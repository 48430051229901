import React, { useEffect } from "react";

import crownimg from "../../src/images/crown.png";
import ".././component/layout/style.css";

import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getsinglealbum } from "../Allslices/albumSlice";
import { setCurrentTrack, setShowMusicBar, setSongPurchased, setTrackList } from "../Allslices/playerSlice";
import { getmyOrder } from "../Allslices/orderSlice";

const ArtisteSong = ({
  song,
  handlePlay,
  handlePause,
  albumid,
  isPurchased,
  isSongPurchased
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentTrack, isPlaying, trackList } = useSelector(
    (state) => state.player
  );
  const { orderAll } = useSelector((state) => state?.order);

  const { artiste } = useSelector((state) => state.album);
 
  const isCurrentTrack = currentTrack?._id === song?._id;

  useEffect(() => {
    const purchaseStatus = orderAll.filter((item) => item?.orderItems?.product === currentTrack?._id)
    
    if (purchaseStatus[0]?.paymentInfo?.status === "true" && purchaseStatus[0]?.orderItems?.product === currentTrack?._id) {
      dispatch(setSongPurchased(true))
    }else{
      dispatch(setSongPurchased(false))
    }
  }, [currentTrack])

  useEffect(() => {
    dispatch(getsinglealbum(albumid));
  }, []);

  const playSong = () => {
    
    const purchaseStatus = orderAll.filter((item) => item?.orderItems?.product === song?._id)
    if (purchaseStatus[0]?.paymentInfo?.status === "true" && purchaseStatus[0]?.orderItems?.product === song?._id) {
      dispatch(setSongPurchased(true))
    }else{
      dispatch(setSongPurchased(false))
    }
    handlePlay(song);
  };
  const pauseSong = () => {
    handlePause(song);
    dispatch(setShowMusicBar(false))
  };

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);


  const handleNavigateToSongpage = (slug) => {
    const index = artiste?.songList.findIndex((s) => s.slug == slug);

    dispatch(setTrackList({ list: artiste?.songList, index }));

    dispatch(setCurrentTrack(song));

    navigate(`/music/${albumid}/${slug}`);
  };
  const titlemaxLength = 15;
  return (
    <>
      <div className="elemt pt-2">
        <div className="apixoss py-4 mt-2">
          {isCurrentTrack && isPlaying ? (
            <i
              onClick={pauseSong}
              className="fas fa-pause fs-4"
              style={{ marginTop: ".5rem!important", cursor: "pointer" }}
            ></i>
          ) : (!song?.isPaid && !artiste?.isPaid) || (isPurchased && !song?.isPaid) || (song?.isPaid && !artiste?.isPaid && isSongPurchased) || 
          (artiste?.isPaid && isPurchased) ? (
            <i
              onClick={playSong}
              className="fas fa-play fs-4"
              style={{ marginTop: ".5rem!important" , cursor: "pointer"}}
            ></i>
          ) : (
            <span className="mt-1">
              {" "}
              <i
                className="fas fa-play fs-4"
                sx={{ marginTop: ".5rem", cursor: "pointer" }}
                title="Unlock to play" 
              ></i>
            </span>
          )}

          <p className="pt-2 yera fw-semibold" style={{ width: "133px" }}>
            {/* {song?.title?.length > titlemaxLength
              ? `${song.title.substring(0, titlemaxLength)}...`
              : song?.title}{" "} */}
            {song?.title}
          </p>
          {isCurrentTrack && isPlaying ? (
            <iframe
              src="https://giphy.com/embed/H4Ke1ghWdhic0HLZ6L"
              width="60"
              height="36"
              frameBorder="0"
              className="giphy-embed d-none d-lg-block"
              allowFullScreen
            ></iframe>
          ) : (
            ""
          )}
          {/* <p className="pt-2 yera fw-semibold">
            <Moment format="D MMM YYYY" withTitle>
              {song?.created_at}
            </Moment>
          </p> */}

          <p className="d-flex gap-3 align-items-center pt-2 yera fw-semibold">
            <i
              class="fa-solid fa-headphones "
              style={{ color: "rgb(183, 103, 162)" }}
            ></i>
            {
                (artiste?.isPaid && !isPurchased) ||
                (!artiste?.isPaid && song?.isPaid && !orderAll.some((item) => item?.orderItems?.product == song?._id)) ? (
                <img
                  src={crownimg}
                  style={{
                    width: "1.45rem",
                  }}
                  className="img-fluid"
                  alt="Paid"
                />
              ) : (
                ""
              )}
          </p>
          {/* <p>
          <a
            href="https://giphy.com/gifs/SumaqStudios-music-producer-sumaq-studios-Sx9iTT2keXbmW6sCFB"></a>
        </p> */}

          <a
            className="btn vieww text-dark text-dark pt-2 yera "
            onClick={() => handleNavigateToSongpage(song?.slug)}
          >
            View More<i className="fa-solid fa-angle-right"></i>
          </a>

          {(song?.isPaid && artiste?.isPaid) ||
            (!isPurchased && song?.isPaid) ||
            (!isPurchased && artiste?.isPaid) ? (
            <img
              src={crownimg}
              style={{
                top: "0.10rem",
                right: "0.25rem",
                width: "1.5rem",
              }}
              className="img-fluid position-absolute  "
              alt=""
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ArtisteSong;
