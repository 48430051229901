import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";
import { SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from "../appUtils/appToast";



const initialState = {
    isLoading: false,
    error: null,
    subscription: {},


}


export const createSubcription = createAsyncThunk('subcription/newsubcription', async ({ email }, { dispatch, rejectWithValue }) => {
    try {
        dispatch(newSubcriptionStart())
        const res = await axios.post(`subcription/create`, { email })
        dispatch(newSubcriptionSuccess((res.data)))
        return res.data;
    } catch (error) {
        SHOW_ERROR_NOTIFICATION(error.response.data.message)
        dispatch(newSubcriptionfailure(error.response.data.message));

    }

}
)

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        newSubcriptionStart(state, action) {
            state.isLoading = true;
            state.error = null;
        },
        newSubcriptionSuccess(state, action) {
            state.isLoading = false;
            state.subscribed = action.payload;
            // state.message= "Subscribed Successfully"
        },
        newSubcriptionfailure(state, action) {
            state.isLoading = false;
            state.message= "Already subscribed"
        }
    }

})

export const { newSubcriptionStart, newSubcriptionSuccess, newSubcriptionfailure } = subscriptionSlice.actions

export default subscriptionSlice.reducer