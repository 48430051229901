import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import drArchikadidilogo from "../../../src/images/final logo black1.png";
import { RxHamburgerMenu } from "react-icons/rx";
import "./style.css";
import "../auth/auth.css";
import { useSelector, useDispatch } from "react-redux";
import { changePassword, logout } from "../auth/authSlice";
import { useParams } from "react-router-dom";
import {
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "./../../appUtils/appToast";
import newLogoDrArchika from "../../../src/images/newDrArchikaLogo1.png";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import gnmailicon from "../../../src/images/contactgmail.png";
import phoneicon from "../../../src/images/contactphone.png";
import locationicon from "../../../src/images/contactlocation.png";
import Whatsapp from "../../../src/images/conatctwhatsapp.png";
import { getContactUs } from "../../Allslices/ContactUsSlice";
import { contactus } from "../../Allslices/mainSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { filtersUpdate, getwisdomList } from "../../Allslices/wisdomSlice";
import { getmyOrder } from "../../Allslices/orderSlice";
import { IoCloseSharp, IoLockOpen } from "react-icons/io5";
import SmallLoader from "../../maincontent/SmallLoader";

const Header = () => {
  const [isloggedin, setIsloggedin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, newSetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const [showCommunityModal, setShowCommunityModal] = useState(false);
  const [communityUnlock, setCommunityUnlock] = useState(false);
  // For HighLighting the Route
  const location = useLocation();
  const currentPath = location.pathname;

  const params = useParams();
  const { token } = params;
  const { user, isAuthenticated, isLoading } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {
  //     // Function to handle the siteSticky effect
  //     const siteSticky = () => {
  //         const header = document.querySelector('.site-navbar');
  //         // const header = document.querySelector('.js-sticky-header');

  //         if (header) {
  //             const topSpacing = 0;

  //             const handleScroll = () => {
  //              
  //                 if (window.pageYOffset > topSpacing) {
  //                     header.classList.add('sticky');
  //                     // header.classList.add('js-sticky-header');
  //                     // header.classList.add('sticky-top')
  //                 } else {
  //                     header.classList.remove('sticky');
  //                 }
  //             };

  //             // Attach the scroll event listener
  //             window.addEventListener('scroll', handleScroll);

  //             // Clean up the event listener when the component unmounts
  //             return () => {
  //                 window.removeEventListener('scroll', handleScroll);
  //             };
  //         }
  //     };

  //     // Call siteSticky when the component mounts
  //     siteSticky();
  // }, []);

  // useEffect(() => {
  //     const handleScroll = () => {
  //         const header = document.querySelector('.site-navbar');
  //         const topSpacing = 0;

  //         if (header) {
  //             const scrollTop = window.pageYOffset;
  //             const elementTop = header.offsetTop;
  //             const etse = elementTop - topSpacing;

  //             if (scrollTop <= etse) {
  //                 header.classList.remove('is-sticky');
  //             } else {
  //                 header.classList.add('is-sticky');
  //             }
  //         }
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  const navigate = useNavigate();
  const [textColor, setTextColor] = useState("black");
  const [menuIconColor, setMenuIconColor] = useState("black");
  const [logo, setLogo] = useState(drArchikadidilogo);

  const [navbarBg, setNavbarBg] = useState(
    "linear-gradient(90deg, rgb(241 240 241) 0%, rgb(239 238 244) 100%)"
  );
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavbarBg("linear-gradient(90deg, #B767A2 0%, #35209F 100%)");
      setTextColor("white");
      setLogo(newLogoDrArchika);
      setMenuIconColor("white");
    } else {
      setNavbarBg(
        "linear-gradient(90deg, rgb(241 240 241) 0%, rgb(239 238 244) 100%)"
      );
      setTextColor("black");
      setLogo(drArchikadidilogo);
      setMenuIconColor("white");
    }
  };

  //for contact us modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { contact } = useSelector((state) => state.contactUs);
  const { orderAll } = useSelector((state) => state?.order);
  const { isLoading: contactUsLoading } = useSelector(
    (state) => state?.contact
  );
  useEffect(()=>{
    if (user?.communityUnlocked) {
      setCommunityUnlock(true);
    }
  },[orderAll,dispatch,user])


  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    mobileNumber: "",
    countryCode: "",
    question: "",
  });

  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const handleChangePhoneNumber = (value, data) => {
    const number = value?.slice(data?.dialCode?.length);

    setFormdata({
      ...formdata,
      mobileNumber: number,
      countryCode: data?.dialCode,
      phone: `${data?.dialCode}${number}`,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "question") {
      const wordCount = value.trim().split(/\s+/).length;

      if (wordCount > 200) {
        SHOW_INFO_NOTIFICATION("Question Words Can't Exceeds More than 500!");
        return;
      }
    }
    setFormdata({
      ...formdata,
      [name]: value,
    });
  };



  const handleFormsubmit = (e) => {
    e.preventDefault();

    dispatch(contactus(formdata))
      .then((result) => {
        toast.success("Message Sent Successfully");
        setTimeout(() => {
          setShow(false);
        }, 1500);
        setFormdata({
          name: "",
          email: "",
          phone: "",
          mobileNumber: "",
          countryCode: "",
          question: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to send message");
        setTimeout(() => {
          setShow(false);
        }, 3000);
      });
  };

  const handleCloseCommunityModal = () => setShowCommunityModal(false);
  const handleShowCommunityModal = () => setShowCommunityModal(true);

  useEffect(() => {
    dispatch(getContactUs());
    dispatch(getmyOrder());
  }, [dispatch]);

  useEffect(() => {
    function handleCollapseClick(event) {
      var target = event.target;

      if (target.classList?.contains("arrow-collapse")) {
        var collapse = target.parentElement.querySelector(".collapse");

        if (collapse.classList?.contains("show")) {
          target.classList.remove("active");
        } else {
          target.classList.add("active");
        }
      }
    }

    function handleWindowResize() {
      var width = window.innerWidth;

      if (width > 768 && document.body.classList?.contains("offcanvas-menu")) {
        document.body.classList.remove("offcanvas-menu");
      }
    }

    function handleOutsideMenuClick(event) {
      var target = event.target;
      var container = document.querySelector(".site-mobile-menu");

      if (
        !container?.contains(target) &&
        !container?.isSameNode(target) &&
        document.body.classList?.contains("offcanvas-menu")
      ) {
        document.body.classList.remove("offcanvas-menu");
      }
    }

    function cloneSiteMenu() {
      var cloneNavs = document.querySelectorAll(".js-clone-nav");

      cloneNavs.forEach(function (cloneNav) {
        var clone = cloneNav.cloneNode(true);
        clone.classList.add("site-nav-wrap");
        document.querySelector(".site-mobile-menu-body").appendChild(clone);
      });

      setTimeout(function () {
        var hasChildrenItems = document.querySelectorAll(
          ".site-mobile-menu .has-children"
        );

        var counter = 0;
        hasChildrenItems.forEach(function (item) {
          var arrowCollapse = document.createElement("span");
          arrowCollapse.className = "arrow-collapse collapsed";
          item.insertBefore(arrowCollapse, item.firstChild);

          var dataToggleValue = "#collapseItem" + counter;
          var collapseItems = item.querySelectorAll("ul");
          collapseItems[0].classList.add("collapse");
          collapseItems[0].id = "collapseItem" + counter;
          arrowCollapse.setAttribute("data-toggle", "collapse");
          arrowCollapse.setAttribute("data-target", dataToggleValue);

          counter++;
        });
      }, 1000);
    }

    // Clone the menu and add event listeners
    cloneSiteMenu();

    // Event listeners
    document.body.addEventListener("click", handleCollapseClick);
    window.addEventListener("resize", handleWindowResize);
    document.body.addEventListener("click", handleOutsideMenuClick);

    // Clean up event listeners
    return () => {
      document.body.removeEventListener("click", handleCollapseClick);
      window.removeEventListener("resize", handleWindowResize);
      document.body.removeEventListener("click", handleOutsideMenuClick);
    };
  }, []); // Empty dependency array to run this effect only once

  const getnewPasswordCheckboxColor = () => {
    if (newPassword.length === 0) {
      return "grey";
    } else if (newPassword.length < 8 || newPassword.length > 20) {
      return "red";
    } else {
      return "green";
    }
  };

  const getconfPasswordCheckboxColor = () => {
    if (confirmPassword.length === 0) {
      return "grey";
    } else if (confirmPassword.length < 8 || confirmPassword.length > 20) {
      return "red";
    } else {
      return "green";
    }
  };

  const handleNavigatetoLoginPage = () => {
    localStorage.setItem("activeTab", "");
    setIsMenuOpen(false);
    navigate("/login");
  };

  const handlechangePassword = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("oldPassword", oldPassword); // this all exist in backend usercontroller.jsx that why we are using here
    myForm.set("newPassword", newPassword); //and also in backend logic already set , we just have to accept user data here and what happens to this datas is depend on logic written in backend
    myForm.set("confirmPassword", confirmPassword);

    if (!oldPassword) {
      SHOW_ERROR_NOTIFICATION("Old password is required");
      return;
    }
    if (newPassword.length < 8) {
      SHOW_ERROR_NOTIFICATION("Password Should be Atleast 8 digits");
      return;
    }
    if (confirmPassword.length < 8) {
      SHOW_ERROR_NOTIFICATION("Password Should be Atleast 8 digits");
      return;
    }
    if (newPassword !== confirmPassword) {
      SHOW_ERROR_NOTIFICATION(" your password does not Match");
      return;
    }

    // dispatch(changePassword(myForm));
    dispatch(
      changePassword({ oldPassword, newPassword, confirmPassword })
    ).then((result) => {
      if (result.payload?.success) {
        SHOW_SUCCESS_NOTIFICATION("Update Successfully");
        setShowModal(false);
        setOldPassword("");
        newSetPassword("");
        setConfirmPassword("");
      } else {
        SHOW_ERROR_NOTIFICATION("Update Password Failed");
      }
    });

    // setShowModal(false);
  };

  const logoutHandler = () => {
    localStorage.setItem("activeTab", "");
    dispatch(logout()).then((result) => {
      if (result?.payload) {
        SHOW_SUCCESS_NOTIFICATION(result?.payload);
        navigate("/");
      }
    });
  };

  const handleNavigateToCommunity = () => {
    navigate("/community");
  };
  const handleNavigatetoCourses = () => {
    navigate("/course");
  };

  // search modal--functionality---
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility11 = () => {
    if (filterText9799 !== "") {
      setIsVisible(true);
    }
  };
  // search modal----

  //  filtering
  //  for placeholder
  const [placeholder, setPlaceholder] = useState("");
  // filtering2
  const [filterText9799, setFilterText9799] = useState("");
  const [isChecked, setIsChecked] = useState("");
  const [selectedRadio, setSelectedRadio] = useState({
    music: false,
    wellness: false,
    wisdom: false,
    podcast: false,
    video: false,
  });
  const [filterText, setFilterText] = useState("");

  const handleInputChange97 = (event) => {
    setFilterText(event.target.value);
  };

  const { currentPage, limit } = useSelector((state) => state.wisdom);

  const handleInputChange9799 = (event) => {
    const { name, checked } = event.target;
    const tagValue = checked ? event.target.nextSibling.textContent : " ";

    setSelectedRadio((prevSelectedRadio) => {
      const updatedSelection = {};

      for (const key in prevSelectedRadio) {
        updatedSelection[key] = key === name;
      }

      return updatedSelection;
    });

    setIsChecked(checked);
    setFilterText9799(tagValue);

    if (placeholder === "Choose one from the given list!") {
      setPlaceholder("Enter a keyword to search!");
    }

    var dropdownMenu = document.getElementById("dropdownMenuButton1");
    dropdownMenu.classList.remove("show");
  };
  // filtering2

  // refrece
  const handlechangePassword9799 = () => {
    setFilterText("");
    setFilterText9799("");
    setIsChecked(false);
    setIsVisible(false);

    if (placeholder === "Enter a keyword to search!") {
      setPlaceholder("");
    }
    if (placeholder === "Choose one from the given list!") {
      setPlaceholder("");
    }

    // For opening the dropDown popupModel while Click on Search Nav Icon
    var dropdownMenu = document.getElementById("dropdownMenuButton1");
    dropdownMenu.classList.add("show");
  };
  // refrece

  // trending input
  const handlePClick = (text) => {
    setFilterText(text);
    toggleVisibility11();
  };
  const handlenavigatetoprofile = () => {
    navigate("/profile");
  };
  const handlenavigatetoquestions = () => {
    navigate("/profile?key=question");
  };
  const handlenavigatetoanswers = () => {
    navigate("/profile?key=answers");
  };
  const handlenavigatetothoughts = () => {
    navigate("/profile?key=thought");
  };
  const handlenavigatetocourses = () => {
    navigate("/profile?key=course");
  };
  const handlenavigatetobhagwat = () => {
    navigate("/profile?key=bhagwat");
  };
  const handlenavigatetowellness = () => {
    navigate("/profile?key=wellness");
  };
  const handlenavigatetomusic = () => {
    navigate("/profile?key=music");
  };
  const handlenavigatetodonation = () => {
    navigate("/profile?key=events");
  };
  // trending input
  const handleIconClick = () => {
    setSelectedRadio({
      music: false,
      wellness: false,
      wisdom: false,
      podcast: false,
      video: false,
    });

   

    //blog 

    if (filterText9799 === "" ||filterText9799 == null ) {
      if (filterText !== "") {
        navigate(`/blog?${filterText}`);
      // } else {
      //   navigate(`/wellness?${filterText}`);
      // }
    }}

    //blog

    if (filterText9799 === "Wellness :") {
      if (filterText === "") {
        navigate(`/wellness`);
      } else {
        navigate(`/wellness?${filterText}`);
      }
    }
    if (filterText9799 === "Music :") {
      if (filterText === "") {
        navigate(`/music`);
      } else {
        navigate(`/music?${filterText}`);
      }
    }
    if (filterText9799 === `Wisdom :`) {
      if (filterText === "") {
        navigate(`/wisdom`);
      } else {
        navigate(`/wisdom?${filterText}`);
      }
    }
    // if (filterText9799 === `Bhagwat Geeta :`) {
    //   navigate(`/bhagwatgeeta/chapter?${filterText}`);
    // }
    if (filterText9799 === `Podcast :`) {
      if (filterText === "") {
        navigate(`/podcast`);
      } else {
        navigate(`/podcast?${filterText}`);
      }
    }
    if (filterText9799 === `Video :`) {
      if (filterText === "") {
        navigate("/video");
      } else {
        navigate(`/video?${filterText}`);
      }
    }

    const modalCloseButton = document.querySelector(
      "#exampleModal .btn-close-search"
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };

  // const [activeTab, setActiveTab] = useState('');

  const handleactiveTab = (e) => {
    // localStorage.setItem("activeTab", e.target.id);
  };

  const onclicklogo = () => {
    localStorage.setItem("activeTab", "home");
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false); // Close the menu
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsMenuOpen(false); // Close the menu if clicking outside
    }
  };
  const handleScroll2 = () => {
    setIsMenuOpen(false); // Close the menu on scroll
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll2);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  const [Loading, Setloading] = useState(false);
  const wisdomload = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  const wisdomfilter = (type) => {
    setIsMenuOpen(false);
    if (type == "audio") {
      dispatch(filtersUpdate({ contentType: "audio" }));
    }
    if (type == "video") {
      dispatch(filtersUpdate({ contentType: "video" }));
    }
    if (type == "article") {
      dispatch(filtersUpdate({ contentType: "article" }));
    }
    dispatch(
      getwisdomList({
        page: currentPage,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setTextColor("white");
      } else {
        setTextColor("black");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleIconClick();
    }
  };


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  

  return (
    <>
      <div>
        <nav
          ref={navbarRef}
          className="navbar navbar-expand-lg bg-body-tertiary fixed-top  "
          style={{ backgroundImage: navbarBg, height: "75px" }}
        >
          <div className="container-fluid " style={{ width: "100%" }}>
            <div
              className=" align-items-center  justify-content-between hederresposibe"
              style={{ width: "100%" }}
            >
              <Link
                className="navbar-brand archikalogo"
                to="/"
                onClick={() => {
                  onclicklogo();
                  handleMenuItemClick();
                }}
              >
                <img src={logo} alt="" className="animeheder responsiblogo " />
              </Link>

              <div className="d-flex align-items-center  ">
                <button
                  type="button"
                  className="btn d-lg-none d-md-block d-sm-block"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={handlechangePassword9799}
                  value="CHANGE PASSWORD"
                >
                  <Link
                    className="nav-link p-2 "
                    aria-current="page"
                    style={{ color: textColor }}
                  >
                    <i class="fa-solid fa-magnifying-glass fs-3 fs-xs-5 d-lg-none d-md-block d-sm-block "></i>
                  </Link>
                </button>
                <button
                  className="navbar-toggler border-0  "
                  style={{ marginRight: "18px" }}
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="#offcanvasNavbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <span className="fs-1" style={{ color: `${textColor}` }}>
                    <RxHamburgerMenu />
                  </span>
                </button>
              </div>
            </div>
            <div
              class="offcanvas offcanvas-end w-75"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close ms-auto"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>

              <ul
                className="navbar-nav navbar-nav-scroll  ms-lg-auto mb-2 mb-lg-0 text-left  align-items-lg-center align-items-sm-left align-items-md-left navbaranchortags "
                style={{
                  whiteSpace: "nowrap",
                  paddingLeft: "20px",
                  maxHeight: "var(--bs-scroll-height, 100vh)",
                }}
              >
                <li
                  className="nav-item "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link
                    className={`nav-link   animeheder nav-space ${
                      currentPath == "/aboutus" ? "colorChange" : ""
                    }`}
                    id="aboutus"
                    aria-current="page"
                    to="/aboutus"
                    // onClick={handleactiveTab}
                    onClick={handleMenuItemClick}
                    style={{
                      color: currentPath == "/aboutus" ? "white" : textColor,
                    }}
                  >
                    <span className="headerQuery ">
                      <i class="fa-regular fa-id-badge d-lg-none "></i>
                      &nbsp;&nbsp; ABOUT US
                    </span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link
                  className={`nav-link p-2 animeheder  ${
                    currentPath === "/wisdom" || currentPath.includes("/wisdom")
                      ? "colorChange"
                      : ""
                  }`}
                  id="wisdom"
                  aria-current="page"
                  to="/wisdom"
                  style={{ color: textColor }}
                  onClick={handleMenuItemClick}
                >
                  WISDOM
                </Link>
              </li> */}

                <li
                  className="nav-item dropdown  animeheder  nav-space "
                  style={{ color: textColor }}
                >
                  <span
                    className=" nav-item  headerQuery"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {" "}
                    <i class="fa-solid fa-lightbulb d-lg-none "></i>
                    &nbsp;&nbsp; WISDOM
                    <i class="fa-solid fa-angle-down "></i>
                  </span>

                  <ul className="dropdown-menu mx-2 ">
                    <li onClick={wisdomload}>
                      <Link
                        to="/wisdom"
                        className={`dropdown-item downdownitebg  ${
                          currentPath === "/article" ||
                          currentPath.includes("/article")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="article"
                        onClick={() => wisdomfilter("article")}
                      >
                        <span className="headerQuery">
                          <i class="fa-solid fa-scroll d-lg-none "></i>
                          &nbsp;&nbsp; Article
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/wisdom"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/video" ||
                          currentPath.includes("/video")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="video"
                        onClick={() => wisdomfilter("video")}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-circle-play  d-lg-none "></i>
                          &nbsp;&nbsp; Video
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/wisdom"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/audio" ||
                          currentPath.includes("/audio")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="audio"
                        onClick={() => wisdomfilter("audio")}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-microphone d-lg-none "></i>
                          &nbsp;&nbsp; Audio
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/podcast"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/podcast" ||
                          currentPath.includes("/podcast")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="podcast"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-podcast d-lg-none "></i>
                          &nbsp;&nbsp; Podcast
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/books"
                        className={`dropdown-item downdownitebg  ${
                          currentPath === "/books" ||
                          currentPath.includes("/books/")
                            ? // currentPath.includes("/albums")
                              "dropdownColorChange"
                            : ""
                        }`}
                        id="books"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-book-open d-lg-none "></i>
                          &nbsp;&nbsp; Books
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item " data-bs-dismiss="offcanvas">
                  <Link
                    to="/wellness"
                    className={`nav-link animeheder nav-space ${
                      currentPath === "/wellness" ||
                      currentPath.includes("/wellness")
                        ? "colorChange"
                        : ""
                    }`}
                    id="wellness"
                    aria-current="page"
                    onClick={handleMenuItemClick}
                    style={{ color: textColor }}
                  >
                    <span className="headerQuery">
                      <i class="fa-solid fa-group-arrows-rotate d-lg-none "></i>
                      &nbsp;&nbsp; WELLNESS BUNDLE
                    </span>
                  </Link>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link
                    className={`nav-link  animeheder nav-space ${
                      currentPath == "/course" ||
                      currentPath.includes("/courses") ||
                      currentPath.includes("/course")
                        ? "colorChange"
                        : ""
                    }`}
                    id="onlinecourses"
                    aria-current="page"
                    to="/courses"
                    onClick={handleMenuItemClick}
                    style={{ color: textColor }}
                  >
                    <span className="headerQuery">
                      <i class="fa-solid fa-book-open-reader d-lg-none "></i>
                      &nbsp;&nbsp; COURSES
                    </span>
                  </Link>
                </li>

                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <Link
                    className={`nav-link  animeheder nav-space ${
                      currentPath == "/events" ||
                      currentPath.includes("/events") ||
                      currentPath.includes("/events")
                        ? "colorChange"
                        : ""
                    }`}
                    id="events"
                    aria-current="page"
                    to="/events"
                    onClick={handleMenuItemClick}
                    style={{ color: textColor }}
                  >
                    <span className="headerQuery">
                      <i class="fa-solid fa-calendar-days d-lg-none "></i>
                      &nbsp;&nbsp; EVENTS
                    </span>
                  </Link>
                </li>
                <li className="nav-item " data-bs-dismiss="offcanvas">
                  <Link
                    className={`nav-link  animeheder nav-space ${
                      currentPath == "/meditation" ||
                      currentPath.includes("/meditation")
                        ? "colorChange"
                        : ""
                    }`}
                    id="meditationretreats"
                    aria-current="page"
                    to="/meditation"
                    onClick={handleMenuItemClick}
                    style={{ color: textColor }}
                  >
                    <span className="headerQuery">
                      <i class="fa-solid fa-spa d-lg-none "></i>
                      &nbsp;&nbsp; RETREATS
                    </span>
                  </Link>
                </li>

                <li
                  className="nav-item dropdown  animeheder other-layout-lg nav-space "
                  style={{ color: textColor }}
                >
                  <span
                    className=" nav-item  headerQuery"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-braille d-lg-none d-md-block d-sm-block"></i>
                    &nbsp;&nbsp; OTHERS
                    <i class="fa-solid fa-angle-down "></i>
                  </span>

                  <ul
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/music"
                        className={`dropdown-item downdownitebg  ${
                          currentPath === "/music" ||
                          currentPath.includes("/music/")
                            ? // currentPath.includes("/albums")
                              "dropdownColorChange"
                            : ""
                        }`}
                        id="music"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-volume-high d-lg-none d-md-block d-sm-block"></i>
                          &nbsp;&nbsp; Music
                        </span>
                      </Link>
                    </li>

                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/blog"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/blog" ||
                          currentPath.includes("/blog")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="blog"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-blog  d-lg-none d-md-block d-sm-block "></i>{" "}
                          &nbsp;&nbsp; Blogs
                        </span>
                      </Link>
                    </li>

                    {/* <li>
                      <Link
                        to="/podcast"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/podcast" ||
                          currentPath.includes("/podcast")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="podcast"
                        onClick={handleMenuItemClick}
                      >
                        Podcast
                      </Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link
                        className={`dropdown-item downdownitebg ${
                          currentPath == "/bhagwatgeeta" ||
                          currentPath.includes("/bhagwatgeeta")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="bhagwatgeeta"
                        to="/bhagwatgeeta"
                        onClick={handleMenuItemClick}
                      >
                        Bhagwat Geeta
                      </Link>
                    </li> */}
                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/gallery"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/gallery" ||
                          currentPath.includes("/gallery")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="gallery"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-image d-lg-none d-md-block d-sm-block"></i>
                          &nbsp;&nbsp; Gallery
                        </span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/wellness"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/wellness" ||
                          currentPath.includes("/wellness")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="wellness"
                        onClick={handleMenuItemClick}
                      >
                        Wellness Bundle
                      </Link>
                    </li> */}
                    <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/video"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/video" ||
                          currentPath.includes("/video/")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="video"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          <i class="fa-solid fa-circle-play  d-lg-none d-md-block d-sm-block"></i>
                          &nbsp;&nbsp; Video
                        </span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/books"
                        className={`dropdown-item downdownitebg  ${
                          currentPath === "/books" ||
                          currentPath.includes("/books/")
                            ? // currentPath.includes("/albums")
                              "dropdownColorChange"
                            : ""
                        }`}
                        id="books"
                        onClick={handleMenuItemClick}
                      >
                        Books
                      </Link>
                    </li> */}
                    {/* <li data-bs-dismiss="offcanvas">
                      <Link
                        to="/donation"
                        className={`dropdown-item downdownitebg ${currentPath === "/donation" ||
                          currentPath.includes("/donation")
                          ? "dropdownColorChange"
                          : "text-dark"
                          }`}
                        id="donation"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-hand-holding-dollar d-lg-none d-md-block d-sm-block"></i>
                          &nbsp;&nbsp; Donation
                        </span>
                      </Link>
                    </li> */}
                    {communityUnlock ? (
                      <li data-bs-dismiss="offcanvas">
                        {!isAuthenticated ? (
                          <Link
                            to="/login"
                            className={`text-secondary dropdown-item downdownitebg ${
                              currentPath === "/community"
                                ? "dropdownColorChange"
                                : "text-dark"
                            }`}
                            id="community"
                            onClick={() => {
                              handleMenuItemClick();
                            }}
                          >
                            <span className="headerQuery">
                              <i class="fa-solid fa-people-roof d-lg-none d-md-block d-sm-block"></i>
                              &nbsp;&nbsp; Community (Blissify)
                            </span>
                          </Link>
                        ) : (
                          <Link
                            to="/community"
                            className={`dropdown-item  downdownitebg ${
                              currentPath === "/community"
                                ? "dropdownColorChange"
                                : "text-dark"
                            }`}
                            id="community"
                            onClick={handleMenuItemClick}
                          >
                            <span className="headerQuery">
                              <i class="fa-solid fa-people-roof d-lg-none d-md-block d-sm-block"></i>
                              &nbsp;&nbsp; Community (Blissify)
                            </span>
                          </Link>
                        )}
                      </li>
                    ) : (
                      <li data-bs-dismiss="offcanvas">
                        {!isAuthenticated ? (
                          <Link
                            to="/login"
                            className={`text-secondary dropdown-item downdownitebg ${
                              currentPath === "/community"
                                ? "dropdownColorChange"
                                : "text-dark"
                            }`}
                            id="community"
                            onClick={() => {
                              handleMenuItemClick();
                            }}
                          >
                            <span className="headerQuery">
                              <i class="fa-solid fa-people-roof d-lg-none d-md-block d-sm-block"></i>
                              &nbsp;&nbsp; Community (Blissify)
                            </span>
                          </Link>
                        ) : (
                          <span
                            className={`text-secondary dropdown-item downdownitebg ${
                              currentPath === "/community"
                                ? "dropdownColorChange"
                                : "text-dark"
                            }`}
                            onClick={handleShowCommunityModal}
                          >
                            <i class="fa-solid fa-people-roof d-lg-none d-md-block d-sm-block"></i>
                            &nbsp;&nbsp; Community (Blissify)
                          </span>
                        )}
                      </li>
                    )}

                    <li data-bs-dismiss="offcanvas">
                      <Link
                        // to="/contactus"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/contactus"
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        // id="contactus"
                        // onClick={handleMenuItemClick}
                        onClick={handleShow}
                      >
                        <span className="headerQuery">
                          {" "}
                          <i class="fa-solid fa-phone-volume d-lg-none d-md-block d-sm-block"></i>
                          &nbsp;&nbsp; Contact Us
                        </span>
                      </Link>
                    </li>

                    {/* <li>
                      <Link
                        to="/events"
                        className={`dropdown-item ${
                          currentPath === "/events" ? "colorChange" : "text-dark"
                        }`}
                        id="events"
                        onClick={handleMenuItemClick}
                      >
                        Events
                      </Link>
                    </li> */}
                  </ul>
                </li>

                {/*Others list for the small screen code start below  */}

                <li
                  className="nav-item other-layout-sm"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    to="/music"
                    className={`nav-link p-2 animeheder  ${
                      currentPath === "/music" ||
                      currentPath.includes("/music/")
                        ? // currentPath.includes("/albums")
                          "dropdownColorChange"
                        : ""
                    }`}
                    id="music"
                    onClick={handleMenuItemClick}
                  >
                    <span className="headerQuery">
                      {" "}
                      <i class="fa-solid fa-volume-high d-lg-none "></i>
                      &nbsp;&nbsp; MUSIC
                    </span>
                  </Link>
                </li>

                <li
                  className="nav-item other-layout-sm"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    to="/blog"
                    className={`nav-link p-2 animeheder ${
                      currentPath === "/blog" || currentPath.includes("/blog")
                        ? "dropdownColorChange"
                        : "text-dark"
                    }`}
                    id="blog"
                    onClick={handleMenuItemClick}
                  >
                    <span className="headerQuery">
                      {" "}
                      <i class="fa-solid fa-blog  d-lg-none  "></i>
                      &nbsp;&nbsp; BLOGS
                    </span>
                  </Link>
                </li>

                {/* <li>
                      <Link
                        to="/podcast"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/podcast" ||
                          currentPath.includes("/podcast")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="podcast"
                        onClick={handleMenuItemClick}
                      >
                        Podcast
                      </Link>
                    </li> */}
                {/* <li className="nav-item">
                      <Link
                        className={`dropdown-item downdownitebg ${
                          currentPath == "/bhagwatgeeta" ||
                          currentPath.includes("/bhagwatgeeta")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="bhagwatgeeta"
                        to="/bhagwatgeeta"
                        onClick={handleMenuItemClick}
                      >
                        Bhagwat Geeta
                      </Link>
                    </li> */}
                <li
                  className="nav-item other-layout-sm"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    to="/gallery"
                    className={`nav-link p-2 animeheder ${
                      currentPath === "/gallery" ||
                      currentPath.includes("/gallery")
                        ? "dropdownColorChange"
                        : "text-dark"
                    }`}
                    id="gallery"
                    onClick={handleMenuItemClick}
                  >
                    <span className="headerQuery">
                      {" "}
                      <i class="fa-solid fa-image d-lg-none "></i>
                      &nbsp;&nbsp; GALLERY
                    </span>
                  </Link>
                </li>
                {/* <li>
                      <Link
                        to="/wellness"
                        className={`dropdown-item downdownitebg ${
                          currentPath === "/wellness" ||
                          currentPath.includes("/wellness")
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="wellness"
                        onClick={handleMenuItemClick}
                      >
                        Wellness Bundle
                      </Link>
                    </li> */}
                <li
                  className="nav-item other-layout-sm"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    to="/video"
                    className={`nav-link p-2 animeheder ${
                      currentPath === "/video" ||
                      currentPath.includes("/video/")
                        ? "dropdownColorChange"
                        : "text-dark"
                    }`}
                    id="video"
                    onClick={handleMenuItemClick}
                  >
                    <span className="headerQuery">
                      <i class="fa-solid fa-circle-play  d-lg-none "></i>
                      &nbsp;&nbsp; VIDEO
                    </span>
                  </Link>
                </li>
                {/* <li>
                      <Link
                        to="/books"
                        className={`dropdown-item downdownitebg  ${
                          currentPath === "/books" ||
                          currentPath.includes("/books/")
                            ? // currentPath.includes("/albums")
                              "dropdownColorChange"
                            : ""
                        }`}
                        id="books"
                        onClick={handleMenuItemClick}
                      >
                        Books
                      </Link>
                    </li> */}
                {/* <li className="nav-item other-layout-sm" data-bs-dismiss="offcanvas">
                  <Link
                    to="/donation"
                    className={`nav-link p-2 animeheder ${currentPath === "/donation" ||
                      currentPath.includes("/donation")
                      ? "dropdownColorChange"
                      : "text-dark"
                      }`}
                    id="donation"
                    onClick={handleMenuItemClick}
                  >
                    <span className="headerQuery">
                      {" "}
                      <i class="fa-solid fa-hand-holding-dollar d-lg-none "></i>
                      &nbsp;&nbsp; DONATION
                    </span>
                  </Link>
                </li> */}
                {communityUnlock ? (
                  <li
                    className="nav-item other-layout-sm"
                    data-bs-dismiss="offcanvas"
                  >
                    {!isAuthenticated ? (
                      <Link
                        to="/login"
                        className={`text-secondary nav-link p-2 animeheder ${
                          currentPath === "/community"
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="community"
                        onClick={() => {
                          handleMenuItemClick();
                        }}
                      >
                        <span className="headerQuery">
                          <i class="fa-solid fa-people-roof d-lg-none "></i>
                          &nbsp;&nbsp; COMMUNITY (BLISSIFY)
                        </span>
                      </Link>
                    ) : (
                      <Link
                        to="/community"
                        className={`nav-link p-2 animeheder ${
                          currentPath === "/community"
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="community"
                        onClick={handleMenuItemClick}
                      >
                        <span className="headerQuery">
                          <i class="fa-solid fa-people-roof d-lg-none "></i>
                          &nbsp;&nbsp; COMMUNITY (BLISSIFY)
                        </span>
                      </Link>
                    )}
                  </li>
                ) : (
                  <li
                    className="nav-item other-layout-sm"
                    data-bs-dismiss="offcanvas"
                  >
                    {!isAuthenticated ? (
                      <Link
                        to="/login"
                        className={`text-secondary nav-link p-2 animeheder ${
                          currentPath === "/community"
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        id="community"
                        onClick={() => {
                          handleMenuItemClick();
                        }}
                      >
                        <span className="headerQuery">
                          <i class="fa-solid fa-people-roof d-lg-none "></i>
                          &nbsp;&nbsp; COMMUNITY (BLISSIFY)
                        </span>
                      </Link>
                    ) : (
                      <span
                        className={`text-secondary nav-link p-2 animeheder ${
                          currentPath === "/community"
                            ? "dropdownColorChange"
                            : "text-dark"
                        }`}
                        onClick={handleShowCommunityModal}
                      >
                        <i class="fa-solid fa-people-roof d-lg-none "></i>
                        &nbsp;&nbsp; COMMUNITY (BLISSIFY)
                      </span>
                    )}
                  </li>
                )}

                <li
                  className="nav-item other-layout-sm"
                  data-bs-dismiss="offcanvas"
                >
                  <Link
                    // to="/contactus"
                    className={`nav-link p-2 animeheder ${
                      currentPath === "/contactus"
                        ? "dropdownColorChange"
                        : "text-dark"
                    }`}
                    // id="contactus"
                    // onClick={handleMenuItemClick}
                    onClick={handleShow}
                  >
                    <span className="headerQuery">
                      {" "}
                      <i class="fa-solid fa-phone-volume d-lg-none "></i>
                      &nbsp;&nbsp; CONTACT US
                    </span>
                  </Link>
                </li>

                <li className="nav-item d-none d-lg-block">
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn magnify-glass"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={handlechangePassword9799}
                      value="CHANGE PASSWORD"
                    >
                      <Link
                        className="nav-link nav-space "
                        aria-current="page"
                        style={{ color: textColor }}
                      >
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </Link>
                    </button>
                    <button
                      className="navbar-toggler border-0  "
                      style={{ marginRight: "18px" }}
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="#offcanvasNavbar"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={toggleMenu}
                    ></button>
                  </div>
                </li>
                <li className="nav-item">
                  {/* btn */}
                  {/* <button
                  type="button"
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={handlechangePassword9799}
                  value="CHANGE PASSWORD"
                >
                  <Link
                    className="nav-link p-2 "
                    aria-current="page"
                    style={{ color: textColor }}
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </Link>
                </button> */}
                  {/* btn */}
                </li>

                {isAuthenticated ? (
                  <li className="nav-item dropdown animeheder nav-space hiUserMob">
                    <span
                      className={`nav-link headerQuery`}
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        color: textColor,
                      }}
                    >
                      
                      <i className="fa-regular fa-circle-user d-lg-none"></i>
                      &nbsp;&nbsp; Hi, {user?.name}
                      <i className="fa-solid fa-angle-down"></i>
                    </span>

                    <ul className="dropdown-menu mx-2">
                      <li onClick={handlenavigatetoprofile}>
                        <Link
                          to="/profile"
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/profile"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">
                            <i className="fa-solid fa-user d-lg-none"></i>
                            &nbsp;&nbsp; PROFILE
                          </span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetoquestions}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/profile?key=question"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY QUESTIONS</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetoanswers}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/profile?key=answers"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY ANSWERS</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetothoughts}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/mythoughts"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY THOUGHTS</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetocourses}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/profile?key=courses"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY COURSE</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetomusic}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/yourAnswer"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY MUSIC</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetowellness}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/wellness"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY WELLNESS</span>
                        </Link>
                      </li>
                      <li onClick={handlenavigatetodonation}>
                        <Link
                          className={`nav-link ps-2 dropdown-item downdownitebg ${
                            currentPath === "/events"
                              ? "dropdownColorChange"
                              : "text-dark"
                          }`}
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">MY EVENTS</span>
                        </Link>
                      </li>
                      {!user?.googleId && (
                        <li>
                          <Link
                            to="#"
                            className="nav-link ps-2 dropdown-item downdownitebg"
                            onClick={() => setShowModal(true)}
                            data-bs-dismiss="offcanvas"
                          >
                            <span className="headerQuery">CHANGE PASSWORD</span>
                          </Link>
                        </li>
                      )}
                      <li onClick={logoutHandler}>
                        <Link
                          className="nav-link ps-2 dropdown-item downdownitebg"
                          data-bs-dismiss="offcanvas"
                        >
                          <span className="headerQuery">LOG OUT</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="nav-item hiUserMob">
                    <Link
                      className="nav-link"
                      style={{ paddingLeft: "8px", paddingRight: "15px" }}
                      to="/login"
                      data-bs-dismiss="offcanvas"
                    >
                      <button
                        onClick={handleNavigatetoLoginPage}
                        className="btn btn-md fw-bolder signInBtn"
                        style={{ background: "#B767A2", color: textColor }}
                      >
                        JOIN US&nbsp;
                        <i
                          className="fa-solid fa-user"
                          style={{ color: textColor }}
                        ></i>
                      </button>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div
            className={`modal fade ${showModal ? "show d-block" : ""}`}
            // id="staticBackdrop1"
            // data-bs-backdrop="static"
            // data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{
              background: "#000000bd",
            }}
          >
            <div className="modal-dialog d-flex ">
              <div
                className="modal-content"
                style={{ background: "transparent", border: "0px solid" }}
              >
                <div className="modal-body p-0">
                  <div className="112">
                    <div className="main_div bg-white">
                      <div className="clmix">
                        <button
                          type="button"
                          className="btn-close modalCloseButton mt-1 ms-1"
                          data-bs-dismiss="modal"
                          onClick={() => setShowModal(false)}
                          aria-label="Close"
                          style={{ position: "relative", left: "95%" }}
                        ></button>
                        <div className="text-center p-3">
                          <h1>
                            <span
                              style={{
                                fontSize: "40px",
                                position: "relative",
                                top: ".5rem ",
                              }}
                            >
                              Update Password
                            </span>
                          </h1>
                        </div>
                      </div>
                      <form
                        className="pt-4 w-100"
                        onSubmit={handlechangePassword}
                      >
                        <label for="basic-url" className="form-label">
                          Old Password
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bgPcolor text-white rounded-0 password"
                            id="basic-addon1"
                          >
                            <i
                              className="bx bx-lock-open-alt"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </span>
                          <input
                            type="text"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className="form-control rounded-0"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                        <label for="basic-url" className="form-label">
                          New Password
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bgPcolor text-white rounded-0 password"
                            id="basic-addon1"
                          >
                            <i
                              className="bx bx-lock-open-alt"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </span>
                          <input
                            type="text"
                            value={newPassword}
                            onChange={(e) => newSetPassword(e.target.value)}
                            className="form-control rounded-0"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            style={{
                              border: `1px solid ${getnewPasswordCheckboxColor()}`,
                            }}
                          />
                        </div>
                        <div className="pb">
                          <div className="forget_div">
                            <p
                              style={{
                                fontSize: "13px !important",
                                color: getnewPasswordCheckboxColor(),
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-check"
                                style={{ paddingRight: "5px" }}
                              ></i>
                              Must be 8 characters long.
                            </p>
                          </div>
                        </div>
                        <label for="basic-url" className="form-label">
                          Confirm Password
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bgPcolor text-white rounded-0 password"
                            id="basic-addon1"
                          >
                            <i
                              className="bx bx-lock-open-alt"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </span>
                          <input
                            type="text"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="form-control rounded-0"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            style={{
                              border: `1px solid ${getconfPasswordCheckboxColor()}`,
                            }}
                          />
                        </div>
                        <div className="pb">
                          <div className="forget_div">
                            <p
                              style={{
                                fontSize: "13px !important",
                                color: getconfPasswordCheckboxColor(),
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-check"
                                style={{ paddingRight: "5px" }}
                              ></i>
                              Must be 8 characters long.
                            </p>
                          </div>
                        </div>
                        <div className="input_box button bgPcolor">
                          <input
                            type="submit"
                            className="bgPcolor"
                            data-bs-dismiss={showModal ? "" : "modal"}
                            value={isLoading ? "" : "UPDATE PASSWORD"}
                            // value="UPDATE PASSWORD"
                          />
                          {isLoading && (
                            <div
                              className="spinner-border text-light position-absolute"
                              role="status"
                              style={{
                                top: "18%",
                                left: "45%",
                                transform: "translate(-50%, -50%)",
                                animation: "rotateSpinner 1s linear infinite",
                              }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* searchmodal */}
        <div
          className="modal"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            background: "#000000bd",
          }}
        >
          <div className="modal-dialog modal-lg  ">
            <div className="modal-content remove1   ">
              <div class="modal-body" style={{ padding: "0px!important" }}>
                {/* mycontent */}

                <div className="container-fluid">
                  <div className="search1   ">
                    <div className="row">
                      <div className="col-12">
                        <div className="serchbar   h-75   d-flex justify-content-between align-items-center  ">
                          <div className="orangebar  px-4  ">
                            <i
                              className="fa-solid fa-sliders fs-4dropdown-toggle show "
                              type="button"
                              // id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                            </i>

                            {/*popup modal design */}
                            <div className="dropdown  ">
                              <ul
                                className="dropdown-menu show"
                                aria-labelledby="dropdownMenuButton1"
                                id="dropdownMenuButton1"
                              >
                                <div className=" form-check mx-1">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="music"
                                    onChange={handleInputChange9799}
                                    checked={selectedRadio.music}
                                  />
                                  <label>Music :</label>
                                </div>
                                {/* <div className=" form-check mx-1">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  onChange={handleInputChange9799}
                                />
                                <label>Bhagwat Geeta :</label>
                              </div> */}
                                <div className=" form-check mx-1">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="wellness"
                                    onChange={handleInputChange9799}
                                    checked={selectedRadio.wellness}
                                  />
                                  <label>Wellness :</label>
                                </div>
                                <div className=" form-check mx-1">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="wisdom"
                                    onChange={handleInputChange9799}
                                    checked={selectedRadio.wisdom}
                                  />
                                  <label>Wisdom :</label>
                                </div>
                                <div className=" form-check mx-1">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="podcast"
                                    onChange={handleInputChange9799}
                                    checked={selectedRadio.podcast}
                                  />
                                  <label>Podcast :</label>
                                </div>
                                <div className=" form-check mx-1">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="video"
                                    onChange={handleInputChange9799}
                                    checked={selectedRadio.video}
                                  />
                                  <label>Video :</label>
                                </div>
                              </ul>
                            </div>
                            {/*pop modal design */}
                          </div>
                          <input
                            type="text"
                            className="input11 fs-5   "
                            readOnly
                            value={filterText9799}
                            style={{
                              width: `${filterText9799.length * 11}px`,
                              color: isChecked ? "red" : "black",
                            }}
                          />
                          <input
                            type="text"
                            className="input111 fs-5   w-100 "
                            value={filterText}
                            onChange={handleInputChange97}
                            onClick={toggleVisibility11}
                            placeholder={placeholder}
                            onKeyDown={handleKeyPress}
                          />
                          <div
                            className="thumbar  h-100  px-3 "
                            style={{ paddingTop: "2.8rem" }}
                          >
                            <i
                              className="fa-solid fa-magnifying-glass fs-5  "
                              onClick={handleIconClick}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 sss3 px-5   w-100 "
                        style={{ display: isVisible ? "block" : "none" }}
                      >
                        <div className="trend   mx-3    ">
                          {/* <div className="Trending   px-5    ">
                          <p
                            className="text-black2 fs-5"
                            style={{
                              cursor: "pointer",
                              display:
                                filterText === "" ||
                                "Trending Search"
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase())
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Trending Search
                          </p>
                        </div> */}
                          <div className="Trending  d-flex justify-content-between px-5 ">
                            <p
                              className=" text-black1 px-4"
                              style={{
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "Events"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                              onClick={() => handlePClick("Events")}
                            >
                              Events
                            </p>
                            <i
                              className="fas fa-arrow-left fs-4 "
                              style={{
                                transform: "rotate(45deg)",
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "@"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                            ></i>
                          </div>
                          {/* <div className="Trending   d-flex justify-content-between px-5 ">
                          <p
                            className=" text-black1 px-4"
                            style={{
                              cursor: "pointer",
                              display:
                                filterText === "" ||
                                "bhagwat geeta"
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase())
                                  ? "block"
                                  : "none",
                            }}
                            onClick={() => handlePClick("bhagwat geeta")}
                          >
                            bhagwat geeta
                          </p>
                          <i
                            className="fas fa-arrow-left fs-4"
                            style={{
                              transform: "rotate(45deg)",
                              cursor: "pointer",
                              display:
                                filterText === "" ||
                                "~"
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase())
                                  ? "block"
                                  : "none",
                            }}
                          ></i>
                        </div> */}
                          <div className="Trending  d-flex justify-content-between px-5 ">
                            <p
                              className=" text-black1 px-4"
                              style={{
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "Music"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                              onClick={() => handlePClick("Music")}
                            >
                              Music
                            </p>
                            <i
                              className="fas fa-arrow-left fs-4"
                              style={{
                                transform: "rotate(45deg)",
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "~"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                            ></i>
                          </div>
                          <div className="Trending  d-flex justify-content-between px-5">
                            <p
                              className="text-black1 px-4"
                              style={{
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "Podcast"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                              onClick={() => handlePClick("Podcast")}
                            >
                              Podcast
                            </p>
                            <i
                              className="fas fa-arrow-left fs-4"
                              style={{
                                transform: "rotate(45deg)",
                                cursor: "pointer",
                                display:
                                  filterText === "" ||
                                  "@"
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                                    ? "block"
                                    : "none",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* mycontent */}
              </div>
              <button
                type="button"
                class="btn-close-search"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>

        {/* searchmodal */}
        <Modal show={show} onHide={handleClose} className="contactmodall">
          <Modal.Header>
            <Modal.Title
              className="d-flex align-items-center justify-content-between fs-2 w-100"
              style={{ fontFamily: "myFont" }}
            >
              Contact Us
              <IoCloseSharp
                className="fs-1"
                cursor={"pointer"}
                color="#9c90d8"
                onClick={() => handleClose()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h2 className=" py-2" style={{ fontFamily: "myFont" }}>
            Contact Us
          </h2> */}
            <form onSubmit={handleFormsubmit}>
              <div className="form-group my-4 position-relative">
                <input
                  type="text"
                  name="name"
                  className="form-control border border-dark contactForm"
                  value={formdata.name}
                  onChange={handleInputChange}
                  placeholder="Name"
                  required
                />
                <span className="text-danger position-absolute required-star">
                  *
                </span>
              </div>
              <div className="form-group my-4 position-relative">
                <input
                  type="email"
                  name="email"
                  className="form-control border border-dark contactForm "
                  id="formEmail"
                  placeholder="Email"
                  value={formdata.email}
                  onChange={handleInputChange}
                  required
                />
                <span className="text-danger position-absolute required-star">
                  *
                </span>
              </div>
              <div className="form-group my-4 position-relative">
                <PhoneInput
                  country={"in"}
                  value={formdata.phone}
                  placeholder="Enter Mobile Number"
                  enableSearch
                  id="phone-input"
                  onChange={handleChangePhoneNumber}
                  required
                  countryCodeEditable={false}
                  containerStyle={{ width: "100%" }}
                  inputStyle={{ width: "100%" }}
                />
                <span className="text-danger position-absolute required-star">
                  *
                </span>
              </div>
              <div class="form-group my-4">
                <textarea
                  class="form-control border border-dark contactForm"
                  placeholder="Ask Any Question"
                  id="formquestion"
                  value={formdata.question}
                  name="question"
                  onChange={handleInputChange}
                  rows={4}
                ></textarea>
              </div>
              <div className="form-group my-2">
                <button
                  type="submit"
                  className="btn px-3 py-1 text-light fs-5 cmn-t-shake "
                  style={{ background: "#9C90D8" }}
                >
                  {contactUsLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      Submit
                      <div
                        className="spinner-border ms-2 text-light"
                        role="status"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: ".5rem",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    `Submit`
                  )}
                </button>
                <div className="contacticons d-flex justify-content-center">
                  {contact?.phone ? (
                    <a
                      href={`tel:${contact?.phone}`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={contact?.phone}
                    >
                      <img src={phoneicon} alt="Phone" />
                    </a>
                  ) : null}

                  {contact?.email ? (
                    <a
                      href={`mailto:${contact?.email}`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={contact?.email}
                    >
                      <img src={gnmailicon} alt="Email" />
                    </a>
                  ) : null}

                  {contact?.address ? (
                    <a
                      href={contact?.address_Link}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Anand Dham Ashram
Bakkarwala Marg, Nangloi Najafgarh Road
New Delhi 110041"
                    >
                      <img src={locationicon} alt="Location" />
                    </a>
                  ) : null}

                  {contact?.whatsapp_Link ? (
                    <a
                      href={contact?.whatsapp_Link}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={contact?.whatsapp_Link}
                    >
                      <img src={Whatsapp} alt="WhatsApp" />
                    </a>
                  ) : null}
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>

      {/* <!-- Modal --> */}
      <div
        className={`modal fade ${showCommunityModal ? "show" : ""}`}
        id="communityModal"
        tabIndex="-1"
        aria-labelledby="communityModalLabel"
        aria-hidden={!showCommunityModal}
        style={{ display: showCommunityModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="communityModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Community Locked
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseCommunityModal}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 d-flex text-center align-items-center gap-2">
              {/* <img src={coming_soon_image} alt="Coming Soon Image" height={10} width={100}/> */}
              <IoLockOpen /> Please Buy Something To Unlock The Community !
            </div>
            {/* <div className="modal-footer">
  <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
