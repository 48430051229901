import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./style.css";
// import carousel1 from "../images/playy-button (1).png";
import carousel1 from "../../src/images/carousel1.png";
import carousel2 from "../../src/images/carousel2.png";
import { getDonationSuccessStories } from "../Allslices/donationSlice";
import { useDispatch, useSelector } from "react-redux";
import bgimg3 from "../../src/images/bgimg3.png";



const Carousel = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDonationSuccessStories());
  }, [dispatch]);

  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const { successstories } = useSelector((state) => state.donation)
  return (
    <>
    {successstories.length>0 &&( <div
      className="container-fluid py-5 donationmedia"
      style={{
        backgroundImage: `url(${bgimg3})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

      }}
    >
      <h3 className="text-center py-2" style={{ fontFamily: "myFont", fontSize: "40px" }}>Success Stories</h3>
      <div className="container">
        <div className="carousel-container" style={{ overflow: "hidden" }}>
          <Slider ref={sliderRef} {...settings}>

            {Array.isArray(successstories) &&
              successstories.map((item, index) => (
                <div className="slide" key={index}>
                  <div className="card mb-3 mx-3" style={{ borderRadius: "16px" }}>
                  {item?.status==true && (<div className="row g-0">

<div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-start p-3">
  <img src={item?.user_image} className="img-fluid rounded-start" alt="..." style={{ maxHeight: "160px" }} />
</div>
<div className="col-lg-9 col-sm-12 d-flex justify-content-center align-items-center">
  <div className="card-body ">

    <p className="card-text pt-2  " style={{
      fontSize: "15px", fontWeight: 600, fontFamily: "Playfair Display",textAlign:"justify"
    }}>{item?.description}</p>
    <hr className="m-0 p-0" style={{ color: "#c6c6c6",height:"5px" }} />
    <p className="card-text fw-bold "><small >{item?.user_name}</small></p>
  </div>
</div>
</div>)}

                    
                  </div>
                </div>

              ))}

            {/*  */}
            {/* Add more slides as needed */}
          </Slider>
          <div className="slider-buttons text-center d-flex gap-3 justify-content-center d-none d-sm-block">
            <button style={{ height: "8px", width: "4rem", backgroundColor: "#B767A2", borderRadius: "40px", border: "none", margin: '0rem 0.5rem' }} className="prev-button" onClick={goToPrev}>
            </button>
            <button style={{ height: "8px", width: "4rem", backgroundColor: "#B767A2", borderRadius: "40px", border: "none", margin: '0rem 0.5rem' }} className="next-button" onClick={goToNext}>
            </button>
            <button style={{ height: "8px", width: "4rem", backgroundColor: "#B767A2", borderRadius: "40px", border: "none", margin: '0rem 0.5rem' }} className="next-button" onClick={goToNext}>
            </button>
          </div>
        </div>
      </div>
    </div>)}
   
    </>
  );
};

export default Carousel;
