import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Rectangle74 from "../../../src/images/Rectangle 74.png";
import PhoneInput from "react-phone-input-2";
import "./auth.css";
import { forgotPassword } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../appUtils/appToast";
import MetaData from "../../component/layout/MetaData";
import 'bootstrap/dist/css/bootstrap.min.css';

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, error } = useSelector((state) => state?.auth);

  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangePhoneNumber = (value, country) => {
    setMobileNumber(value.slice(country.dialCode.length));
    setCountryCode(`+${country.dialCode}`);
  };

 

  // const sendOtpMobile = async (resetToken) => {
  //   let token = resetToken;
  //   try {
  //     setButtonDisabled(true);
  //     clearTimeout(timeoutRef.current);

  //     const recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
  //       size: "invisible",
  //       callback: () => {
  //         console.log("recaptcha resolved..");
  //       },
  //     });

  //     const fullPhoneNumber = `+${countryCode}${mobileNumber}`;

  //     console.log("The fullPhoneNumber is:", fullPhoneNumber);
  //     await signInWithPhoneNumber(auth, fullPhoneNumber, recaptchaVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult;
  //       });
  //     SHOW_SUCCESS_NOTIFICATION("OTP sent successfully");
  //     navigate("/otp", { state: { resetToken: token }});
  //     timeoutRef.current = setTimeout(() => {
  //       setButtonDisabled(false);
  //     }, 60000);
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //     if (error.code === 'auth/too-many-requests') {
  //       SHOW_ERROR_NOTIFICATION("Too many attempts. Please try again later.");
  //     } else {
  //       SHOW_ERROR_NOTIFICATION(error.message);
  //     }
  //     setButtonDisabled(false);
  //   }
  // };

  const handleNavigateToOtp = (e) => {
    e.preventDefault();

    setLoading(true);
    let error = false;
    let errormsg = "";

    if (mobileNumber === "" && email === "") {
      errormsg = "At least one email or mobile number is required";
      error = true;
    } else if (mobileNumber !== "") {
      if (!/^\d{10,15}$/.test(mobileNumber)) {
        errormsg = "Invalid mobile number";
        error = true;
      }
    } else if (email !== "") {
      const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailFormat.test(email)) {
        errormsg = "Enter a valid email address";
        error = true;
      }
    }

    const payload = {mobile: mobileNumber, email, countryCode};
    if (error) {
      SHOW_ERROR_NOTIFICATION(errormsg);
      setLoading(false);
      return;
    } else {
      if (mobileNumber !== "") {
        const mobile = mobileNumber;
        dispatch(forgotPassword(payload)).then((result) => {
          if (result.payload?.success === true) {
            // const resetTokenValue = result.payload.resetToken;
            navigate("/otp", {state: {mobile: mobile, countryCode: countryCode}});
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        dispatch(forgotPassword(payload)).then((result) => {
          if (result.payload?.success === true) {
            navigate("/otp", {state: {email: email}});
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (error) {
      SHOW_ERROR_NOTIFICATION(error?.message);
    }
  }, [error]);

  useEffect(() => {
    if (message) {
      SHOW_SUCCESS_NOTIFICATION(message?.message);
    }
  }, [message]);

  return (
    <div>
      <MetaData title="Forgot Password" />
      <div
        className="hero112 hero"
        style={{
          backgroundImage: `url(${Rectangle74})`,
          backgroundSize: "cover",
          height: "650px",
        }}
      >
        <div className="login12">
          <div className="text-center p-3">
            <h1>
              <span style={{ fontSize: "55px" }} className="loginfont">Forgot Password</span>
            </h1>
          </div>

          <div className="main_div">
            <div className="title">
              <p
                style={{ fontSize: "14px !important", fontFamily: "lato" }}
                className="pt-1"
              >
                Enter Your Mobile Number or Email
              </p>
            </div>
            <form>
              <div className="input-group mb-2 mt-3">
                <span
                  className="input-group-text bgPcolor text-white rounded-0"
                  id="basic-addon1"
                >
                  <i className="fa-solid fa-mobile-screen"></i>
                </span>
                <PhoneInput
                  country={"in"}
                  className="form-control ps-0 textgray"
                  enableSearch
                  inputClass="form-control rounded-0 border border-0 w-100 focus"
                  id="phone-input"
                  countryCodeEditable={false}
                  placeholder="Enter Mobile Number"
                  onChange={handleChangePhoneNumber}
                />
              </div>

              <div id="recaptcha"></div>
              <h5
                className="text-center fw-bolder"
                style={{ color: "#9C90D8", margin: ".7rem" }}
              >
                OR
              </h5>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bgPcolor text-white rounded-0"
                  id="basic-addon1"
                >
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <input
                  type="email"
                  name="email"
                  className="form-control "
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input_box button">
                {loading ? (
                  <button className="btn bgPcolor rounded-3 w-100 h-100" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                ) : (
                  <input
                    type="submit"
                    onClick={handleNavigateToOtp}
                    className="bgPcolor rounded-3"
                    value="Send OTP"
                    disabled={buttonDisabled}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
