import React, { useEffect } from "react";
import { setCurrentTrack, setTrackList } from "../Allslices/playerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import bg from "../../src/images/Rectangle 57 (3).png";
import { getSinglePodcast } from "../Allslices/podcastSlice";
import PodcastSongMusicPlayer from "./PodcastSongMusicPlayer";
import MetaData from "../component/layout/MetaData";

const PodcastMusicSubpage = ({ audioRef, songDetails, setSongDetails }) => {
  const { currentTrack, isPlaying, trackList, currentIndex } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();

  const { podcastslug } = useParams();

  let url = window.location.href;
  url = url.split("/");
  const urlleng = url.length;
  const songId = url[urlleng - 1];

  useEffect(() => {
    if (!isPlaying && currentTrack == null) {
      if (podcastslug) {
        dispatch(getSinglePodcast(podcastslug)).then((res) => {
          const songList = res.payload?.podcast?.songList;
          dispatch(setTrackList({ list: songList }));
          dispatch(
            setCurrentTrack(
              res.payload?.podcast?.songList.find(
                (song) => song?.slug == songId
              )
            )
          );
        });
      }
    }
  }, [podcastslug]);

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };

  return (
    <>
      <MetaData title={currentTrack?.description} />

      {currentTrack?.contentType === "video" ? (
        <>
          <div
            className="rect57bg blogimg mb-2"
            style={{
              backgroundImage: `linear-gradient(45deg, rgba(249, 200, 124, 0.3), rgba(249, 200, 123, 21)), url(${currentTrack?.thumbnail_image})`,
              backgroundSize: "cover",
              // filter: "blur(2px)"
            }}
          >
            <div className="container blogsub ">
              <div className="row text-center ">
                <div className="col-lg-12 col-sm-12">
                  <h2
                    className="text-dark"
                    style={{
                      fontFamily: "myFont!important",
                      paddingTop: "80px",
                    }}
                  >
                    {currentTrack?.title}
                  </h2>
                </div>
              </div>
            </div>
            <div className="wellnesssinglevideo" style={{ padding: "90" }}>
              <iframe
                src={currentTrack?.videoUrl}
                title={currentTrack?.title}
                className="singlevideoiframe singlewellnessiframe"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  borderRadius: "20px",
                  boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                }}
              ></iframe>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="textrt ">
                <p
                  className="contyu mt-5 text-center"
                  style={{ fontSize: "18px", padding: "75px 0 35px 0" }}
                  dangerouslySetInnerHTML={{
                    __html: currentTrack?.description,
                  }}
                ></p>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div
            className="conatainer-fluid podcastimg"
            style={{ overflow: "hidden" }}
          >
            <img
              src={bg}
              alt=""
              height={"400vh"}
              width={"100%"}
              className="image-fluid"
            />
            <div className="podcastimage pt-5 mt-5">
              {/* <img src={img2} alt="img" className="" /> */}
              <img
                src={currentTrack?.thumbnail_image}
                alt=""
                style={{
                  maxWidth: "90%",
                  maxHeight: "290px",
                  backgroundSize: "cover",
                }}
              />
              <div className="musicplayerpodcastCustom bg-light py-3 py-sm-1 px-sm-1 py-md-3 px-md-3 px-lg-4 py-lg-3 px-3 rounded-3">
                <PodcastSongMusicPlayer
                  onSeek={seekPoint}
                  albumId={podcastslug}
                  audioRef={audioRef}
                  time={songDetails?.time}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              </div>
            </div>
          </div>
          <div className="container text-center mb-4">
            <h2 className="fw-semibold" style={{ fontFamily: "myFont" }}>
              About Track
            </h2>
            <p style={{ fontFamily: "Lato" }}>{currentTrack?.description}</p>
            <div className="fw-bold">
              {/* <span>Label : <b >{currentTrack?.label?.map(item => item.title).join(', ')}</b></span> */}
              <br />
              {currentTrack?.artist?.length > 0 && (
                <span>
                  Artists :{" "}
                  <b>
                    {currentTrack?.artist?.map((item) => item.title).join(", ")}
                  </b>
                </span>
              )}
              <br />
              <span>
                category : <b>{podcastslug}</b>
              </span>
              <br />
              {/* <span>Genres : Pop-goth</span>
                            <br /> */}
              <span>
                Release Date :{" "}
                <Moment format="D MMM YYYY" withTitle>
                  {currentTrack?.created_at}
                </Moment>
              </span>
              <br />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PodcastMusicSubpage;
