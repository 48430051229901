import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlaying,
  nextTrack,
  prevTrack,
  setShowMusicBar,
} from "../Allslices/playerSlice";
import { convertToMins } from "../appUtils";
import rect259 from "../images/Rectangle 259.png";
import ".././component/layout/style.css";
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";

import { BsSoundwave } from "react-icons/bs";

const WisdomAudioPlayer = ({
  audioRef,
  onSeek,
  albumId,
  time,
  setSongDetails,
}) => {
  const dispatch = useDispatch();
  const { currentTrack, isPlaying, trackList } = useSelector(
    (state) => state.player
  );

  useEffect(() => {
    if (audioRef.current && currentTrack?.audiofile) {
      audioRef.current.src = currentTrack.audiofile; // Set the audio source URL
    }
  }, []);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef?.current.pause();
      dispatch(setPlaying(false));
      dispatch(setShowMusicBar(false));
    } else {
      audioRef?.current.play();
      dispatch(setPlaying(true));
      dispatch(setShowMusicBar(true));
    }
  };

  const handleNextSong = () => {
    if (trackList.length === 1) {
      restartSong();
    } else {
      dispatch(nextTrack());
    }
  };

  const handlePreviousSong = () => {
    if (trackList.length === 1) {
      restartSong();
    } else {
      dispatch(prevTrack());
    }
  };

  const restartSong = () => {
    setSongDetails((prev) => {
      return { ...prev, time: 0 };
    });
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  let trackRef = audioRef?.current;
  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 d-flex justify-content-center py-1">
          <span className="fw-semibold podcastss">{currentTrack?.title}</span>
        </div>

        <div className="col-lg-5 d-flex justify-content-center align-items-center py-1">
          <span style={{ cursor: "pointer" }}>
            <i className="fa-solid fa-backward-step fs-4 mx-4"></i>
          </span>
          <span
            onClick={currentTrack ? handlePlayPause : null}
            style={{ cursor: "pointer" }}
          >
            {isPlaying ? (
              <i className="fas fa-pause fs-4"></i>
            ) : (
              <i className="fas fa-play fs-4"></i>
            )}
          </span>
          <span style={{ cursor: "pointer" }}>
            <i className="fa-solid fa-forward-step fs-4 mx-4"></i>
          </span>
        </div>
      </div>

      <Flex justifyContent="space-between" gap={3}>
        <Slider
          outline={0}
          _focus={{ outline: 0 }}
          aria-label="seek-slider"
          defaultValue={0}
          width="100%"
          onChange={onSeek}
          value={!isNaN(time) ? time : 0}
        >
          <SliderTrack bg="gray.400">
            <SliderFilledTrack bg="#b767a2" />
          </SliderTrack>
          <SliderThumb boxSize={5} outline={0}>
            <Box color="#b767a2" as={BsSoundwave} />
          </SliderThumb>
        </Slider>
        {/* <Text fontSize="xs" color="zinc.500">
          {track?.duration.split(".").join(":")}
        </Text> */}
      </Flex>
      <div className="d-flex justify-content-between px-2 ">
        <div className="d-flex gap-2 text-secondary">
          <span>
            {audioRef.current
              ? convertToMins(audioRef.current.currentTime)
              : "0:00"}
          </span>
          /
          <span>
            {audioRef.current?.duration
              ? convertToMins(audioRef.current.duration)
              : "0:00"}
          </span>
        </div>
      </div>
    </>
  );
};

export default WisdomAudioPlayer;
