import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../component/auth/authSlice'
import contactReducer from '../Allslices/mainSlice'
import blogReducer from '../Allslices/blogSlice'
import privacyReducer from "../Allslices/configSlice"
import communityReducer from "../Allslices/communitySlice"
import albumReducer from "../Allslices/albumSlice"
import galleryReducer from "../Allslices/gallerySlice"
import songReducer from "../Allslices/songSlice"
import playerReducer from "../Allslices/playerSlice"
import termsAndConditionsReducer from "../Allslices/t&cSlice"
import aboutusReducer from '../Allslices/aboutusSlice'
import homeReducer from '../Allslices/homeSlice'
import tagReducer from '../Allslices/tagSlice'
import donationImpactReducer from '../Allslices/donationImpactSlice'
import donationReducer from '../Allslices/donationSlice'
import donationVideoReducer from '../Allslices/donationSlice'
import wellnessReducer from '../Allslices/wellnessSlice'
import wisdomReducer from '../Allslices/wisdomSlice'
import videoReducer from '../Allslices/videoSlice'

import podcastReducer from '../Allslices/podcastSlice'
import BhagwatReducer from "../Allslices/BhagwatGitaSlice"
import courseReducer from '../Allslices/courseSlice'
import contactUsReducer from '../Allslices/ContactUsSlice'
import orderReducer from '../Allslices/orderSlice'
import createSubcription from "../Allslices/subcription"
import coinsvalueReducers from "../Allslices/coinsSlice"
import EventConfigReducers from "../Allslices/EventConfigSlice"
import MeditationReducer from "../Allslices/meditationSlice"
import promoReducer from '../Allslices/PromoSlice'
import bookpageReducer  from '../Allslices/BookSlice'
import countryStateCityReducer from '../Allslices/countrycitystateSlice'
import occupationReducer from '../Allslices/occupationSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    contact: contactReducer,
    blog: blogReducer,
    privacy: privacyReducer,
    community: communityReducer,
    album: albumReducer,
    song: songReducer,
    gallery: galleryReducer,
    player: playerReducer,
    terms: termsAndConditionsReducer,
    aboutus: aboutusReducer,
    home: homeReducer,
    tag: tagReducer,
    donationImpact: donationImpactReducer,
    donation: donationReducer,
    donationVideo: donationVideoReducer,
    allwellness: wellnessReducer,
    wisdom: wisdomReducer,
    video:videoReducer,
    podcast: podcastReducer,
    BhagwatGita: BhagwatReducer,
    course: courseReducer,
    contactUs:contactUsReducer,
    order: orderReducer,
    subscription:createSubcription,
    coinsdata:coinsvalueReducers,
    Event_Config:EventConfigReducers,
    singlearticlewellness:wellnessReducer,
    meditation_page:MeditationReducer,
    promoCode: promoReducer,
    bookpage:bookpageReducer,
    countrystatecity:countryStateCityReducer,
    occupationList: occupationReducer,
  }
})