import React, { useEffect, useState } from "react";
import Meditationimg from "../../src/images/Meditation-Service 1.png";
import Reactangle6 from "../../src/images/Rectangle6.png";
import Reactangle473 from "../../src/images/Rectangle 473 edit.png";
import MetaData from "../component/layout/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { getMeditation, getAllMeditation } from "../Allslices/meditationSlice";
import { useNavigate, useParams } from "react-router";
import SmallLoader from "./SmallLoader";

export default function Meditation() {
  const dispatch = useDispatch();
  const { meditation_config, meditation_retreats } = useSelector(
    (state) => state.meditation_page
  );

  useEffect(() => {
    dispatch(getMeditation());
    dispatch(getAllMeditation());
  }, []);

  let navigate = useNavigate();
  const { slug } = useParams();

  const handleClick = (slug) => {
    navigate(`/meditation/SinglePage/${slug}`, { state: { default_image: meditation_config?.default_image } });
  };


  return (
    <>
      <MetaData title={meditation_config?.meta_title} description={meditation_config?.meta_description} tags={meditation_config?.meta_tag} />
      <div>
        {meditation_config ? (

          <>
            <div className="vidomediaquery desktop-only" style={{ backgroundSize: "cover", overflow: "hidden", backgroundImage: `url(${meditation_config?.background_image})`, height: "616px" }} >
              <div className="container wisdompad desktop-only">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                    {meditation_config?.alignItem === "left" && (<>
                      <div>
                        <h1 className="fw-bold mt-5">
                          <span className="wisdomhead haedingfont">
                            {meditation_config?.heading}
                          </span>
                        </h1>
                        <p className="pt-4  text-dark fw-bold fs-5 meditationmchek " style={{ textAlign: "justify", fontFamily: "Lato" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: meditation_config?.subheading,
                    }}
                  />
                </p>
                      </div></>)}
                     
                  </div>
                  <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                    {meditation_config?.alignItem === "right" && (<>
                      <div>
                        <h1 className="fw-bold mt-5 ">
                          <span className="wisdomhead haedingfont">
                            {meditation_config?.heading}
                          </span>
                        </h1>
                        <p className="pt-4  text-dark fw-bold fs-5 meditationmchek " style={{ textAlign: "justify", fontFamily: "Lato" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: meditation_config?.subheading,
                    }}
                  />
                </p>
                      </div></>)}

                  </div>
                </div>
              </div>


            </div>
            


            <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${meditation_config?.res_background_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {meditation_config?.alignItem  === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: meditation_config?.res_heading,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {meditation_config?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: meditation_config?.res_heading,
                      }}
                    ></p>
                  </>)}
                </div>

              </div>

            </div>

          </div>

          {/* <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: wisdombanner?.res_subheading,
                }}
               >
               
               </p>
              
             </div> */}

             
          {/* banner ends here */}

          </div>


          
          </>

       




        ) : (<SmallLoader />)}

        {meditation_retreats ? (<section>
          <div
            className=""
            style={{
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(156, 144, 216, 0.2) 100%)`,
              backgroundSize: "cover",
            }}
          >
            <div className="container d-lg-none d-md-block d-sm-block ">
              <div className=" text-center lh-lg ">
                <p className="pt-3  text-dark fw-bold fs-5 meditationmchek p-2" style={{ textAlign: "justify", fontFamily: "Lato" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: meditation_config?.subheading,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="text-center py-5 singleevntsres">
            <h1
              style={{
              
                fontWeight: "bold",
                fontFamily: "myFont",
              }}
            >
              Meditation Retreats
            </h1>

            <div className="py-1 mainmedicont">

              {Array.isArray(meditation_retreats) && meditation_retreats.filter((item) => item?.status == true)
              .map((item, index) => (
                <div
                  onClick={() => handleClick(item?.slug)}
                  className="card item-card container my-4 meditaii11"
                  style={{
                    backgroundImage: `url(${item?.thumbnail_image})`,
                    backgroundSize: "cover"
                  }}
                  key={index}
                >
                  {/* <img
                    src={item?.thumbnail_image}
                    alt=""
                    // height={300}
                    style={{ borderRadius: "16px" }}
                  /> */}
                  <div className="card-img-overlay d-flex align-items-center justify-content-center p-0 ">
                    <h5 className="card-title">
                      <span
                        
                      >
                        {item?.title}
                      </span>
                    </h5>
                  </div>
                </div>
              ))}




            </div>
          </div>
        </section>) : (<SmallLoader />)}
      </div>



    </>
  );
}
