import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCurrentTrack,
  setShowMusicBar,
  setTrackList,
} from "../Allslices/playerSlice";
import { getSinglePodcast } from "../Allslices/podcastSlice";
import Moment from "react-moment";

const PodcastsongList = ({ song, handlePlay, handlePause }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentpathUrl = location.pathname.split('/');
  const albumid = currentpathUrl[2];

  const { podcast } = useSelector((state) => state?.podcast);
  const { currentTrack, isPlaying, trackList } = useSelector(
    (state) => state.player
  );
  const isCurrentTrack = currentTrack?._id === song?._id;

  // Fetch podcast data when albumid changes
  useEffect(() => {
    dispatch(getSinglePodcast(albumid));
  }, []);

  const playSong = () => {
    handlePlay(song);
  };

  const pauseSong = () => {
    handlePause(song);
  };

  const handleNavigateToSongpage = (slug) => {
    const index = podcast?.songList.findIndex((s) => s.slug == slug);
    dispatch(setTrackList({ list: podcast?.songList, index }));
    dispatch(setCurrentTrack(song));
    navigate(`/podcast/${albumid}/${slug}`);
  };

  const titlemaxLength = 40;

  return (
    <div className="elemt pt-2" key={song._id}>
      <div className="apixos p-2 pt-4 fw-semibold position-relative">
        {song ? (
          song?.contentType === 'video' ? (
            <i className="fa-solid fa-video fs-4 pt-2"></i>
          ) : (
            isCurrentTrack && isPlaying ? (
              <i
                onClick={pauseSong}
                className="fas fa-pause fs-4"
                style={{ marginTop: "10px", cursor: "pointer" }}
              ></i>
            ) : (
              <i
                onClick={!song?.isPaid ? playSong : null}
                className="fas fa-play fs-4"
                style={{ marginTop: "10px", cursor: "pointer" }}
              ></i>
            )
          )
        ) : null}




        <p className="pt-2 yera yeratitle">
          {song?.title?.length > titlemaxLength
            ? `${song.title.substring(0, titlemaxLength)}...`
            : song?.title}
        </p>
        {isCurrentTrack && isPlaying ? (
          <iframe
            src="https://giphy.com/embed/H4Ke1ghWdhic0HLZ6L"
            width="60"
            height="36"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        ) : null}
        <p className="pt-2">
          <Moment format="D MMM YYYY" withTitle>
            {song?.created_at}
          </Moment>
        </p>

        {song?.contentType === "video" ? (
          <p className="yera fw-semibold">
            <i className="fa-solid fa-video px-2" style={{ color: "#9767a2" }}></i>
          </p>
        ) : (
          <p className=" yera fw-semibold">
            <i className="fa-solid fa-headphones px-2 " style={{ color: "#9767a2" }}></i>
          </p>
        )}
        <a
          className="btn vieww text-dark"
          onClick={() => handleNavigateToSongpage(song.slug)}
        >
          View More <i className="fa-solid fa-angle-right text-dark"></i>
        </a>
      </div>
    </div>
  );
};

export default PodcastsongList;
