import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
};


export const contactus = createAsyncThunk(
    'contact/contactus',
    async (contactData, { dispatch, rejectWithValue }) => {
        try {
            dispatch(contactusStart());
            const response = await axios.post(`user/contactUsController`, contactData);
            dispatch(contactusSuccess(response))
            return response;
        } catch (error) {
            dispatch(contactusfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message);
        }
    }
)


const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        contactusStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        contactusSuccess(state, action) {
            state.isLoading = false;
        },
        contactusfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false
        }

    }
})

export const {contactusStart,contactusSuccess,contactusfailure} = contactSlice.actions;
export default contactSlice.reducer;