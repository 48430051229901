import React, { useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getConfigVideo, getsinglelistvideo, putinterstvideo } from "../Allslices/videoSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import MetaData from "../component/layout/MetaData";
import Moment from "react-moment";
import Slider from "react-slick";

const SingleVideo = () => {
  const dispatch = useDispatch();

  const { singlevideo, videoConfig, videotag } = useSelector((state) => state?.video);

  let sliderRef1 = useRef();
  const navigate = useNavigate();
  const tags = singlevideo?.tags?.map((item) => item?._id) ?? [];
  const numCards = videotag?.length || 0
  const minSlidesToShow = Math.min(4, numCards);
  const maxLength = 100;
  const maxLength2 = 100;

  const settings1 = {
    // dots: true,
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 800,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const { slug } = useParams();
  useEffect(() => {
    dispatch(getsinglelistvideo(slug));
    dispatch(getConfigVideo());
  }, [dispatch, slug]);

  useEffect(() => {
    dispatch(putinterstvideo({ tags }));
  }, [singlevideo])
  const url = window.location.href;
  // const shareMessage = `${singlevideo?.meta_title}\n\n${singlevideo?.meta_description}\n\n#${singlevideo?.meta_tag}\n\n`;
  // const shareHashtag = `#${singlevideo?.meta_title}\n#${singlevideo?.meta_tag}#${singlevideo?.title}\n#${singlevideo?.title}\n${Array.isArray(singlevideo?.tags) && singlevideo?.tags.length > 0 && singlevideo?.tags?.map((item) => `#${item?.name}`)}#${singlevideo?.slug}\n#DrArchikaDidi`;
  const shareHashtag_twitter = [`#${singlevideo?.meta_title}\n#${singlevideo?.meta_tag}#${singlevideo?.title}\n#${singlevideo?.title}\n${Array.isArray(singlevideo?.tags) && singlevideo?.tags.length > 0 && singlevideo?.tags?.map((item) => `#${item?.name}`)}#${singlevideo?.slug}\n#DrArchikaDidi`];
  const shareHashtag = `${singlevideo?.meta_title}\n#${singlevideo?.meta_tag}#${singlevideo?.title}\n#${singlevideo?.title}\n${Array.isArray(singlevideo?.tags) && singlevideo?.tags.length > 0 && singlevideo?.tags?.map((item) => `#${item?.name}`)}#${singlevideo?.slug}\n#DrArchikaDidi`;


  const shareMessage = `${singlevideo?.meta_title}\n\n${singlevideo?.meta_description}`;
  const shareHashtag_fb = `#${singlevideo?.meta_tag || 'DrArchikaDidi'}`;



  let splitUrl;

  if (singlevideo?.page_banner_image) {
    splitUrl = singlevideo?.page_banner_image.split(
      "http://api.drarchikadidi.com/"
    );
    // splitUrl = singlevideo?.page_banner_image.split('http://localhost:8080/');
  }
  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? videoConfig?.default_image
        : singlevideo?.page_banner_image;
  }
  const handleNavigateToWellnessSingle = (slug) => {
    navigate(`/video/singleVideo/${slug}`);
  };

  return (
    <>
      <MetaData title={singlevideo?.meta_title} description={singlevideo?.meta_description} tags={singlevideo?.meta_tag} />
      <section>
        <div
          className="blogimg mb-2 rect571bg"
          style={{ backgroundImage: `url(${bannerImage})` }}
        >
          <div className="container blogsub">
            <div className="row justify-content-center ">
              <div className="col-lg-12 col-md-12 text-center">
                <h4
                  className="text-dark mt-5 text-capitalize sbpagetitle"
                  style={{ fontFamily: "playfair" }}
                >
                  {singlevideo?.title}
                </h4>
                <p
                  className="pt-3 videosubheading text-dark fw-semibold "
                  style={{ fontFamily: "playfair" }}
                >
                  {singlevideo?.shortdescription}
                </p>
                <div className="imagesee  ">
                  <iframe
                    // width="560"
                    // height="315"
                    src={singlevideo?.videourl}
                    title={singlevideo?.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    className="singlevideoiframe"
                    allowFullScreen
                    style={{
                      borderRadius: "20px",
                      boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container socialicons ">
        <div className="textrt ">
          <div className="row icotext text-center ">
            <div className="col-lg-6 col-5 p-1 ">
              {/* <Moment
                style={{ fontSize: "16px" }}
                format="D MMM YYYY"
                withTitle
              >
                {singlevideo?.createdAt}
              </Moment> */}
            </div>

            <div className="col-lg-6 col-7 d-flex justify-content-center align-items-center ">
              {/* <a href="#" className="p-1 "> */}
              <a  className="p-1 ">
                <FacebookShareButton
                  url={url}
                  hashtag={shareHashtag_fb}
                  quote={shareMessage}
                >
                  <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
              </a>

              {/* <a href="#" className="p-1">
          <FacebookMessengerShareButton
            appId="1292589614774311"
            title="Share on Messenger"
            url={url}
            hashtag="#ArchikaDidiBlog"
          >
            <FacebookMessengerIcon size={45} round={true} />
          </FacebookMessengerShareButton>
        </a> */}

              {/* <button
  className="react-share__ShareButton"
  style={{ background: "#3885f7" }}
  onClick={() => {
    window.open('fb-messenger://share?link=' + encodeURIComponent(url) + '&app_id=' + encodeURIComponent(url));
  }}
>
  <FacebookMessengerIcon
    size={45}
    round={true}
    bgStyle={{ fill: "none",round:"true" }}
  />
</button>  */}

              {/* <a href="#" className="p-1">instagram</a> */}
              <a  className="p-1">
                <WhatsappShareButton
                  appId=""
                  title={shareMessage}
                  url={url}
                  hashtag={shareHashtag}
                >
                  <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
              </a>
              <a  className="p-1">
                <TwitterShareButton
                  appId=""
                  title={shareMessage}
                  hashtag={shareHashtag_twitter}
                  url={url}
                >
                  <XIcon size={30} round={true} />
                </TwitterShareButton>
              </a>
              <a  className="p-1">
                <TelegramShareButton
                  title={shareMessage}
                  url={url}
                >
                  <TelegramIcon size={30} round={true} />
                </TelegramShareButton>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* content paragraph  */}
      <section>
        <div className="container">
          <div className="row  justify-content-center mt-4">
            <div className="col-lg-8 col-md-12 col-sm-12 text-center mb-5 text-capitalize">
              <p
                style={{ color: "rgb(33 33 33)", textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: singlevideo?.description }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      {/* text section all end */}
      {Array.isArray(videotag) && videotag.length == 0 ? (
        ""
      ) : (
        <div
          className="mahab-mixer d-none d-lg-block"
          style={{ overflow: "hidden" }}
        >
          <h1 className="text-center pt-2  mt-5">


            <span className="haedingfont">Related Video </span>

          </h1>

          <div
            className="container artvid  singlewisdomart pb-4 mb-5 "
            style={{ overflow: "hidden" }}
          >
            <div className={`row g-3 py-2 artvid33 ${videotag?.length <= 2 ? 'sliderscontainer' : ''}`}>
              <Slider
                ref={(slider) => {
                  sliderRef1 = slider;
                }}
                {...settings1}
              >
                {Array.isArray(videotag) &&
                  videotag?.filter((e) => e?._id !== singlevideo?._id).map((item, index) => (
                    <div
                      key={index}
                      className="col-md-6 col-sm-12 col-lg-3"
                      onClick={() => handleNavigateToWellnessSingle(item?.slug)}
                      style={{ borderRadius: "22px" }}
                    >
                      <div
                        className="card item-card card-block mx-2 my-3 overflow-hidden"
                        style={{
                          borderRadius: "10px",
                          height: "450px",
                        }}
                      >
                        <img
                          src={item?.thumbnail_image}
                          alt="Photo of sunset"
                          style={{ aspectRatio: "3/2" }}
                        />

                        <div
                          className="d-flex justify-content-end"
                          style={{ position: "relative", right: "26px" }}
                        >
                          <h6 className="pt-0">
                            <h6 className="pt-3">
                              Video
                            </h6>
                          </h6>
                          <i
                            className='fa-solid fa-circle-play'
                            style={{
                              position: "relative",
                              top: "18px",
                              left: "9px",
                            }}
                          ></i>
                        </div>
                        <div className="container">
                          <h4
                            className="mt-3"
                            style={{
                              fontFamily: "Playfair Display SC",
                              color: "#000000",
                            }}
                          >
                            {item?.title?.length > maxLength2
                              ? `${item?.title?.substring(0, maxLength2)}...`
                              : item?.title}
                          </h4>
                          <p
                            className="card-text lato"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.description.length > maxLength
                                  ? `${item.description.substring(
                                    0,
                                    maxLength
                                  )}...`
                                  : item.description,
                            }}
                            style={{
                              marginLeft: "0.0rem",
                              fontFamily: "Lato",
                              color: "#5B5B5B",
                            }}
                          ></p>


                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleVideo;
