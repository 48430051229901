import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getsingleBlog, getAllBlogs, getBlogs, putinterstBlog } from "../Allslices/blogSlice";
import LoaderPage from "./LoaderPage";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import Moment from "react-moment";
import MetaData from "../component/layout/MetaData";
import Slider from "react-slick";

export default function BlogSubpage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  // const url = window.location.href;
  const url = `https://drarchikadidi.com/blog/international-day-violence-women`;
  // const url = `http://localhost:3000/blog/international-day-violence-women`;
  // const url = `https://drarchikadidi.com`;

  useEffect(() => {
    dispatch(getsingleBlog(slug));
    dispatch(getAllBlogs());
    dispatch(getBlogs());

  }, [dispatch, slug]);

  const { singleblog, blogbanner, blogtag } = useSelector((state) => state.blog);

  const tags = singleblog?.tags?.map((item) => item?._id) ?? [];

  useEffect(() => {
    dispatch(putinterstBlog({ tags }));
  }, [singleblog])

  let sliderRef1 = useRef();
  const numCards = blogtag?.length || 0
  const minSlidesToShow = Math.min(4, numCards);
  const maxLength = 100;
  const maxLength2 = 100;

  const settings1 = {
    // dots: true,
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 800,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  let splitUrl = singleblog.page_banner_image?.split('http://api.drarchikadidi.com/');
  let bannerImage;

  if (splitUrl) {
    bannerImage = splitUrl[1] == "publicundefined" ? blogbanner?.default_background_image : singleblog?.page_banner_image;
  }

  // const shareMessage = `${singleblog?.meta_title}\n\n${singleblog?.meta_description}\n\n#${singleblog?.meta_tag}\n\n`;
  const shareHashtag_twitter = [`#${singleblog?.meta_title}\n#${singleblog?.meta_tag}#${singleblog.title}\n#${singleblog?.title}\n${Array.isArray(singleblog?.tags) && singleblog?.tags.length > 0 && singleblog?.tags?.map((item) => `#${item?.name}`)}#${singleblog.slug}\n#DrArchikaDidi`];
  const shareHashtag = `${singleblog?.meta_title}\n#${singleblog?.meta_tag}#${singleblog.title}\n#${singleblog?.title}\n${Array.isArray(singleblog?.tags) && singleblog?.tags.length > 0 && singleblog?.tags?.map((item) => `#${item?.name}`)}#${singleblog.slug}\n#DrArchikaDidi`;


  const shareMessage = `${singleblog?.meta_title}\n\n${singleblog?.meta_description}`;
  const shareHashtag_fb = `#${singleblog?.meta_tag || 'DrArchikaDidi'}`;


  const handleNavigatetoSubpage = (slug) => {
    navigate(`/blog/${slug}`, { state: { scrollY: window.scrollY } });
  };
  return (
    <>
      {singleblog ? (
        <>
          <MetaData
            title={singleblog.meta_title}
            description={singleblog.meta_description}
            tags={singleblog.meta_tag}
            imageURL={singleblog.thumbnail_image}
            url={url}
          />
          {/* banner section */}
          <div className=" blogimg mb-2" style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: "cover"
          }}>
            <div className="container blogsub">
              <div className="row text-center p-2">
                <div className="col-lg-12 col-sm-12 mt-5">
                  <h4
                    className=" text-dark text-capitalize sbpagetitle"
                    style={{ fontFamily: "myFont" }}
                  >
                    {singleblog.title}
                  </h4>
                  <p className="pt-4 pb-5 text-dark fw-bold lato" style={{ fontFamily: "lato" }}>
                    {singleblog.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="imagesee pt-5">



            <img
              src={singleblog.thumbnail_image}
              alt="img"
              className=""
              style={{
                borderRadius: "10px",
                boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
              }}
            />
          </div>
          {/* banner section end */}
          {/* text section all */}
          <section>
            <div className="container">
              <div className="textrt">
                <div className="row icotext icotextblog text-center">
                  <div className="col-lg-6 col-5 dateblog">
                    <p className="fw-bold">
                      Published :{" "}
                      <Moment format="D MMM YYYY" withTitle>
                        {singleblog.createdAt}
                      </Moment>
                    </p>
                  </div>
                  <div className="col-lg-6 col-7 dateblog">
                    {/* <a href="#" className="p-1">*/}
                    <a className="p-1">
                      <FacebookShareButton
                        url={url}
                        // quote={'Title or jo bhi aapko likhna ho'}
                        imageURL={singleblog.thumbnail_image}
                        hashtag={shareHashtag_fb}
                        quote={shareMessage}
                        
                        // hashtag={'#portfolio...'}
                      >
                        <FacebookIcon size={30} round={true} />
                      </FacebookShareButton>
                    </a>
                    <a className="p-1">
                      <WhatsappShareButton
                        title={shareMessage}
                        url={url}
                        hashtag={shareHashtag}
                      >
                        <WhatsappIcon size={30} round={true} />
                      </WhatsappShareButton>
                    </a>
                    <a className="p-1">
                      <TwitterShareButton
                        title={shareMessage}
                        hashtag={shareHashtag_twitter}
                        url={url}
                      >
                        <XIcon size={30} round={true} />
                      </TwitterShareButton>
                    </a>
                    <a className="p-1">
                      <TelegramShareButton
                        title={shareMessage}
                        url={url}
                      >
                        <TelegramIcon size={30} round={true} />
                      </TelegramShareButton>
                    </a>
                  </div>
                </div>
                <div
                  className="contyu px-2 mt-5 blogsubdescription"
                  dangerouslySetInnerHTML={{ __html: singleblog.content }}
                />
                <br />
                <br />
              </div>
            </div>
          </section>
          {/* text section all end */}
          {Array.isArray(blogtag) && blogtag.length == 0 ? (
            ""
          ) : (
            <div
              className="mahab-mixer d-none d-lg-block"
              style={{ overflow: "hidden" }}
            >
              <h1 className="text-center pt-2  mt-5">

                <span className="haedingfont">Related Blog </span>

              </h1>

              <div
                className="container artvid  singlewisdomart pb-4 mb-5 "
                style={{ overflow: "hidden" }}
              >
                <div className={`row g-3 py-2 artvid33 ${blogtag?.length <= 2 ? 'sliderscontainer' : ''}`}>
                  <Slider
                    ref={(slider) => {
                      sliderRef1 = slider;
                    }}
                    {...settings1}
                  >
                    {Array.isArray(blogtag) &&
                      blogtag?.filter((e) => e?._id !== singleblog?._id).map((item, index) => (
                        <div
                          key={index}
                          className="col-md-6 col-sm-12 col-lg-3"
                          onClick={() => handleNavigatetoSubpage(item?.slug)}
                          style={{ borderRadius: "22px" }}
                        >
                          <div
                            className="card item-card card-block mx-2 my-3 overflow-hidden"
                            style={{
                              borderRadius: "10px",
                              height: "450px",
                            }}
                          >
                            <img
                              src={item?.thumbnail_image}
                              alt="Photo of sunset"
                              style={{ aspectRatio: "3/2" }}
                            />

                            {/* <div
                            className="d-flex justify-content-end"
                            style={{ position: "relative", right: "26px" }}
                          >
                            <h6 className="pt-0">
                              <h6 className="pt-3">
                                Video
                              </h6>
                            </h6>
                            <i
                              className='fa-solid fa-circle-play'
                              style={{
                                position: "relative",
                                top: "18px",
                                left: "9px",
                              }}
                            ></i>
                          </div> */}
                            <div className="container">
                              <h4
                                className="mt-3"
                                style={{
                                  fontFamily: "Playfair Display SC",
                                  color: "#000000",
                                }}
                              >
                                {item.title.length > maxLength2
                                  ? `${item.title.substring(0, maxLength2)}...`
                                  : item.title}
                              </h4>
                              <p
                                className="card-text lato"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.description.length > maxLength
                                      ? `${item.description.substring(
                                        0,
                                        maxLength
                                      )}...`
                                      : item.description,
                                }}
                                style={{
                                  marginLeft: "0.0rem",
                                  fontFamily: "Lato",
                                  color: "#5B5B5B",
                                }}
                              ></p>


                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  );
}
