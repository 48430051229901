import React, { useEffect, useRef, useState } from "react";
import ".././component/layout/style.css";
import Yogashala from "../../src/images/zapp2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getwisdomList,
  getwisdomConfig,
  getwisdomCategory,
  getLanguages,
  filtersUpdate,
} from "../Allslices/wisdomSlice";
import SmallLoader from "./SmallLoader";
import { getHome } from "../Allslices/homeSlice";
import MetaData from "../component/layout/MetaData";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import nodata from "../images/No-Data.png";
import usePreserveScroll from "./histrorysave";

const Wisdom = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();
  const handleNavigateToWisdomSubpage = (slug) => {
    navigate(`/wisdom/${slug}`, { state: { scrollY: window.scrollY } });
  };

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  const query = location.search.split("?")[1];


  const {
    wisdom = [],
    currentPage,
    limit,
    isLoading,
    totalwisdom,
    wisdombanner,
    wisdomCategories,
    Languages,
    Filters: { categoryId, language, sort, contentType, text, isSearched }
  } = useSelector((state) => state.wisdom);
  const { home } = useSelector((state) => state.home);


  useEffect(() => {
    dispatch(getwisdomConfig());
    dispatch(getwisdomCategory());
    dispatch(getLanguages());
    dispatch(getHome());
    // dispatch(
    //   getwisdomList({
    //     page: currentPage,
    //     limit: limit,
    //   })
    // );
    window.scrollTo(
      {
        top: 750,
        behavior: 'smooth'
      })

  }, []);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value
    };

    dispatch(filtersUpdate(payload));
  };


  const handleFiltersSubmit = (e, action) => {

    if (action == "loadmore") {
      dispatch(
        getwisdomList({
          page: currentPage,
          limit: limit + 4,
        })
      )
    } else {

     
      dispatch(
        getwisdomList({
          page: currentPage,
          limit: limit,
        })
      )

      window.scrollTo(
        {
          top: 650,
          behavior: 'smooth'
        })
    }

  };
  const maxLength = 40;
  const maxLength1 = 115;


  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit()
  }, [query])



  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  return (
    <>
      <MetaData title={wisdombanner?.meta_title} description={wisdombanner?.meta_description} tags={wisdombanner?.meta_tag} />
      {wisdom ? (
        <>
          <div
            className="wisdomimg desktop-only "
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${wisdombanner?.background_image})`,
            }}
          >
            <div className="container wisdompad ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {wisdombanner?.alignItem === "left" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {wisdombanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes mt-4"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {wisdombanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {wisdombanner?.alignItem === "right" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {wisdombanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes mt-4"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {wisdombanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>


          {/* banner starts here */}


          <div className="mobile-only">
            <div className="container-fluid mobile-only" style={{ position: "relative", display: "flex" }}>
              <img src={`${wisdombanner?.res_background_image}`} />
              <div className="container" style={{ position: "absolute", height: "100%" }}
              >
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-6 d-flex justify-content-start align-items-center">

                    {wisdombanner?.alignItem === "left" && (<>
                      <p
                        className="homebannertitle"
                        style={{ fontFamily: "myFont", fontSize: "24px" }}
                        dangerouslySetInnerHTML={{
                          __html: wisdombanner?.res_heading,
                        }}
                      ></p>
                    </>)}

                  </div>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    {wisdombanner?.alignItem === "right" && (<>
                      <p
                        className="homebannertitle"
                        style={{ fontFamily: "myFont", fontSize: "24px" }}
                        dangerouslySetInnerHTML={{
                          __html: wisdombanner?.res_heading,
                        }}
                      ></p>
                    </>)}
                  </div>


                </div>

              </div>

            </div>

            <div className="container">
              <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: wisdombanner?.res_subheading,
                }}
              >

              </p>

            </div>


            {/* banner ends here */}

          </div>


          {/* banner ends here */}


          <div className="container filterpad pt-3 shadow">
            <div className="row pb-4 p-3">
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                id="mobileScreen"
              >
                <label
                  for="filter"
                  className="fw-bold myfiltericon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img style={{ width: "25px" }} src={filtericon} alt="" />
                </label>

                <select
                  className="select searchborder rounded-pill p-2 w-100 customselect"
                  value={language}
                  name="language"
                  id="selectFilter"
                  onChange={(e) => handleFilterChange(e)}
                  onKeyDown={handleKeyPress}
                >
                  <option value="">Language</option>
                  {Array.isArray(Languages) &&
                    Languages?.map((item, index) => {
                      return (
                        <option value={item?.language}>{item?.language}</option>
                      );
                    })}
                </select>
              </div>

              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  value={categoryId}
                  name="categoryId"
                  className="select searchborder rounded-pill p-2 w-100 customselect"
                  onChange={(e) => handleFilterChange(e)}
                  id="selectFilter"
                  onKeyDown={handleKeyPress}
                >
                  <option value="">Category</option>
                  {Array.isArray(wisdomCategories) &&
                    wisdomCategories?.map((item, index) => {
                      return <option value={item?._id}>{item?.title}</option>;
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  value={contentType}
                  name="contentType"
                  className="select  searchborder rounded-pill p-2 w-100 customselect"
                  onChange={(e) => handleFilterChange(e)}
                  id="selectFilter"
                  onKeyDown={handleKeyPress}
                >
                  <option value="">Content Type</option>
                  <option value="article">Article</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <label
                  for="filter"
                  className="fw-bold mysearchicon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img
                    src={searchicon}
                    alt=""
                    style={{ width: "25px" }}
                    onClick={handleFiltersSubmit}
                  />
                </label>
                <form class=" w-100 pt-4" role="search">
                  <input
                    className="form-control  searchborder rounded-pill p-2 w-100 searchborder"
                    type="search"
                    name="text"
                    placeholder="Search by keyword..."
                    aria-label="Search"
                    onChange={(e) => handleFilterChange(e)}
                    onKeyDown={handleKeyPress}
                    value={text}
                    style={{ borderRadius: "19px", color: "#A0A0A0" }}
                  />
                  {!text && <i className="fa-solid fa-magnifying-glass mysearchiconforphone"></i>}
                </form>
              </div>

              <div className=" d-flex justify-content-center pt-3">
                <button
                  className="btn  btn-lg text-light cmn-t-shake shadowbuttons mb-3"
                  style={{
                    padding: "0.6rem 2.5rem",
                  }}
                  onClick={handleFiltersSubmit}
                >
                  SEARCH
                </button>
              </div>
            </div>
          </div>

          <section>
            <div className="container artvid mt-2">
              {wisdom?.length > 0 ? <div className="row g-3" ref={listStartRef} id="listStart">
                {Array.isArray(wisdom) &&
                  wisdom?.map((item, index) => (
                    <>


                      <div
                        key={index}
                        className="col-md-6 col-sm-12 col-lg-3"
                        // onClick={() => handleNavigateToWisdomSubpage(item?.slug)}
                        style={{ borderRadius: "22px" }}
                      ><Link to={`/wisdom/${item?.slug}`}>
                          <div
                            className="card item-card text-dark card_height1 overflow-hidden"
                            style={{
                              borderRadius: "10px",
                            }}
                          >
                            <img
                              src={item?.thumbnail_image}
                              alt=""
                              style={{ aspectRatio: "3/2" }}
                            />

                            <div
                              className="d-flex justify-content-end"
                              style={{ position: "relative", right: "26px" }}
                            >
                              <h6 className="pt-3">
                                {`${item.contentType === "video"
                                  ? `Video`
                                  : item.contentType === "audio"
                                    ? `Audio`
                                    : `Article`
                                  }`}
                              </h6>
                              <i
                                className={`fa-solid ${item.contentType === "video"
                                  ? `fa-circle-play`
                                  : item.contentType === "audio"
                                    ? `fa-headphones`
                                    : `fa-book-open`
                                  }`}
                                style={{
                                  position: "relative",
                                  top: "18px",
                                  left: "9px",
                                }}
                              ></i>
                            </div>
                            <div className="container">
                              <h5
                                className="mt-1"
                                style={{
                                  fontFamily: "myFont",
                                  color: "#000000",
                                  fontWeight: 'bold',
                                  height: "45px",
                                  overflow: "hidden"
                                }}
                              >
                                {item.title.length > maxLength
                                  ? `${item.title.substring(0, maxLength)}...`
                                  : item.title}

                              </h5>
                              <p
                                className="card-text lato"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.description.length > maxLength1
                                      ? `${item.description.substring(
                                        0,
                                        maxLength1
                                      )}...`
                                      : item.description,
                                }}
                                style={{
                                  marginLeft: "0.0rem",
                                  fontFamily: "Lato",
                                  color: "#5B5B5B",

                                }}
                              ></p>


                            </div>
                          </div>
                        </Link>
                      </div>

                    </>
                  ))}

                <div className="col-12">
                  <div className="pt-3 d-flex justify-content-center py-5">
                    {wisdom &&
                      wisdom?.length == totalwisdom ? (
                      ""
                    ) : (
                      <a>
                        <button
                          className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                          onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                        >
                          {isLoading ? (
                            <div class="spinner-border text-dark" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            <span>LOAD MORE</span>
                          )}
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </div> :
                <section>
                  <div className="text-center d-flex justify-content-center">
                    <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
                  </div>
                </section>}
            </div>
          </section>

          <section className="downloadapp" style={{ overflow: "hidden" }}>
            <div
              className="bg-imgd mt-3"

            >
              <div className="container mainappcon">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 paddingtt ">
                    <h1 className="btnphone haedingfont">
                      <span>{home?.image_6?.title}!</span>
                    </h1>
                    <hr style={{ color: "#FD6E12" }} />
                    <p className="lato btnphone">
                      {home?.image_6?.description}
                    </p>
                    <div className="pt-4 btnphone">
                      <a
                        href={home?.googleplay}
                        target="_blank"
                        className="market-btn google-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          Google Play
                        </span>
                      </a>
                      <a
                        href={home?.appplay}
                        target="_blank"
                        className="market-btn apple-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          App Store
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mycontainerdapp ">
                    <div className="downimg" style={{
                      backgroundImage: `url(${home?.image_6?.thumbnail_image})`

                    }}>
                      {/* <img src={home?.image_6?.thumbnail_image} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <SmallLoader />
      )}
    </>
  );
};

export default Wisdom;
