import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    occupation: null,
}



export const getAllOccupationList = createAsyncThunk(
    'occupation/getalloccupation',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(getAllOccupationStart());
            const response = await axios.get(`occupation/getalloccupation`)
            dispatch(getAllOccupationSuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(getAllOccupationFail(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)


const occupationSlice = createSlice({
    name: 'occupation',
    initialState,
    reducers: {
        getAllOccupationStart(state, action) {
            state.isLoading = true;
        },
        getAllOccupationSuccess(state, action) {
            state.occupation = action.payload?.occupationList;
            state.isLoading = false
        },
        getAllOccupationFail(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }

})

export const { getAllOccupationStart, getAllOccupationSuccess, getAllOccupationFail } = occupationSlice.actions;

export default occupationSlice.reducer;
