import React, { useEffect, useState } from "react";
import {
  getGalleryBanner,
  getsinglegallerycategory,
} from "../Allslices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import { useParams } from "react-router-dom";
import GalleryModal from "./GalleryModal";
export default function GallerySubpage() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { singlecategory, isLoading, gallerybanner } = useSelector(
    (state) => state.gallery
  );
 

  let splitUrl;

  if (singlecategory?.page_banner_image) {
    splitUrl = singlecategory?.page_banner_image.split(
      "http://api.drarchikadidi.com/"
    );
    // splitUrl = singlecategory?.page_banner_image.split('http://localhost:8080/');
  }
  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? gallerybanner?.default_image
        : singlecategory?.page_banner_image;
  }

  useEffect(() => {
    dispatch(getsinglegallerycategory(slug));
    dispatch(getGalleryBanner());
  }, []);

  let arrayData = singlecategory?.imageslist?.map((element, scheduleIndex) => ({
    height: 100,
    src: element?.thumbnail_image,
    width: 100,
  }));

  return (
    <>
      <MetaData
        title={singlecategory?.meta_title}
        description={singlecategory?.meta_description}
      />
      {singlecategory ? (
        <>
          <div
            className="wisdomimg desktop-only"
            style={{
              backgroundImage: `url(${bannerImage})`,
              backgroundSize: "cover",
            }}
          >
            <div className="container padding_gall">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {singlecategory?.alignItem === "left" && (
                    <>
                      <div>
                        <div>
                          <h1 className="fw-bold">
                            <span
                              style={{
                                fontSize: "larger",
                                color: "white!important",
                              }}
                            >
                              {singlecategory?.title}
                            </span>
                          </h1>
                          <p className="pt-2 text-dark ">
                            {singlecategory?.banner_description}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {singlecategory?.alignItem === "right" && (
                    <>
                      <div>
                        <div>
                          <h1 className="fw-bold">
                            <span
                              style={{
                                fontSize: "larger",
                                color: "white!important",
                              }}
                            >
                              {singlecategory?.title}
                            </span>
                          </h1>
                          <p className="pt-2 text-dark ">
                            {singlecategory?.banner_description}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid mobile-only">
            <div
              className="container mycontainer2"
              style={{
                backgroundSize: "contain",
                backgroundImage: `url(${bannerImage})`,
                backgroundRepeat: "no-repeat",
                height: "63vh",
                
              }}
            >
              <div className="row h-50">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {singlecategory?.alignItem === "left" && (
                    <>
                      <div>
                        <div>
                          <h1 className="fw-bold">
                            <span
                              style={{
                                fontSize: "larger",
                                color: "white!important",
                              }}
                            >
                              {singlecategory?.title}
                            </span>
                          </h1>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {singlecategory?.alignItem === "right" && (
                    <>
                      <div>
                        <div>
                          <h1 className="fw-bold">
                            <span
                              style={{
                                fontSize: "larger",
                                color: "white!important",
                              }}
                            >
                              {singlecategory?.title}
                            </span>
                          </h1>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <p
                className="m-0 py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify",
                }}
              >
                {singlecategory?.banner_description}
              </p>
            </div>
          </div> */}

<div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex",}}> 
            <img src={ `${bannerImage}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {singlecategory?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: singlecategory?.title,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {singlecategory?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html:singlecategory?.title,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: singlecategory?.banner_description,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>

          
        </>
      ) : (
        <SmallLoader />
      )}

      <section>
        {singlecategory ? (
          <div className="container" style={{ margin: "8% auto" }}>
            {Array.isArray(singlecategory?.imageslist) &&
            singlecategory?.imageslist.length > 0 &&
            arrayData.length > 0 ? (
              <GalleryModal photos={arrayData}></GalleryModal>
            ) : (
              <h1 className="fwsemi-bold text-center">
                No Images Available To Show
              </h1>
            )}
          </div>
        ) : (
          <SmallLoader />
        )}
      </section>
    </>
  );
}
