import React, { useState, useEffect } from "react";

import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";

import { convertToMins } from "../appUtils";
import { BsSoundwave } from "react-icons/bs";

const PlayingBar = ({ onSeek, time, track, trackRef }) => {
  const [totalDuration, setTotalDuration] = useState(null);

  useEffect(() => {
    const audio = trackRef;

    const handleLoadedMetadata = () => {
      const formattedDuration = formatTime(audio.duration);
      setTotalDuration(formattedDuration);
    };

    audio?.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio?.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [track?._id]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {/* <span>{trackRef ? convertToMins(trackRef.currentTime) : "0:00"}</span>/
      <span>{trackRef?.duration ? totalDuration : "0:00"}</span> */}

      <Flex justifyContent="space-evenly" gap={1}>
        <Text
          fontSize="xs"
          color="zinc.500"
          sx={{ mb: "0rem" }}
          className="fs-6"
          style={{ marginBottom: "0px!important" }}
        >
          {trackRef ? convertToMins(trackRef.currentTime) : "0:00"}
        </Text>
        <Slider
        style={{maxWidth: "100%!important"}}
          outline={0}
          _focus={{ outline: 0 }}
          aria-label="seek-slider"
          defaultValue={0}
          width="10rem"
          onChange={onSeek}
          value={!isNaN(time) ? time : 0}
          className=""
        >
          <SliderTrack bg="gray.400">
            <SliderFilledTrack bg="#b767a2" className="barresponsive"/>
          </SliderTrack>
          <SliderThumb boxSize={3} outline={0} style={{height: "21px", width: "21px"}}>
            <Box color="#b767a2" as={BsSoundwave} />
          </SliderThumb>
        </Slider>
        {/* <Text fontSize="xs" color="zinc.500">
          {track?.duration.split(".").join(":")}
        </Text> */}
      </Flex>
    </>
  );
};

export default PlayingBar;
