import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ".././component/layout/style.css";
import "../component/auth/auth.css";
import Thoughts1 from "../../src/images/Thoughts 1.png";
// import Communityimage from "../../src/images/Community.png";
import Communityimage from "../../src/images/comm.png";
import menicon from "../../src/images/menicon.jpeg";
import Rectangle116 from "../../src/images/Side-Banner.jpg";
import Rectangle117 from "../../src/images/Side-Banner-1.jpg";
import exploreBg from "../../src/images/explorebg.png";
import Audio from "../../src/images/ModalBg1.png";
import Vedio from "../../src/images/modalBg2.png";
import AddPostModal from "./post/AddPostModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SHOW_ERROR_NOTIFICATION } from "../appUtils/appToast";
import {
  fetchPostResetPage,
  fetchPosts,
  getCommunityAds,
  getCommunityConfig,
  isAnonymusPost,
} from "../Allslices/communitySlice";
import PostItem from "./post/PostItem";
import MetaData from "../component/layout/MetaData";
import { getHome } from "../Allslices/homeSlice";
import ExploreBg from "../../src/images/explorebg.png";
import { getmyOrder } from "../Allslices/orderSlice";
import { IoLockClosedSharp } from "react-icons/io5";

const Community = () => {
  const [showAddPost, setShowAddPost] = useState(false);
  const [showCommunity, setShowCommunity] = useState(false);
  const [feedType, setFeedType] = useState("all");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { orderAll } = useSelector((state) => state?.order);

  useEffect(()=>{

    if (user?.communityUnlocked) {
      setShowCommunity(true)
    }
    
  },[dispatch])

  const userImage = localStorage.getItem("userImage");

  const {
    fetchPostState: {
      loading: fetchPostloading,
      data: fetchPostData,
      totalCount,
      currentPage,
      limit,
      hideLoadMore,
    },
    communityconfig,
    adsState: { data: adsData }
  } = useSelector((state) => state.community);
  const { home } = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(getCommunityConfig());
    dispatch(getCommunityAds());
    dispatch(getHome());
    dispatch(getmyOrder());
  }, []);

  const callFetchPost = useCallback((type) => {
    dispatch(fetchPosts({ type })).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, []);

  const handleFiltersSubmit = (e, action) => {
    if (action == "loadmore") {
      dispatch(
        fetchPosts({
          page: currentPage,
          limit: limit * 2,
          type: feedType,
        })
      );
    }
  };

  const changeFeedType = useCallback(
    (type) => {
      setFeedType(type);
      dispatch(fetchPostResetPage());
      callFetchPost(type);
    },
    [feedType, callFetchPost]
  );

  useEffect(() => {
    dispatch(fetchPostResetPage());
    changeFeedType("all");
  }, []);

  if (!user?.communityUnlocked) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>

          <h1 className="text-center fs-4 d-flex"><IoLockClosedSharp /> &nbsp;&nbsp; Sorry Community is Locked Until you Purchase Something.</h1>
        </div>
      </>
    )
  }   
  return (
    <>
      <MetaData title={communityconfig?.meta_title} description={communityconfig?.meta_description} tags={communityconfig?.meta_tag} />
      <div>
        <div
          className="vidomediaquery desktop-only"
          style={{
            backgroundSize: "cover",
            overflow: "hidden",
            backgroundImage: `url(${communityconfig?.background_image})`,
          }}
        >
          <div className="container wisdompad desktop-only">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                {communityconfig?.alignItem === "left" && (
                  <>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {communityconfig?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes mt-4"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify",
                        }}
                      >
                        {communityconfig?.subheading}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                {communityconfig?.alignItem === "right" && (
                  <>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {communityconfig?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes mt-4"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify",
                        }}
                      >
                        {communityconfig?.subheading}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* banner starts here */}


        <div className="container-fluid mobile-only">
          <div className="container mycontainer2" style={{
            backgroundSize: "contain",
            backgroundImage: `url(${communityconfig?.res_background_image})`,
            backgroundRepeat: 'no-repeat',
            height: '53vh'
          }}>
            <div className="row ">
              <div className="col-6 d-flex justify-content-start align-items-center">

                {communityconfig?.alignItem === "left" && (<>
                  <h1 className="fw-bold  mx-2  " style={{ marginTop: "75px" }}>
                    <span className="wisdomhead haedingfont ">
                      {communityconfig?.res_heading}
                    </span>
                  </h1>
                </>)}

              </div>
              <div className="col-6 d-flex justify-content-start align-items-center">
                {communityconfig?.alignItem === "right" && (<>
                  <h1 className="fw-bold mx-2  " style={{ marginTop: "75px" }}>
                    <span className="wisdomhead haedingfont">
                      {communityconfig?.res_heading}
                    </span>
                  </h1>
                </>)}
              </div>
            </div>

            <p
              className="m-0  "
              style={{
                fontFamily: "Lato",
                textAlign: "justify",
                paddingTop: "8.5rem"
              }}
            >
              {communityconfig?.res_subheading}
            </p>

          </div>
        </div>
        {/* banner ends here */}

        {/* button group */}
        <section className="pt-5 communitybuttons">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-sm-12 col-md-10">
                <div className="row p-2 border con_box">
                  <div className="col-lg-1 col-md-2 col-2 p-0 ">
                    <img
                      src={user?.image ?? ""}
                      alt="user_image"
                      style={{ height: '60px', position: 'absolute', width: "60px", borderRadius: "50%" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "default_usericon.jpg";
                      }}
                    />
                  </div>
                  <div className="col-lg-11 col-md-10 col-10">
                    <p
                      className=" rounded-pill mt-2"
                      onClick={() => setShowAddPost(true)}
                      style={{ cursor: "pointer", backgroundColor: '#dadada6b', padding: '10px 10px', width: '100%' }}
                    >
                     <span className="colorcommunity"> What's Happening!!!</span>
                    </p>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-center">
                  <div className="d-flex p-2 textabc border con_box" style={{width:'100%', gap:'14px'}}>
                    <div className="commeimg ">
                     
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                      
                    </div>
                  </div>
                  
                </div> */}



                {/* modal */}

                <div
                  className="modal fade"
                  id="exampleModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{
                            border: "none",
                            background: "white",
                            fontSize: "30px",
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <button className="btn shadowbuttons">
                          Upload File
                        </button>
                      </div>
                      <div className="modal-body">...</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-transparent"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="button" className="btn shadowbuttons">
                          Add A Comment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- multi button --> */}
                <div className="multi-button pt-5">
                  <button
                    onClick={() => changeFeedType("all")}
                    className={`${feedType == "all" ? "multi-button-selected" : ""
                      }`}
                    style={{
                      borderColor: "#B767A2",
                      //backgroundColor: feedType == 'all' ? '#FF9933' : null,
                    }}
                  >
                    All
                  </button>
                  <button
                    onClick={() => changeFeedType("question")}
                    className={`${feedType == "question" ? "multi-button-selected" : ""
                      }`}
                    style={{
                      borderColor: "#D6D2D2",
                      //backgroundColor: feedType == 'questions' ? '#FF9933' : null,
                    }}
                  >
                    Questions
                  </button>
                  <button
                    onClick={() => changeFeedType("thought")}
                    className={`${feedType == "thought" ? "multi-button-selected" : ""
                      }`}
                    style={{
                      borderColor: "#D6D2D2",
                      //backgroundColor: feedType == 'thought' ? '#FF9933' : null,
                    }}
                  >
                    Thought
                  </button>
                </div>
                {/* <!-- multi button end --> */}
                {fetchPostData?.map((item, index) => (
                  <PostItem key={item._id} listItem={item} />
                ))}
              </div>

              <div className="col-lg-3 col-md-2">
                {Array.isArray(adsData) &&
                  adsData.map((item, index) =>

                    <div className="d-none d-lg-block d-md-block py-3" key={index}>
                      <a href={item?.link} target="_blank" rel="noopener noreferrer">
                        <img src={item?.thumbnail_image} className="img-fluid" alt="" />
                      </a>
                    </div>

                  )
                }
              </div>
            </div>
          </div>
        </section>
        {hideLoadMore === false && (
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                {fetchPostData && fetchPostData?.length == totalCount ? (
                  ""
                ) : (
                  <a className="pt-5 d-flex justify-content-center">
                    <button
                      className="btn text-light shadowbuttons btn-lg cmn-t-shake rounded-pill"
                      onClick={(e) => handleFiltersSubmit(e, "loadmore")}

                    >
                      {fetchPostloading ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>LOAD MORE</span>
                      )}
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <!-- explore start=========== --> */}
        <section className="explore">
          <div
            className="exploimg "
            style={{
              backgroundImage: `url(${ExploreBg})`,
              backgroundSize: "cover",
            }}
          >
            <div className="container ">
              <div className="row">
                <div className="col-12 exploretext ">
                  <div className="aboutusexplore haedingfont">
                    <p
                      className="text-center haedingfont"
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.title_e,
                      }}
                    ></p>
                  </div>

                  <p className="text-center lato">
                    {home?.bannercontent?.description_e}
                  </p>
                  <div className=" pt-3 d-flex justify-content-center">
                    <a href="/courses">
                      <button className="btn-read cmn-t-shake">
                        EXPLORE NOW
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Modal --> */}
        {showAddPost && (
          <AddPostModal
            open={showAddPost}
            onHide={() => dispatch(isAnonymusPost(false)) && setShowAddPost(false)}
          />
        )}

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal4"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content CommunityModalContent d-flex justify-content-center ">
              <div className=" modalHeader">
                <div className="topButttons pt-2 m-0 px-2">
                  <h5>Add Audio</h5>
                  <button
                    type="button"
                    className="btn-close modalCloseButton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div
                className="modal-body AddAudioModalBody d-flex justify-content-center"
                style={{
                  textAlign: "center",
                  height: "18rem",
                }}
              >
                <div
                  className="ImgAudioModal"
                  style={{
                    width: "17rem",
                    height: "15rem",
                  }}
                >
                  <img
                    src={Audio}
                    alt=""
                    style={{
                      width: "100%",
                      backgroundSize: "cover",
                    }}
                  />
                  <h5>Manage your Audio</h5>
                  <h6 style={{ fontSize: "small" }}>
                    Lorem ipsum sit dolor amet is a dummy text used by the
                    typography industry and the web industry.
                  </h6>
                </div>
              </div>
              <div className="d-flex justify-content-center ">
                <button
                  type="button"
                  className="btn modalButtons mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal5"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content CommunityModalContent d-flex justify-content-center ">
              <div className=" modalHeader">
                <div className="topButttons m-0 pt-2 px-2">
                  <h5>Add Video</h5>
                  <button
                    type="button"
                    className="btn-close modalCloseButton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div
                className="modal-body AddVedioModalBody d-flex justify-content-center"
                style={{
                  textAlign: "center",
                  height: "18rem",
                }}
              >
                <div
                  className="ImgAudioModal"
                  style={{
                    width: "14rem",
                    height: "10rem",
                  }}
                >
                  <img
                    src={Vedio}
                    alt=""
                    style={{
                      width: "80%",
                      backgroundSize: "cover",
                    }}
                  />
                  <h5>Share Live Videos</h5>
                  <h6 style={{ fontSize: "small" }}>
                    Lorem ipsum sit dolor amet is a dummy text used by the
                    typography industry and the web industry.
                  </h6>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn modalButtons mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Community;
