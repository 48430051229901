import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { filtersUpdate, getAllBlogs, getBlogs, getLanguages } from "../Allslices/blogSlice";
import searchicon from "../images/searchicons.png";
import filtericon from "../images/filtericons.png";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import nodata from "../images/No-Data.png";
import usePreserveScroll from "./histrorysave";

export default function Blog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();
  const handleNavigatetoSubpage = (slug) => {
    navigate(`/blog/${slug}`, { state: { scrollY: window.scrollY } });
  };

  const location = useLocation();

  const query = location.search.split("?")[1];


  let initialfilters = {
   
    text: query ?? "",
  };


  const {
    blog,
    currentPage,
    limit,
    isLoading,
    totalblogs,
    blogbanner,
    Languages,
    Filters: { categoryId, language, sort, contentType, text }

  } = useSelector((state) => state.blog);


  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value
    };

    dispatch(filtersUpdate(payload));
  };


  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

 
  const handleFiltersSubmit = (e, action) => {

    if (action == "loadmore") {
      dispatch(
        getAllBlogs({
          page: currentPage,
          limit: limit + 8,
        })
      )
    } else {
      dispatch(
        getAllBlogs({
          page: currentPage,
          limit: limit,
        })
      )
      window.scrollTo(
        {
          top: 650,
          behavior: 'smooth'
        })
    }

    

  };


  useEffect(() => {
    dispatch(getBlogs());
    // dispatch(getAllBlogs());
    dispatch(getLanguages());


    if(query){
      dispatch(filtersUpdate(query ? { text: query } : { text: text }));
      handleFiltersSubmit();
    }else{
      dispatch(
        getAllBlogs({
          page: currentPage,
          limit: limit,
        })
      );
    }
    

    return ()=>dispatch(filtersUpdate({text:''}))
  }, [query]);

  useEffect(() => {
    dispatch(getBlogs());
    
  }, []);

  // useEffect(() => {
    
    
  //   return ()=>dispatch(filtersUpdate({text:''}))
  // }, [])
  


  const maxLength = 40;
  const maxLength1 = 115;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <MetaData title={blogbanner?.meta_title} description={blogbanner?.meta_description} tags={blogbanner?.meta_tag} />
      {
        <>
          <div className="mb-3">
            {/* benner section */}
            <>
              {blogbanner ? (
                <>
                  <div
                    className="vidomediaquery desktop-only "
                    style={{
                      backgroundSize: "cover",
                      backgroundImage: `url(${blogbanner?.background_image})`,
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="container wisdompad ">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                          {blogbanner?.alignItem === "left" && (
                            <>
                              <div>
                                <h1 className="fw-bold ">
                                  <span className="wisdomhead haedingfont">
                                    {blogbanner?.heading}
                                  </span>
                                </h1>
                                <p
                                  className=" w-75 wisdomPara homebdes mt-4"
                                  style={{
                                    fontFamily: "Lato",
                                    textAlign: "justify",
                                  }}
                                >
                                  {blogbanner?.subheading}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                          {blogbanner?.alignItem === "right" && (
                            <>
                              <div>
                                <h1 className="fw-bold ">
                                  <span className="wisdomhead haedingfont">
                                    {blogbanner?.heading}
                                  </span>
                                </h1>
                                <p
                                  className=" w-75 wisdomPara homebdes mt-4"
                                  style={{
                                    fontFamily: "Lato",
                                    textAlign: "justify",
                                  }}
                                >
                                  {blogbanner?.subheading}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${blogbanner?.res_background_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {blogbanner?.alignItem  === "left" && (<>
                    <p
                      className="homebannertitle "
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: blogbanner?.res_heading,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {blogbanner?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle blogheading"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: blogbanner?.res_heading,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: blogbanner?.res_subheading,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>


                 
               
              {/* banner section end */}
              <div className="container filterpad pt-3 shadow mt-3">
                <div className="row pb-4 p-3">
                  <div
                    className="col-lg-4 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                    id="mobileScreen"
                  >
                    <label
                      for="filter"
                      className="fw-bold myfiltericon"
                      style={{ color: "#A0A0A0" }}
                    >
                      <img style={{ width: "25px" }} src={filtericon} alt="" />
                    </label>

                    <select
                      value={language}
                      name="language"
                      className="select searchborder rounded-pill p-2 w-100 customselect"
                      id="selectFilter"
                      onChange={(e) => handleFilterChange(e)}
                      onKeyDown={handleKeyPress}
                    >
                      <option value="">Language</option>
                      {Array.isArray(Languages) &&
                        Languages?.map((item, index) => {
                          return (
                            <option value={item?.language}>
                              {item?.language}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div
                    className="col-lg-4 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                    id="mobileScreen"
                  >
                    <select
                      value={sort}
                      name="sort"
                      className="select searchborder rounded-pill p-2 w-100 px-2 my-2 bg-white"
                      onChange={(e) => handleFilterChange(e)}
                      onKeyDown={handleKeyPress}
                    >
                      <option value="English">Sort by</option>
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                    </select>
                  </div>

                  <div
                    className="col-lg-4 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                    id="mobileScreen"
                  >
                    <label
                      for="filter"
                      className="fw-bold mysearchicon"
                      style={{ color: "#A0A0A0" }}
                    >
                      <img
                        src={searchicon}
                        alt=""
                        style={{ width: "25px" }}
                        onClick={handleFiltersSubmit}
                      />
                    </label>
                    <form class=" w-100 pt-4" role="search">
                      <input
                        className="form-control  searchborder rounded-pill p-2 w-100 searchborder"
                        type="search"
                        name="text"
                        placeholder="Search by keyword..."
                        aria-label="Search"
                        onChange={(e) => handleFilterChange(e)}
                        value={text}
                        onKeyDown={handleKeyPress}
                        style={{ borderRadius: "19px", color: "#A0A0A0" }}
                      />
                      <i class="fa-solid fa-magnifying-glass mysearchiconforphone"></i>
                    </form>
                  </div>

                  <div className=" d-flex justify-content-center pt-3">
                    <button
                      className="btn  btn-lg text-light cmn-t-shake shadowbuttons mb-3"
                      style={{
                        padding: "0.6rem 2.5rem",
                      }}
                      onClick={handleFiltersSubmit}
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
              <div

              >
                <section ref={listStartRef} id="listStart">
                  <div className=" container beloved pt-5 ">
                    {blog?.length > 0 ?<div className="row g-4">
                      {blog?.map((item, index) => {
                        // if (item.status === true) {
                        return (
                          <div
                            key={index}
                            className="col-md-6 col-sm-12 col-lg-3"
                            style={{ cursor: "pointer" }}
                            // onClick={() => handleNavigatetoSubpage(item.slug)}
                          ><Link to={`/blog/${item.slug}`}>
                            <div
                              className="card item-card card_height1 text-dark "
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={item?.thumbnail_image}
                                alt=""
                                style={{
                                  borderRadius: "10px 10px 0px 0px",
                                  aspectRatio: "3/2",
                                }}
                              />
                              <div className="container card_height1_11">
                              <div
                              className="d-flex justify-content-end"
                              style={{ position: "relative", right: "26px" }}
                            >
                              <h6 className="pt-3">
                              
                                    Blog
                  
                              </h6>
                              <i
                                className="fa-solid fa-book-open"
                                style={{
                                  position: "relative",
                                  top: "18px",
                                  left: "9px",
                                }}
                              ></i>
                            </div>
                                <h5
                                  className="mt-1"
                                  style={{ fontFamily: "playfair" ,fontWeight:'bold',height: "45px",
                                    overflow: "hidden"}}
                                >
                                  {item?.title?.length > maxLength
                                    ? `${item?.title.substring(
                                      0,
                                      maxLength
                                    )}...`
                                    : item.title}
                                </h5>

                                <p
                                  className="card-text lato"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.description?.length > maxLength1
                                        ? `${item?.description.substring(
                                          0,
                                          maxLength1
                                        )}...`
                                        : item.description,
                                  }}
                                  style={{
                                    marginLeft: "0.0rem",
                                    fontFamily: "myFont",
                                    color: "#5B5B5B",
                                  }}
                                ></p>
                                <h5
                                  className="lato d-flex pt-2 position-absolute bottom-0"
                                  style={{ fontSize: "16px" }}
                                >
                                  {/* <Moment
                                        className="fw-semibold"
                                        format="D MMM YYYY"
                                        withTitle
                                      >
                                        {item?.createdAt}
                                      </Moment> */}
                                </h5>
                              </div>
                            </div>
                            </Link>
                          </div>
                        );
                        // } else {
                        //   return null;
                        // }
                      })}
                    </div>:
                    <section>
                <div className="text-center d-flex justify-content-center">
                <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
                </div>
              </section>}
                  </div>
                </section>
                <section className="py-3">
                  <div className="pt-3 d-flex justify-content-center py-5">
                    {blog &&
                      blog?.length == totalblogs ? (
                      ""
                    ) : (
                      <a>
                        <button
                          className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                          onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                        >
                          {isLoading ? (
                            <div class="spinner-border text-dark" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            <span>LOAD MORE</span>
                          )}
                        </button>
                      </a>
                    )}
                  </div>
                </section>
              </div>

              {/* <!-- getabook solution end --> */}
            </>
            ) : (
              <SmallLoader />
            )}
            </>
          </div>
    
        
        </>
      }
    </>
  );
}

