import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";
const filters = {
  categoryId: '',
  language: '',
  sort: '',
  contentType: '',
  text: ''
}
const initialState = {
  isLoading: false,
  error: null,
  blog: [],
  blogtag: [],
  currentPage: 0,
  limit: 8,
  totalblogs: 0,
  singleblog: {},
  blogbanner: null,
  Filters: filters

};

export const getAllBlogs = createAsyncThunk(
  "blog/all",
  async (
    { page, limit, forEvent },
    { dispatch, rejectWithValue, getState }
  ) => {
    const { blog: { Filters: { sort, language, contentType, text } } } = getState();

    try {
      dispatch(getallblogStart());
      let response;
      if (forEvent == true) {
        response = await axios.get(
          `blog/all?forEvent=${forEvent}&limit=100000000000000`
        );
      } else {
        let query = "";

        if (contentType) {
          query += `&contentType=${contentType}`;
        }
        if (sort != "") {
          query += `&sort=${sort}`;
        }
        if (language) {
          query += `&language=${language}`;
        }
        if (text != "") {
          query += `&text=${text}`;
        }
        response = await axios.get(`blog/all?page=${page ?? + 1}&limit=${limit ?? ""}${query}`);
      }

      dispatch(getallblogSuccess(response?.data));
      return response.data;
    } catch (error) {
      dispatch(getallblogfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getsingleBlog = createAsyncThunk(
  "blog/singleblog",
  async (slug, { dispatch, rejectWithValue }) => {
    try {
      dispatch(singleblogstart());
      const response = await axios.get(`blog/${slug}`);
      dispatch(singleblogsuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(singleblogfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "config/getaboutUs",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(blogsstart());
      const response = await axios.get(`blog/getbanner`);
      dispatch(blogsSuccess(response?.data?.requiredcontent));
    } catch (error) {
      dispatch(blogsFailure(error.response.data.message));
    }
  }
);
export const getLanguages = createAsyncThunk(
  "languages/get_languages",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(languagesStart());
      const response = await axios.get("languages/get_languages");
      dispatch(languagesSuccess(response?.data?.settingItems));
    } catch (error) {
      dispatch(languagesFailure(error.response.data.message));
    }
  }
);



export const putinterstBlog = createAsyncThunk(
  'blog/recommendedBlog',
  async ({ tags }, { dispatch, rejectWithValue }) => {

    try {
      dispatch(starttagblog());
      const response = await axios.post(`blog/recommendedBlog`, {tags:tags});
      dispatch(successtagblog(response.data));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(failuretagblog(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    getallblogStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getallblogSuccess(state, action) {
      state.isLoading = false;
      state.blog = action.payload.allblog;
      state.totalblogs = action.payload.totalblogs;
      state.currentPage = action.payload.currentPage;
      state.limit = action.payload.limit;
    },
    getallblogfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.singleblog = null;
    },
    singleblogstart(state, action) {
      state.isLoading = true;
    },
    singleblogsuccess(state, action) {
      state.isLoading = false;
      state.singleblog = action.payload.blog;
    },
    singleblogfailure(state, action) {
      state.isLoading = false;
      state.singleblog = null;
    },
    blogsstart(state) {
      state.isLoading = true;
      state.error = null;
    },
    blogsSuccess(state, action) {
      state.blogbanner = action?.payload;
      state.isLoading = false;
    },
    blogsFailure(state, action) {
      state.blogbanner = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    languagesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    languagesSuccess(state, action) {
      state.Languages = action.payload;
      state.isLoading = false;
    },
    languagesFailure(state, action) {
      state.Languages = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    filtersUpdate(state, action) {
      const { categoryId, language, sort, contentType, text } = action.payload;
      if (categoryId !== undefined) {
        state.Filters.categoryId = categoryId;
      }
      if (language !== undefined) {
        state.Filters.language = language;
      }
      if (sort !== undefined) {
        state.Filters.sort = sort;
      }
      if (contentType !== undefined) {
        state.Filters.contentType = contentType;
      }
      if (text !== undefined) {
        state.Filters.text = text;
      }
    },
    starttagblog(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    successtagblog(state, action) {
      state.isLoading = false;
      state.blogtag = action?.payload?.recommendedBlogList;
    },
    failuretagblog(state, action) {
      state.isLoading = false;
      state.error = null;
    }
  },
});

export const {
  filtersUpdate,
  getallblogStart,
  getallblogSuccess,
  getallblogfailure,
  singleblogstart,
  singleblogsuccess,
  singleblogfailure,
  blogsstart,
  blogsSuccess,
  blogsFailure,
  languagesStart,
  languagesSuccess,
  languagesFailure,
  starttagblog,
  successtagblog,
  failuretagblog
} = blogSlice.actions;

export default blogSlice.reducer;
