import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";
import { SHOW_SUCCESS_NOTIFICATION } from "../appUtils/appToast";

const filters = {
  categoryId: '',
  language: '',
  sort: '',
  contentType: '',
  text: ''
}

const initialState = {
  isLoading: false,
  error: null,
  courselist: [],
  course: null,
  courseCategories: [],
  courseLessonList: [],
  activityAnswer: {},
  Filters: filters,
  courseDetails:null,
  isPostActivityAnswerLoading: false,
};

export const getAllCourses = createAsyncThunk(
  "course/allcourses",
  async (
    { page },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { course: { Filters: { categoryId, sort, language, contentType, text } } } = getState();
    try {
      dispatch(getallcoursesStart());
      const response = await axios.get(
        `course/allcourses?&categoryId=${categoryId ?? ""
        }&contentType=${contentType ?? ""}&sort=${sort ?? ""}&language=${language ?? ""
        }&text=${text ?? ""}`
      );
      dispatch(getallcoursesSuccess(response?.data?.returnndata));
      return response?.data?.returnndata;
    } catch (error) {
      dispatch(getallcoursesfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getcoursebanner = createAsyncThunk(
  "config/get_coursebanner",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(coursestart());
      const response = await axios.get(`config/get_coursebanner`);
      dispatch(courseSuccess(response?.data));
    } catch (error) {
      dispatch(courseFailure(error.response.data.message));
    }
  }
);
export const getcoursedetails = createAsyncThunk(
  "courses/courseDetails",
  async (slug, { dispatch, rejectWithValue }) => {
    try {
      dispatch(singlecoursestart());
      const response = await axios.get(`course/${slug}`);
      dispatch(singlecourseSuccess(response?.data));
      return response?.data
    } catch (error) {
      dispatch(singlecourseFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

// For Getting the Course Categories
export const getCourseCategories = createAsyncThunk(
  "category/all_coursecategory",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(getCourseCategoriesStart());
      const response = await axios.get(`course/category/all_coursecategory`);
      dispatch(getCourseCategoriesSuccess(response.data?.categories));
    } catch (error) {
      dispatch(getCourseCategoriesFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

// For Getting the all Language Lists from the Langauge
export const getLanguages = createAsyncThunk(
  "languages/get_languages",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(languagesStart());
      const response = await axios.get("languages/get_languages");
      dispatch(languagesSuccess(response?.data?.settingItems));
    } catch (error) {
      dispatch(languagesFailure(error.response.data.message));
    }
  }
);

// For getting the specific course lesson
export const getCourseLesson = createAsyncThunk(
  "course/getcourselesson",
  async ({ courseid, orderid }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(courseLessonStart());
      const response = await axios.get(`course/getlessons/${courseid}?orderid=${orderid}`);
      dispatch(courseLessonSuccess(response?.data?.lessonslist));
    } catch (error) {
      dispatch(courseLessonFail(error.response.data.message));
    }
  }
);

// post the activity answers in the course lesson by the user
export const postActivityAnswer = createAsyncThunk(
  "course/activityanswers",
  async ({ answerActivityId, formData }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(postActivityAnswerStart());
      const response = await axios.post(
        `course/answer/${answerActivityId}`,
        formData
      );
      dispatch(postActivityAnswerSuccess(response?.data?.activityanswer));
      if (response.data.success === true) {
        SHOW_SUCCESS_NOTIFICATION("Activity Answer Posted Successfully.")
      }
    } catch (error) {
      dispatch(postActivityAnswerFail(error.response.data.message));
    }
  }
);

// post the activity answers in the course lesson by the user
export const promoVideo = createAsyncThunk(
  'course/promoVideo',
  async (slug, { dispatch, rejectWithValue }) => {
      try {
          dispatch(promostart());
          const response = await axios.get(`course/promoVideo/${slug}`)
          dispatch(promoSuccess(response?.data))

      } catch (error) {
          dispatch(promoFailure(error.response.data.message))
      }
  }
)
const coursesSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    getallcoursesStart(state) {
      state.isLoading = true;
      state.error = null;
      state.courselist = [];
    },
    getallcoursesSuccess(state, action) {
      state.isLoading = false;
      state.courselist =action.payload
      // state.courselist = [...state.courselist, ...action.payload?.courses];
    },
    getallcoursesfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    coursestart(state) {
      state.isLoading = true;
      state.error = null;
    },
    courseSuccess(state, action) {
      state.course = action.payload;
      state.isLoading = false;
    },
    courseFailure(state, action) {
      state.course = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    singlecoursestart(state, action) {
      state.isLoading = true;
    },
    singlecourseSuccess(state, action) {
      state.isLoading = false;
      state.courseDetails = action.payload;
    },
    singlecourseFailure(state, action) {
      state.isLoading = false;
      state.courseDetails = null;
    },
    getCourseCategoriesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getCourseCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.courseCategories = action.payload;
    },
    getCourseCategoriesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    languagesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    languagesSuccess(state, action) {
      state.Languages = action.payload;
      state.isLoading = false;
    },
    languagesFailure(state, action) {
      state.Languages = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    courseLessonStart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    courseLessonSuccess(state, action) {
      state.isLoading = false;
      state.courseLessonList = action.payload;
    },
    courseLessonFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.courseLessonList = null;
    },
    postActivityAnswerStart(state, action) {
      state.isPostActivityAnswerLoading = true;
      state.error = null;
    },
    postActivityAnswerSuccess(state, action) {
      state.isPostActivityAnswerLoading = false;
      state.error = null;
      state.activityAnswer = action.payload.activityanswer;
    },
    postActivityAnswerFail(state, action) {
      state.isPostActivityAnswerLoading = false;
      state.error = action.payload;
    },
    filtersUpdate(state, action) {
      const { categoryId, language, sort, contentType, text } = action.payload;
      if (categoryId !== undefined) {
        state.Filters.categoryId = categoryId;
      }
      if (language !== undefined) {
        state.Filters.language = language;
      }
      if (sort !== undefined) {
        state.Filters.sort = sort;
      }
      if (contentType !== undefined) {
        state.Filters.contentType = contentType;
      }
      if (text !== undefined) {
        state.Filters.text = text;
      }
    },
    promostart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    promoSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.coursePromo = action.payload?.foundVideo;
    },
    promoFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  filtersUpdate,
  getallcoursesStart,
  getallcoursesSuccess,
  getallcoursesfailure,
  coursestart,
  courseSuccess,
  courseFailure,
  singlecoursestart,
  singlecourseSuccess,
  singlecourseFailure,
  getCourseCategoriesStart,
  getCourseCategoriesSuccess,
  getCourseCategoriesFailure,
  languagesStart,
  languagesSuccess,
  languagesFailure,
  courseLessonStart,
  courseLessonSuccess,
  courseLessonFail,
  postActivityAnswerStart,
  postActivityAnswerSuccess,
  postActivityAnswerFail,
  promostart,
  promoSuccess,
  promoFailure
} = coursesSlice.actions;

export default coursesSlice.reducer;
