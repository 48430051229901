import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import SmallLoader from "./SmallLoader";
import searchicon from "../images/searchicons.png";
import filtericon from "../images/filtericons.png";
import nodata from "../images/No-Data.png";
import useGeolocation from "../component/shared/UserGeolocation";

import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import {
  filtersUpdate,
  getAllCourses,
  getcoursebanner,
  getCourseCategories,
  getLanguages,
} from "../Allslices/courseSlice";
// import StarRating from './StarRating'
import MetaData from "../component/layout/MetaData";
import usePreserveScroll from "./histrorysave";

export default function OnlineClasses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();

  const {
    courselist,
    course,
    courseCategories,
    Languages,
    Filters: { categoryId, language, sort, contentType, text },
  } = useSelector((state) => state.course);
  const categorywisecourse = courselist?.categorywise;
  const upcomingCourses = courselist?.course_upcom;
  const newCourses = courselist?.course_new;
  const bestsellingCourses = courselist?.course_bestsell;

  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [message, setMessage] = useState("");
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);
  const [totalcoursesCount, settotalCoursesCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);

  useEffect(() => {
    dispatch(getcoursebanner());
    dispatch(getCourseCategories());
    dispatch(getLanguages());
  }, []);

  const isComponentMounted = useRef(true);
  const [hoveredCourseIndex, setHoveredCourseIndex] = useState(null);
  const sliderRefs = useRef([]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleMouseEnter = (index) => {
    setHoveredCourseIndex(index);
    sliderRefs?.current[index]?.slickPlay(); // Start autoplay for the hovered slider
  };
  const query = location.search.split("?")[1];

  const handleMouseLeave = () => {
    setHoveredCourseIndex(null);
    sliderRefs?.current?.forEach((slider) => slider?.slickPause()); // Pause autoplay for all sliders
  };
  // let initialfilters = {
  //   categoryId: null,
  //   language: null,
  //   contenttype: null,
  //   newest: "",
  //   text: "",
  // };
  // const [filters, setfilters] = useState(initialfilters);
  const [page, setPage] = useState(1);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    const payload = {
      [name]: value,
    };
    dispatch(filtersUpdate(payload));
  };

  const maxLength = 80;
  const maxLength1 = 40;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!hasMore) {
          setLoader(false);
          return;
        }
        setLoader(true);

        setWaitResponse(true);
        dispatch(filtersUpdate(query ? { text: query } : { text: text }));

        const action = await dispatch(
          getAllCourses({
            page: page,
          })
        );

        if (getAllCourses.fulfilled.match(action)) {
          if (action.payload.courses?.length === 0) {
            setHasMore(false);
            setLoader(false);
            return;
          }
          setFetchedCourses([...fetchedCourses, ...action.payload.courses]);
          settotalCoursesCount(action.payload?.totalCourses);
          setLoader(false);
          setWaitResponse(false);
        } else if (getAllCourses.rejected.match(action)) {
        }
      } catch (error) {
        setLoader(false);
        setWaitResponse(false);
      }
    };

    if (isComponentMounted.current) {
      fetchData();
    }
  }, []);

  const handlenavigateToCourseSinglePage = (item) => {
    if (item.coming_soon) {
      setMessage(item.message ? item.message : "This Course is coming soon. Please check back later!")

      handleShow();
    } else {
      navigate(`/courses/${item?.slug}`);
    }
  };

  const handleFiltersSubmit = (e, action) => {
    if (action == "loadmore") {
      setLoader(true);
    }
    isComponentMounted.current = false;

    if (action == "loadmore") {
      setPage((prevpage) => prevpage + 1);
    }

    dispatch(
      getAllCourses({
        page: action == "loadmore" ? page + 1 : page,
      })
    ).then((response) => {
      // setFetchedwellness(response?.payload?.wellnessLists)
      if (action == "loadmore") {
        setFetchedCourses([...fetchedCourses, ...response?.payload?.courses]);
        setLoader(false);
      } else {
        setFetchedCourses(response?.payload?.courses);
        window.scrollTo({
          top: 650,
          behavior: "smooth",
        });
      }
    });
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          right: "-15px!important",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowRight color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          left: "-35px",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowLeft color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  let sliderRef1 = useRef();
  const numCards = newCourses?.length>=3;
  const numCardss = newCourses?.length>=2;
  
  const minSlidesToShow = Math.min(3, numCards);
  const settings1 = {
    infinite: numCards,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: numCardss,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  // settings1.slidesToShow = minSlidesToShow;
  // let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  // if (minSlidesToShow === 3) {
  //   gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  // } else if (minSlidesToShow === 2) {
  //   gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  // } else if (minSlidesToShow === 1) {
  //   gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  // }

  let sliderRef2 = useRef();
  const numCards1 = upcomingCourses?.length >= 3;

  const minSlidesToShow1 = Math.min(3, numCards1);
  const settings2 = {
    infinite: numCards1,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const numCards3 = bestsellingCourses?.length >= 3;

  const settings3 = {
    infinite: numCards3,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  // settings1.slidesToShow = minSlidesToShow1;
  // let gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";
  // if (minSlidesToShow1 === 3) {
  //   gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";
  // } else if (minSlidesToShow1 === 2) {
  //   gridColumnClass1 = "col-md-3 col-sm-12 col-lg-3";
  // } else if (minSlidesToShow1 === 1) {
  //   gridColumnClass1 = "col-md-3 col-sm-12 col-lg-3";
  // }


  const userGeolocationCode = useGeolocation();

  const renderPrice = (
    selling_price_rupee,
    selling_price_usd,
    mrp_price_rupee,
    mrp_price_usd
  ) => {
    const isIndianUser = userGeolocationCode === "IN";
    const sellingPrice =
      isIndianUser || !userGeolocationCode
        ? selling_price_rupee
        : selling_price_usd;
    const mrpPrice =
      isIndianUser || !userGeolocationCode ? mrp_price_rupee : mrp_price_usd;
    const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

    if (sellingPrice === mrpPrice) {
      return ` ${currencySymbol}${sellingPrice}`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          {" "}
          <span
            style={{ textDecoration: "line-through", color: "rgb(91, 91, 91)" ,fontSize:'14px'}}
          >
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          {currencySymbol}
          {sellingPrice}
        </>
      );
    } else {
      return ` ${currencySymbol}${sellingPrice}`;
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div>
      <MetaData
        title={course?.coursebanner?.meta_title}
        description={course?.coursebanner?.meta_description}
        tags={course?.coursebanner?.meta_tag}
      />
      {course ? (
        <>
          <div
            className="wisdomimg bg-cover photo_p desktop-only"
            style={{
              backgroundImage: `url(${course?.coursebanner?.background_image})`,
            }}
          >
            <div className="container wisdompad ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {course?.coursebanner?.alignItem === "left" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {course?.coursebanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {course?.coursebanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {course?.coursebanner?.alignItem === "right" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {course?.coursebanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {course?.coursebanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* banner starts here */}

          <div className="mobile-only">
            <div
              className="container-fluid mobile-only"
              style={{ position: "relative", display: "flex" }}
            >
              <img src={`${course?.coursebanner?.res_background_image}`} />
              <div
                className="container"
                style={{ position: "absolute", height: "100%" }}
              >
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    {course?.coursebanner?.alignItem === "left" && (
                      <>
                        <p
                          className="homebannertitle"
                          style={{ fontFamily: "myFont", fontSize: "24px" }}
                          dangerouslySetInnerHTML={{
                            __html: course?.coursebanner?.res_heading,
                          }}
                        ></p>
                      </>
                    )}
                  </div>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    {course?.coursebanner?.alignItem === "right" && (
                      <>
                        <p
                          className="homebannertitle"
                          style={{ fontFamily: "myFont", fontSize: "24px" }}
                          dangerouslySetInnerHTML={{
                            __html: course?.coursebanner?.res_heading,
                          }}
                        ></p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify",
                }}
                dangerouslySetInnerHTML={{
                  __html: course?.coursebanner?.res_subheading,
                }}
              ></p>
            </div>

            {/* banner ends here */}
          </div>

          {/* <div className="container-fluid mobile-only">
            <div
              className="container mycontainer3"
              style={{
                backgroundSize: "contain",
                backgroundImage: `url(${course?.coursebanner?.res_background_image})`,
                backgroundRepeat: "no-repeat",
                height: "63vh",
              }}
            >
              <div className="row h-50">
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {course?.coursebanner?.alignItem === "left" && (
                    <>
                      <h4 className="haedingfont">
                        <span className="wisdomhead haedingfont">
                          {course?.coursebanner?.res_heading}
                        </span>
                      </h4>
                    </>
                  )}
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {course?.coursebanner?.alignItem === "right" && (
                    <>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {course?.coursebanner?.res_heading}
                        </span>
                      </h1>
                    </>
                  )}
                </div>
              </div>

              <p
                className="m-0  "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify",
                }}
              >
                {course?.coursebanner?.res_subheading}
              </p>
            </div>
          </div> */}
          {/* banner ends here */}
          <div className="container filterpad  shadow sidemedia py-2">
            <div className="row pb-4 p-3 justify-content-center">
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                id="mobileScreen"
              >
                <label
                  htmlFor="filter"
                  className="fw-bold myfiltericon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img style={{ width: "25px" }} src={filtericon} alt="" />
                </label>
                <select
                  className="select searchborder rounded-pill p-2 w-100 my-2"
                  value={language}
                  name="language"
                  onChange={(e) => handleFilterChange(e)}
                  onKeyDown={handleKeyPress}
                >
                  <option value="">All Languages</option>
                  {Array.isArray(Languages) &&
                    Languages?.map((item, index) => {
                      return (
                        <option value={item?.language} className="text-black">
                          {item?.language}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  className="select searchborder rounded-pill p-2 w-100 px-2 mx-1 my-2"
                  value={categoryId}
                  name="categoryId"
                  onKeyDown={handleKeyPress}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Category</option>
                  {Array.isArray(courseCategories) &&
                    courseCategories?.map((item, index) => {
                      return <option value={item?._id}>{item?.name}</option>;
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  value={sort}
                  name="sort"
                  className="select searchborder rounded-pill p-2 w-100 px-2 my-2"
                  onChange={(e) => handleFilterChange(e)}
                  onKeyDown={handleKeyPress}
                >
                  <option value="English">Sort by</option>
                  <option value="newest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>

              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <label
                  for="filter"
                  className="fw-bold mysearchicon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img
                    src={searchicon}
                    alt=""
                    style={{ width: "25px" }}
                    onClick={handleFiltersSubmit}
                  />
                </label>
                <form className=" w-100 pt-4" role="search">
                  <input
                    className="form-control me-2 w-100 searchborder paddingdesign"
                    // type="search"
                    name="text"
                    placeholder="Search by keyword..."
                    aria-label="Search"
                    onChange={(e) => handleFilterChange(e)}
                    value={text}
                    style={{ borderRadius: "19px" }}
                    onKeyDown={handleKeyPress}
                  />
                  <i class="fa-solid fa-magnifying-glass mysearchiconforphone "></i>
                </form>
              </div>
              <div className="pt-3 d-flex justify-content-center ">
                <button
                  className="btn  shadowbuttons btn-lg text-light cmn-t-shake"
                  style={{
                    padding: "0.6rem 2.5rem",
                  }}
                  onClick={handleFiltersSubmit}
                >
                  SEARCH
                </button>
              </div>
            </div>
          </div>
          {bestsellingCourses?.length > 0 || (Array.isArray(categorywisecourse) && categorywisecourse.find((e) => e?.courselist?.length > 0)) || newCourses?.length > 0 || upcomingCourses?.length > 0 ? (
            <div
              className="container artvid  "
              style={{ overflow: "hidden" }}
            >
              {bestsellingCourses?.length > 0 && (
                <h2
                  className="text-start   ps-3 bestsellinghead"
                  style={{
                    fontFamily: "myFont",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Best Selling Programmes
                </h2>
              )}
              <div
                className={`row g-3 py-2 artvid33 slidercourse ${bestsellingCourses?.length <= 2 ? "sliderscontainercourse" : ""
                  }`}
              >
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settings3}
                >
                  {Array.isArray(bestsellingCourses) &&
                    bestsellingCourses.map((item, index) => (
                      <div
                        className="col-md-6 col-sm-12 col-lg-3 mb-3"
                        onClick={() => handlenavigateToCourseSinglePage(item)}
                        style={{ borderRadius: "22px" }}

                        key={index}
                      >
                        <div
                          className="card item-card card-block mx-4 my-3 overflow-hidden"
                          style={
                            {
                              // borderRadius: "10px",
                              // height: "450px",
                            }
                          }
                        >
                          {item?.sliderImages?.map((slider_image, idx) => (
                            <div className="" key={idx}>
                              <img
                                src={slider_image?.course_image}
                                alt="Photo of course"
                                style={{
                                  aspectRatio: "3/2",
                                }}
                              />
                            </div>
                          ))}

                          <div className="container courseapixoss">
                            <h4
                              className="mt-3"
                              style={{
                                fontFamily: "myFont",
                                color: "#000000",
                                height: "45px",
                              }}
                            >
                              {item.title.length > maxLength1
                                ? `${item.title.substring(0, maxLength1)}...`
                                : item.title}
                            </h4>
                            <p
                              className="card-text lato my-2"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.shortDescription.length > maxLength
                                    ? `${item.shortDescription
                                      ?.replace(/<\/?p>/gi, "")
                                      .substring(0, maxLength)}...`
                                    : item.shortDescription?.replace(
                                      /<\/?p>/gi,
                                      ""
                                    ),
                              }}
                              style={{
                                marginLeft: "0.0rem",
                                fontFamily: "Lato",
                                color: "#5B5B5B",
                                minHeight: "55px",
                              }}
                            ></p>
                            <div className="coursecolor">
                              <i className="fa-solid fa-video  fs-6 pt-1 text-info " />{" "}
                              Online<i className="fa-solid fa-language  fs-6 pt-1 text-info ms-2" />{" "}
                              {item.courseLanguage}
                            </div>
                           
                            <div
                              className="my-3 d-flex "
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                type="button"
                                className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                              >
                                Register
                              </button>
                              <spna className="d-flex flex-column coursecolor">
                                {" "}
                                {item.selling_price_rupee > 0
                                  ? renderPrice(
                                    item.selling_price_rupee,
                                    item.selling_price_usd,
                                    item?.mrp_price_rupee,
                                    item.mrp_price_usd
                                  )
                                  : "Free"}
                              </spna>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          ) : (
            <section>
              <div className="text-center d-flex justify-content-center">
                <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
              </div>
            </section>
          )
          }

          <div className="container " style={{ overflow: "hidden" }}>
            <div>
              {Array.isArray(categorywisecourse) &&
                categorywisecourse.map((ele, index) => {
                  const infinitestatus = ele?.courselist?.length >= 3;
                  const infinitestatus2 = ele?.courselist?.length >= 2;
                  const settings3 = {
                    infinite: infinitestatus,
                    autoscroll: true,
                    autoplay: true,
                    autoplayspeed: 6000,
                    speed: 700,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                    responsive: [
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                    infinite: infinitestatus2,

                        },
                      },
                      {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1200,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                    ],
                  };
                  return (
                    <div className="" key={index}>
                      {ele?.courselist?.length > 0 ? (
                        <>
                          <h2
                            className="text-start pt-3  ps-3"
                            style={{
                              fontFamily: "myFont",
                              color: "rgba(0, 0, 0, 1)",
                            }}
                          >
                            {ele.name}
                          </h2>

                          <div
                            className={`row g-3 py-2  slidercourse ${ele?.courselist?.length <= 2
                              ? "sliderscontainercourse"
                              : ""
                              } `}
                          >
                            {/* {Array.isArray(ele?.courselist) && ele.courselist.length > 1 ? ( */}

                            <Slider
                              ref={(slider) => {
                                sliderRef1 = slider;
                              }}
                              {...settings3}
                            >
                              {ele?.courselist.map((item, index) => (
                                <div
                                  className="col-md-6 col-sm-12 col-lg-3 mb-3"
                                  onClick={() =>
                                    handlenavigateToCourseSinglePage(item)
                                  }
                                  style={{ borderRadius: "22px" }}
                                  key={index}
                                >
                                  <div className="card item-card card-block mx-4 my-3 overflow-hidden">
                                    {item?.sliderImages?.map(
                                      (slider_image, idx) => (
                                        <div className="" key={idx}>
                                          <img
                                            src={slider_image?.course_image}
                                            alt="Photo of course"
                                            style={{
                                              aspectRatio: "3/2",
                                            }}
                                          />
                                        </div>
                                      )
                                    )}

                                    <div className="container courseapixoss">
                                      <h4
                                        className="mt-3"
                                        style={{
                                          fontFamily: "myFont",
                                          color: "#000000",
                                          height: "45px",
                                        }}
                                      >
                                        {item.title.length > maxLength1
                                          ? `${item.title.substring(
                                            0,
                                            maxLength1
                                          )}...`
                                          : item.title}
                                      </h4>
                                      <p
                                        className="card-text lato my-2"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.shortDescription.length > maxLength
                                              ? `${item.shortDescription
                                                ?.replace(/<\/?p>/gi, "")
                                                .substring(0, maxLength)}...`
                                              : item.shortDescription?.replace(
                                                /<\/?p>/gi,
                                                ""
                                              ),
                                        }}
                                        style={{
                                          marginLeft: "0.0rem",
                                          fontFamily: "Lato",
                                          color: "#5B5B5B",
                                          minHeight: "55px",
                                        }}
                                      ></p>
                                      <div className="coursecolor">
                                        <i className="fa-solid fa-video fs-6 pt-1 text-info" />{" "}
                                        Online <i className="fa-solid fa-language fs-6 pt-1 text-info ms-2" />{" "}
                                        {item.courseLanguage}
                                      </div>
                                     
                                      <div
                                        className="my-3 d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-lg d-sm-5 fs-6 courseregisterbtn "
                                        >
                                          Register
                                        </button>
                                        <spna className="d-flex flex-column coursecolor">
                                          {" "}
                                          {item.selling_price_rupee > 0
                                            ? renderPrice(
                                              item.selling_price_rupee,
                                              item.selling_price_usd,
                                              item?.mrp_price_rupee,
                                              item.mrp_price_usd
                                            )
                                            : "Free"}
                                        </spna>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </>
                      ) : ("")}
                    </div>
                  );

                }
                )}
            </div>
          </div>

          {newCourses?.length > 0 && (
            <div
              className="container artvid    "
              style={{ overflow: "hidden" }}
            >
              <h2
                className="text-start pt-3  ps-3"
                style={{
                  fontFamily: "myFont",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                New Launches
              </h2>

              <div
                className={`row g-3 py-2 artvid33 slidercourse ${newCourses?.length <= 2 ? "sliderscontainercourse" : ""
                  }`}
              >
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settings1}
                >
                  {Array.isArray(newCourses) &&
                    newCourses.map((item, index) => (
                      <div
                        className="col-md-6 col-sm-12 col-lg-3 mb-3"
                        onClick={() => handlenavigateToCourseSinglePage(item)}
                        style={{ borderRadius: "22px" }}
                        key={index}
                      >
                        <div
                          className="card item-card card-block mx-4 my-3 overflow-hidden "
                          style={
                            {
                              // borderRadius: "10px ",
                              // height: "450px",
                            }
                          }
                        >
                          {item?.sliderImages?.map((slider_image, idx) => (
                            <div className="" key={idx}>
                              <img
                                src={slider_image?.course_image}
                                alt="Photo of course"
                                style={{
                                  aspectRatio: "3/2",
                                }}
                              />
                            </div>
                          ))}

                          <div className="container courseapixoss">
                            <h4
                              className="mt-3"
                              style={{
                                fontFamily: "Playfair Display SC",
                                color: "#000000",
                                height: "45px",
                              }}
                            >
                              {item.title.length > maxLength1
                                ? `${item.title.substring(0, maxLength1)}...`
                                : item.title}
                            </h4>
                            <p
                              className="card-text lato my-2"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.shortDescription.length > maxLength
                                    ? `${item.shortDescription
                                      ?.replace(/<\/?p>/gi, "")
                                      .substring(0, maxLength)}...`
                                    : item.shortDescription?.replace(
                                      /<\/?p>/gi,
                                      ""
                                    ),
                              }}
                              style={{
                                marginLeft: "0.0rem",
                                fontFamily: "Lato",
                                color: "#5B5B5B",
                                minHeight: "55px",
                              }}
                            ></p>
                            <div className="coursecolor">
                              <i className="fa-solid fa-video  fs-6 pt-1 text-info" />{" "}
                              Online <i className="fa-solid fa-language  fs-6 pt-1 text-info ms-2" />{" "}
                              {item.courseLanguage}
                            </div>
                           
                            <div
                              className="my-3 d-flex "
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                type="button "
                                className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                              >
                                Register
                              </button>
                              <spna className="d-flex flex-column coursecolor">
                                {" "}
                                {item.selling_price_rupee > 0
                                  ? renderPrice(
                                    item.selling_price_rupee,
                                    item.selling_price_usd,
                                    item?.mrp_price_rupee,
                                    item.mrp_price_usd
                                  )
                                  : "Free"}
                              </spna>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          )}
          {upcomingCourses?.length > 0 && (
            <div
              className="container artvid  pb-4"
              style={{ overflow: "hidden" }}
            >
              <h3
                className="text-start pt-3  ps-3"
                style={{
                  fontFamily: "myFont",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Coming Soon
              </h3>

              <div
                className={`row g-3 py-2 artvid33 slidercourse ${upcomingCourses?.length <= 2 ? "sliderscontainercourse" : ""
                  }`}
              >
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(upcomingCourses) &&
                    upcomingCourses.map((item, index) => (
                      <div
                        className="col-md-6 col-sm-12 col-lg-3 mb-3"
                        onClick={() => handlenavigateToCourseSinglePage(item)}
                        style={{ borderRadius: "22px" }}
                        key={index}
                      >
                        <div
                          className="card item-card card-block mx-4 my-3 overflow-hidden"
                          style={
                            {
                              // borderRadius: "10px",
                              // height: "450px",
                            }
                          }
                        >
                          {item?.sliderImages?.map((slider_image, idx) => (
                            <div className="" key={idx}>
                              <img
                                src={slider_image?.course_image}
                                alt="Photo of course"
                                style={{
                                  aspectRatio: "3/2",
                                }}
                              />
                            </div>
                          ))}

                          <div className="container courseapixoss">
                            <h4
                              className="mt-3"
                              style={{
                                fontFamily: "myFont",
                                color: "#000000",
                                height: "45px",
                              }}
                            >
                              {item.title.length > maxLength1
                                ? `${item.title.substring(0, maxLength1)}...`
                                : item.title}
                            </h4>
                            <p
                              className="card-text lato my-2"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.shortDescription.length > maxLength
                                    ? `${item.shortDescription
                                      ?.replace(/<\/?p>/gi, "")
                                      .substring(0, maxLength)}...`
                                    : item.shortDescription?.replace(
                                      /<\/?p>/gi,
                                      ""
                                    ),
                              }}
                              style={{
                                marginLeft: "0.0rem",
                                fontFamily: "Lato",
                                color: "#5B5B5B",
                                minHeight: "55px",
                              }}
                            ></p>
                            <div className="coursecolor">
                              <i className="fa-solid fa-video  fs-6 pt-1 text-info " />{" "}
                              Online <i className="fa-solid fa-language  fs-6 pt-1 ms-2 text-info" />{" "}
                              {item.courseLanguage}
                            </div>
                           
                            <div
                              className="my-3 d-flex "
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                type="button"
                                className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                              >
                                Register
                              </button>
                              <spna className="d-flex flex-column coursecolor">
                                {" "}
                                {item.selling_price_rupee > 0
                                  ? renderPrice(
                                    item.selling_price_rupee,
                                    item.selling_price_usd,
                                    item?.mrp_price_rupee,
                                    item.mrp_price_usd
                                  )
                                  : "Free"}
                              </spna>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          )}

        </>
      ) : (
        <SmallLoader />
      )}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Coming Soon
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato  ">
              {/* <img src={coming_soon_image} alt="Coming Soon Image" height={10} width={100}/> */}
              {message}
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <section className="mb-5">
<div className="container artvid  pe-2">
  {fetchedCourses?.length > 0 ? (
    <div className="row" ref={listStartRef} id="listStart">
      {Array.isArray(fetchedCourses) &&
        fetchedCourses.map((item, index) => (
          <div
            className="col-lg-4 col-sm-12 col-12 my-2"
            onClick={() => handlenavigateToCourseSinglePage(item)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            key={index}
          >
            <div
              className="card item-card card-block mx-1"
              style={{
                borderRadius: "20px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <Slider
                ref={(slider) =>
                  (sliderRefs.current[index] = slider)
                }
                {...(hoveredCourseIndex === index
                  ? sliderSettings
                  : {})}
              >
                {item?.sliderImages?.map((slider_image, idx) => (
                  <div className="carousel-item" key={idx}>
                    <img
                      src={slider_image?.course_image}
                      className="d-block w-100"
                      alt="..."
                      style={{
                        // aspectRatio: "3/2",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                    />
                  </div>
                ))}
              </Slider>
              {/* <div className="cardBottom d-flex flex-column">
              <div className="container px-4 pb-3">
                <h6
                  className="pt-3 text-dark text-end"
                  style={{
                    marginLeft: "0.0rem",
                    fontFamily: "Lato",
                    color: "#5B5B5B",
                  }}
                >
                  {item?.category?.name}
                </h6>
                <h4
                  className="mt-2 fs-4 text-capitalize"
                  style={{ fontFamily: "playfair", color: "black" }}
                >
                  {item?.title?.length > maxLength
                    ? `${item.title.substring(0, maxLength)}...`
                    : item?.title}
                </h4>
              </div>
            </div> */
}
//             </div>
//           </div>
//         ))}
//     </div>
//   ) : (
//     <section>
//       <div className="text-center d-flex justify-content-center">
//         <img
//           style={{ width: "400px" }}
//           src={nodata}
//           alt="No Data Image"
//         />
//       </div>
//     </section>
//   )}
// </div>
// </section> */}
