import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ".././component/layout/style.css";
import MainMusicPagePlayer from "./MainMusicPagePlayer";
import { useNavigate, useParams } from "react-router-dom";
import {
  getsinglealbum,
  getAlbumBanner,
  getMusicBanner,
} from "../Allslices/albumSlice";
import { setTrackList, playTrack, pauseTrack, setCurrentAlbum, setAlbumPurchased } from "../Allslices/playerSlice";
import ArtisteSong from "./ArtisteSong";
import OrderModal from "../Payments/OrderModal";
import MetaData from "../component/layout/MetaData";
import nodata from "../images/No-Data.png";
import { getmyOrder } from "../Allslices/orderSlice";
import useGeolocation from "../component/shared/UserGeolocation";



const Albums = ({ audioRef, songDetails, setSongDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const url = window.location.href;
  const { slug } = params;
  const id = slug;
  const { artiste, albumbanner, musicbanner } = useSelector((state) => state.album);
  const { isPlaying, currentAlbum, isAlbumPurchased } = useSelector((state) => state.player);
  const { orderAll } = useSelector((state) => state?.order);
  const sellingPriceINR = artiste?.selling_price_rupee;
  const mrpPriceINR = artiste?.mrp_price_rupee;
  const sellingPriceUSD = artiste?.selling_price_usd;
  const mrpPriceUSD = artiste?.mrp_price_usd;

  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";
  let mrpPrice = isIndianUser || !userGeolocationCode ? mrpPriceINR : mrpPriceUSD;

  const renderPrice = useMemo(() => {
    const sellingPrice = isIndianUser || !userGeolocationCode ? sellingPriceINR : sellingPriceUSD;
    const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";
    if (sellingPrice === mrpPrice) {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          BUY NOW{" "}
          <span style={{ textDecoration: "line-through", color: "white" }}>
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          ({currencySymbol}{sellingPrice})
        </>
      );
    } else {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    }
  }, [artiste, orderAll, isAlbumPurchased]);

  const [limit, setlimit] = useState(4);
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);




  useEffect(() => {
    dispatch(getsinglealbum(slug)).then((res) => {
      dispatch(setCurrentAlbum(res.payload))
    });
    dispatch(getAlbumBanner());
    dispatch(getMusicBanner());
  }, []);

  const onSongPlay = (song) => {
    const index = artiste?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: artiste?.songList, index }));

    dispatch(playTrack(song));
  };

  const onSongPause = (song) => {
    const index = artiste?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: artiste?.songList, index }));
    dispatch(pauseTrack(song));
  };

  const handleLoadmore = () => {
    // setlimit(limit + 4);
    setLoader(true);
    setTimeout(() => {
      setlimit((prevLimit) => prevLimit + 4);
      setLoader(false);
    }, 2000);
  };

  const [buynow, setbuynow] = useState(false);

  const orderType = "music";
  let albumPurchased = null;

  const handlebuynow = () => {
    if (!user) {
      navigate(`/register`);
    } else {
      setbuynow(true);
    }
  };

  const myorderlist = orderAll?.filter((item) => item?.orderType === "music");

  const [checkBuyStatus, setCheckBuyStatus] = useState()

  useEffect(() => {

    const purchaseStatus = orderAll.filter((item) => item?.orderItems?.product == currentAlbum?.album?._id)
    if (purchaseStatus[0]?.paymentInfo?.status == "true" && currentAlbum?.album?._id) {
      dispatch(setAlbumPurchased(true))
    } else {
      dispatch(setAlbumPurchased(false))
    }
  }, [currentAlbum, orderAll, artiste])



  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  for (const order of orderAll) {
    if (order?.orderItems?.product === artiste?._id) {
      albumPurchased = order;
      break;
    }
  }

  let isPurchased = false;
  let isSongPurchased = false
  orderAll.map((item, index) => {
    if (item?.orderItems?.product === artiste?._id) {
      isPurchased = true;
    }

    if (item?.orderItems?.product && artiste?.songList.some((song) => song?._id === item.orderItems.product)) {
      isSongPurchased = true;
    }
  })

  let bannerImage;
  const defaultImage = musicbanner?.default_image;
  const artisteBannerImage = artiste?.banner_image;

  if (!artisteBannerImage || artisteBannerImage.includes('publicundefined')) {
    bannerImage = defaultImage;
  } else {
    bannerImage = artisteBannerImage;
  }

  // useEffect(()=>{
  //   if(((checkBuyStatus === true) || (checkBuyStatus === undefined)) && (artiste?.isPaid === true)){
  //     dispatch(setAlbumPurchased(true))
  //   }
  // },[artiste])

  return (
    <>
      <MetaData title={artiste?.meta_title} description={artiste?.meta_description} tags={artiste?.meta_tag} />
      {/* <!-- banner section --> */}
      <div
        className="musicxr musicMainPage"
        style={{
          backgroundImage: `url(${bannerImage})`,
          height: "550px",
        }}
      >
        <div className="container muxrty">
          <div className="row">
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-10">
              <h1 className="fw-bold mb-3">
                <span
                  style={{
                    // fontSize: "4rem",
                    fontWeight: "900",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {artiste?.title}
                </span>
              </h1>

              <div className="">
                {/* {((checkBuyStatus ==true) || (artiste?.isPaid === true)) ? ( */}
                {artiste?.isPaid && !isAlbumPurchased ? (

                  <button
                    className="btn shadowbuttons btn-lg text-light rounded-pill cmn-t-shake "
                    data-bs-toggle={`${user === null ? '' : 'modal'}`}
                    data-bs-target="#staticBackdrop"
                    style={{ fontSize: "font-size: 17px" }}
                    // style={{ backgroundColor: "rgba(255, 153, 51, 1)" }}
                    onClick={handlebuynow}
                  >
                    {mrpPrice > 0 ? renderPrice : "GET NOW (FREE)"}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12`"></div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end --> */}

      {/* <!-- section mp3-music --> */}

      <MainMusicPagePlayer
        audioRef={audioRef}
        songDetails={songDetails}
        setSongDetails={setSongDetails}
        id={id}
        isPurchased={isPurchased}
      />


      {/* <!-- section mp3-music end --> */}

      {/* <!-- about album --> */}
      <section>
        <div className="container muxrtzz">
          <div className="aboutr text-center p-1">
            <h1 className="haedingfont">
              <span >{artiste?.title}</span>
            </h1>
            <p
              className=" text-dark mt-4"
              style={{ marginTop: "10px", marginBottom: "0", fontSize: "20px !important" }}
            >
              {artiste?.description}
            </p>
          </div>
        </div>
      </section>

      {/* <!-- about album end --> */}

      {/* <!-- tacky section --> */}

      <div className="tacky">
        <div className="container">
          <div className="p-2">
            {artiste?.songList?.length > 0 ? (
              <div className="text-center mb-5 muxrtzz">
                <h1 className="text-center haedingfont mt-3">

                  <span >Tracks</span>
                </h1>
                {/* {artiste?.isPaid===true?
            <button
              className="btn btn-warning btn-lg text-light rounded cmn-t-shake " data-bs-toggle="modal" data-bs-target="#staticBackdrop"
              style={{ backgroundColor: "#FF9933!important" }}
              onClick={handlebuynow}
            >
             BUY NOW
            </button>
            : ""
            } */}
                {artiste?.songList?.slice(0, limit).map((song, index) => {
                  return (
                    <ArtisteSong
                      key={song?._id}
                      song={song}
                      handlePlay={onSongPlay}
                      handlePause={onSongPause}
                      isPurchased={isPurchased}
                      isSongPurchased={isSongPurchased}
                      albumid={id}
                    />
                  );
                })}

                <div className="p-4 text-center">
                  {limit >= artiste?.songList.length ? (
                    ""
                  ) : (
                    <button
                      className="btn shadowbuttons btn-lg text-light rounded-pill py-2p cmn-t-shake "
                      style={{ backgroundColor: "rgba(255, 153, 51, 1)" }}
                      onClick={handleLoadmore}
                    >
                      {loader ? (
                        <div class="spinner-border text-dark" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        " LOAD MORE"
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-center">
                <img style={{ width: "350px" }} src={nodata} alt="No Data Image" />
              </div>
              // <h1 className="text-center">
              // <span style={{ fontSize: "95px" }}>No Tracks Added Yet!!</span>
              // </h1>
            )}
          </div>
        </div>
      </div>
      {/* <!-- tacky section end --> */}

      {/* <!-- connect music --> */}
      {/* <div className="connect text-light d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5">
              <div className="adio">
                <h1>
                  Connect to Your
                  <br /> Audience Everywhere
                </h1>
                <p className="text-light">
                  Going Premium is easy and it also allows you to use more than
                  11.9M icons and stickers without attribution.
                </p>
              </div>
              <div className="pt-3">
                <a href="">
                  <button
                    className="btn btn-warning btn-lg text-light rounded-5 cmn-t-shake "
                    style={{ backgroundColor: "#FF9933!important" }}
                  >
                    SPIRITUAL
                  </button>
                </a>
              </div>
              <h1 className="py-2">
             
                <span>Fling Mentns Of Joy in A Chanlange Your</span>
              </h1>
              <div className="d-flex gap-2">
                <a>
                  <button
                    className="btn btn- btn-lg text-light  cmn-t-shake border d-flex gap-1"
                    style={{
                     
                      color: "#1f374e!important",
                    }}
                  >
                    <img src={zzz} style={{ width: "27px" }} alt="" />
                    LISTEN NOW
                  </button>
                </a>
                <a>
                  <button
                    className="btn btn- btn-lg text-light  cmn-t-shake border "
                  >
                    BROWSE ALL
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div> */}

      <OrderModal orderType={orderType} product={buynow ? artiste : ""} navigate={true} isExpired={false} />

      {/* <!-- connect music end--> */}
    </>
  );
};

export default Albums;
