import React from 'react';
import { Flex, Button, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';
import '.././component/layout/style.css';

const VolumeControl = ({ onChange, volume, onToggle }) => {
    return (
        <Flex align="center" className="PB-range-slider-div" sx={{gap: "0rem!important"}}>
            <Button
                variant="unstyled"
                p={0}
                m={0}
                display="inline-flex"
                boxSize={6}
                onClick={onToggle}
            >
                {volume === 0 ? <BsFillVolumeMuteFill /> : <BsFillVolumeUpFill />}
            </Button>

            <Slider
                aria-label="volume-slider"
                width="6rem"
                onChange={onChange}
                value={volume ? volume * 100 : 0}
                min={0}
                max={100}
                step={1}
                className="mx-2 progressBarvolume bar volume"
            >
                <SliderTrack bg="gray.400">
                    <SliderFilledTrack bg="#b767a2" />
                </SliderTrack>
                <SliderThumb boxSize={3} outline={0} />
            </Slider>
        </Flex>
    );
}

export default VolumeControl;
