import React, { useEffect } from "react";

import ".././component/layout/style.css";
import { nextTrack, prevTrack, setPlaying } from "../Allslices/playerSlice";
import { useDispatch, useSelector } from "react-redux";
import { convertToMins } from "../appUtils";
import {
  Flex,
  Slider,
  SliderFilledTrack,
  Box,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { BsSoundwave } from "react-icons/bs";

const WellnessSongMusicPlayer = ({
  songDetails,
  onSeek,
  time,
  audioRef,
  setSongDetails,
}) => {
  const dispatch = useDispatch();
  const { currentTrack, isPlaying, trackList } = useSelector(
    (state) => state.player
  );

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef?.current.pause();
      dispatch(setPlaying(false));
    } else {
      audioRef?.current.play();
      dispatch(setPlaying(true));
    }
  };

  const handleNextSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(nextTrack());
    }
  };

  const handlePreviousSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(prevTrack());
    }
  };

  const restartSong = () => {
    setSongDetails((prev) => {
      return { ...prev, time: 0 };
    });
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  return (
    <>
      <div
        className="d-flex justify-content-between rounded-3 align-items-center"
        style={{ maxWidth: "100%" }}
      >
        <div>
          <span className="text-start fw-semibold fs-6">
            {currentTrack?.title}
          </span>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "7rem" }}
        >
          <span onClick={handlePreviousSong}>
            <i class="fa-solid fa-backward-step fs-4" sx={{cursor: "pointer"}}></i>
          </span>
          <span onClick={currentTrack ? handlePlayPause : null} style={{cursor: "pointer"}}>
            {isPlaying ? (
              <i className={`fas fa-pause fs-4 `}></i>
            ) : (
              <i className={`fas fa-play fs-4  `}></i>
            )}
          </span>
          <span onClick={handleNextSong}>
            <i class="fa-solid fa-forward-step fs-4" style={{cursor: "pointer"}}></i>
          </span>
        </div>
      </div>

      <Flex justifyContent="space-between" gap={3}>
        <Slider
          outline={0}
          _focus={{ outline: 0 }}
          aria-label="seek-slider"
          defaultValue={0}
          width="100%"
          onChange={onSeek}
          value={!isNaN(time) ? time : 0}
        >
          <SliderTrack bg="gray.400">
            <SliderFilledTrack bg="#b767a2" />
          </SliderTrack>
          <SliderThumb boxSize={6} outline={0}>
            <Box color="#b767a2" as={BsSoundwave} />
          </SliderThumb>
        </Slider>
      </Flex>
      {/* <div className="progress-bar-container mt-3">
        <div className="progress-bar" id="progress-bar"></div>
      </div> */}

      <div
        className="d-flex justify-content-between"
        style={{ maxWidth: "100%" }}
      >
        {/* <div>
          <span className=" fs-5 text-secondary">{currentTrack?.description}</span>
        </div> */}
        <div className="d-flex gap-1 text-secondary pb-3">
          <span>
            {audioRef.current
              ? convertToMins(audioRef.current.currentTime)
              : "0:00"}
          </span>
          /
          <span>
            {audioRef.current?.duration
              ? convertToMins(audioRef.current.duration)
              : "0:00"}
          </span>
        </div>
      </div>
    </>
  );
};

export default WellnessSongMusicPlayer;
