import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlaying } from '../Allslices/playerSlice';

const Playcontrols = ({audioRef, onNext, onPrevious, onPlay, isPlaying, repeatStatus  , isPurchased}) => {
  const { currentTrack } = useSelector((state) => state.player);
  const dispatch = useDispatch();

  
  

 
  const handlePlay = () => {
    
    onPlay();
  };



  return (
    <div className="text-center d-flex gap-4 text-secondary align-items-center">
      <span onClick={onPrevious} style={{cursor: "pointer"}}>
        <i className="fa-solid fa-backward-step fs-5"></i>
      </span>
      <span onClick={handlePlay} title='Play/Pause' style={{cursor: "pointer"}}>
        {!isPlaying ? (
          <i className="fas fa-play fs-5"></i>
        ) : (
          <i className="fas fa-pause fs-5"></i>
        )}
      </span>
      <span onClick={onNext} style={{cursor: "pointer"}}>
        <i className="fa-solid fa-forward-step fs-5"></i>
      </span>
      
    </div>
  );
};

export default Playcontrols;
