import React, { useState, useEffect, useRef } from "react";
import Rectangle57 from "../../src/images/rectangle57.png";
import { useLocation, useNavigate } from "react-router-dom";
import MetaData from "../component/layout/MetaData";
import d32 from "../images/d32.png";
import headphones from "../images/headphones 1.png";
import stickynote from "../images/sticky-note 1.png";
import lock from "../images/lock 1.png";
import CheckMark from "../images/checkmark.png";
import close from "../images/close 6.png";
import Moment from "react-moment";
import { ImHeadphones } from "react-icons/im";
import { MdLibraryBooks } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { getmyOrder } from "../Allslices/orderSlice";
import {
  getcoursedetails,
  getCourseLesson,
  postActivityAnswer,
  singlecourseFailure,
} from "../Allslices/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SHOW_SUCCESS_NOTIFICATION } from "../appUtils/appToast";

export default function Course2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentpathUrl = location.pathname;
  const mainkey = currentpathUrl.split("/")[1];
  const slug = currentpathUrl.split("/")[2];
  const {
    courseDetails,
    courseLessonList,
    isLoading: coursedataLoading,
    isPostActivityAnswerLoading,
    activityAnswer,
  } = useSelector((state) => state.course);

  const OrderId = location.state?.orderid;
  const CourseId = location.state?.productId;
  const { user } = useSelector((state) => state.auth);
  const { orderAll } = useSelector((state) => state?.order);
  const [activeTab, setActiveTab] = useState("Section0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [showActivity, setShowActivity] = useState(false);
  const [showAudioModal, setshowAudioModal] = useState(false);
  const [answerActivityId, setAnswerActivityId] = useState("");
  const [audioContentIndex, setAudioContetnIndex] = useState("");
  const [formData, setFormData] = useState({
    answerText: "",
    orderid: OrderId,
    courseid: CourseId,
  });
  const [loader, setLoader] = useState(false);
  const [loadmore, setLoadMore] = useState(false);
  // const [activeContent, setActiveContent] = useState('Content0');
  const [activeContents, setActiveContents] = useState({});

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  // useEffect(
  //   () => {
  //     if (orderAll && orderAll.length > 0) {
  //       const isCoursePurchased = orderAll.some(
  //         (order) => order.orderItems.slug === slug
  //       );
  //       if (!isCoursePurchased) {
  //         navigate(`/courses/${slug}`);
  //       }
  //     }
  //   },
  //   orderAll,
  //   slug,
  //   navigate
  // );

  useEffect(() => {
    if (orderAll && orderAll.length > 0) {
      let isCoursePurchased = false;

      for (const order of orderAll) {
        const orderItem = order.orderItems;
        if (orderItem.slug === slug) {
          isCoursePurchased = true;
          const createdAt = moment(order.createdAt);
          const expiryDate = createdAt.add(
            orderItem.monthValidity || 0,
            "months"
          );
          const currentTime = moment();

          if (currentTime.isAfter(expiryDate)) {
            isCoursePurchased = false;
            break;
          }
        }
      }

      if (!isCoursePurchased) {
        navigate(`/courses/${slug}`);
      }
    }
  }, [orderAll, slug, navigate]);

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (userId == null) {
      navigate("/login");
    }
  }, []);

  const scrollToCourse = () => {
    const courseSection = document.getElementById("courseSection");
    courseSection.scrollIntoView({ behavior: "smooth" });
    setActiveTab("Course");
  };

  const activityRef = useRef(null);

  const toggleActivity = (activityId) => {
    setAnswerActivityId(activityId);
    setShowActivity(!showActivity);
    setshowAudioModal(false);
  };

  const [activeAccordianIndex, setActiveAccordianIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordianIndex(activeAccordianIndex === index ? null : index);
  };

  useEffect(() => {
    if (showActivity && activityRef.current) {
      activityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, 100);
    }
  }, [showActivity]);

  const audioModalRef = useRef(null);

  const toggleAudio = (url, index) => {
    setAudioUrl(url);
    setAudioContetnIndex(index);
    setshowAudioModal(!showAudioModal);
    setShowActivity(false);
  };

  useEffect(() => {
    if (showAudioModal && audioModalRef.current) {
      audioModalRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showAudioModal]);

  const sectionRef = useRef();

  useEffect(() => {
    if (activeTab && sectionRef.current && window.innerWidth < 768) {
      sectionRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeTab]);

  

  const [visibleCourses, setVisibleCourses] = useState(4);

  const handleLoadMore = () => {
    setLoadMore(true);
    setTimeout(() => {
      setVisibleCourses((prevVisibleCourses) => prevVisibleCourses + 4);
      setLoadMore(false);
    }, 1000);
  };

  const openModal = (url) => {
    setIsModalOpen(true);
    setAudioUrl(url);
  };

  const handleContentClick = (dayIndex, contentIndex, content) => {
    setActiveContents((prev) => ({
      ...prev,
      [dayIndex]: {
        activeContent: contentIndex,
        activeTitle: content.title,
        activeDescription: content.description,
        activeAudioUrl: content?.audiofile,
        activeVideoUrl: content.videoUrl,
      },
    }));
  };

  let activeContent = 0;
  useEffect(() => {
    if (courseLessonList?.length > 0) {
      const defaultActiveContents = {};
    
      courseLessonList.forEach((day, dayIndex) => {
       
        if (day?.content?.length > 1) {
          const firstContent = day.content[0];
          if (firstContent) {
            defaultActiveContents[dayIndex] = {
              activeContent: 0,
              activeTitle: firstContent.title,
              activeDescription: firstContent.description,
              activeAudioUrl: firstContent?.audiofile,
              activeVideoUrl: firstContent.videoUrl,
            };
          }
        }
      });
      setActiveContents(defaultActiveContents);
    }
  }, [courseLessonList]);

 
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const singleCourse = courseDetails?.course;
  const courseid = singleCourse?._id;


  const courseFaqList = singleCourse?.courseFaq;

  const orderType = "course";

  
  useEffect(() => {
    setLoader(true);
    dispatch(getcoursedetails(slug));
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, [slug]);


  useEffect(() => {
    dispatch(
      getCourseLesson({ courseid: courseid, orderid: formData.orderid })
    );
  }, [courseid, formData.orderid, isPostActivityAnswerLoading]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const startHerenavigate = () => {
    navigate("/course2");
  };

  const [currenttab, setcurrenttab] = useState("button1");

  const [videoUrl, setVideoUrl] = useState(singleCourse?.bannerVideoUrl);
  const handleActivechange = (e) => {
    setcurrenttab(e.target.id);
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // const formDataToSend = new FormData();
    // for (const key in formData) {
    //   if (formData[key] !== null) {
    //       formDataToSend.append(key, formData[key]);
    //     }
    // }
    dispatch(postActivityAnswer({ answerActivityId, formData }));
    SHOW_SUCCESS_NOTIFICATION("Activity Answer Posted Successfully.");
    setShowActivity(false);
  };

  const handleUrlChange = (newUrl) => {
   
    const topValue = window.innerWidth <= 768 ? 900 : 280;
   

    window.scrollTo({
      top: topValue,
      behavior: "smooth",
    });
    setVideoUrl(newUrl);
  };

  useEffect(() => {
    setVideoUrl(singleCourse?.bannerVideoUrl);
  }, [singleCourse?.bannerVideoUrl]);

  useEffect(() => {
    if (singleCourse?.status === false) {
      navigate("/courses");
    }
  }, [singleCourse, dispatch, navigate]);

  return (
    <>
      <MetaData title="Course 2" />
      <div style={{ overflow: "hidden" }}>
        {/* <div
          style={{
            backgroundImage: `url(${Rectangle57})`,
            backgroundSize: "cover",
            height: "5rem",
            position: "sticky",
          }}
        ></div> */}
        <div
          className="aboutbtn2"
          style={{
            backgroundImage: `url(${singleCourse?.bannerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "300px",
          }}
        ></div>
        <div
          className="poascastsub2"
          style={{
            backgroundImage: `url(${Rectangle57})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="container meaning ">
            <div className="row pt-5">
              <div
                className="col-lg-3 col-sm-10 col-10 course2SideContainer p-0"
                style={{ borderRadius: "16px" }}
              >
                {currenttab == "button1" ? (
                  <button
                    className="btn shadowbuttons btn-lg text-light rounded-0  w-100 "
                    onClick={() => scrollToCourse()}
                  >
                    Start Here
                  </button>
                ) : (
                  ""
                )}
                {currenttab == "button2" ? (
                  <button className="btn shadowbuttons btn-lg text-light rounded-0  w-100 ">
                    Daily Action
                  </button>
                ) : (
                  ""
                )}
                {currenttab == "button3" ? (
                  <button className="btn shadowbuttons btn-lg text-light rounded-0  w-100 ">
                    Ready to move on?
                  </button>
                ) : (
                  ""
                )}
                <div className="position-relative">
                  {loader ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "14rem",
                        width: "18rem",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={singleCourse?.courseImage}
                        alt=""
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "0px 0px 10px 10px !important",
                          aspectRatio: "16/9",
                        }}
                      />
                      {/* <h3
                        className="hpe text-center "
                        style={{ fontFamily: "myFont" }}
                      >
                        <span className="text-dark ">
                          {singleCourse?.welcomeMessage?.title}
                        </span>
                      </h3> */}
                    </div>
                  )}
                </div>
                <div className="penel text-muted  m-4">
                  <p
                    style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                    dangerouslySetInnerHTML={{
                      __html: singleCourse?.welcomeMessage?.description,
                    }}
                  ></p>
                </div>

                <div className="menus">
                  <ul>
                    {Array.isArray(singleCourse?.courseSection) &&
                      [...singleCourse.courseSection].map((section, index) => (
                        <li
                          key={section._id}
                          onClick={() => handleTabClick(`Section${index}`)}
                          className={`d-flex gap-2 align-items-center coursemenus my-2 py-2 ${
                            activeTab === `Section${index}`
                              ? "d-flex!important gap-2 align-items-center active coursemenus my-2 py-2"
                              : ""
                          }`}
                        >
                          <span
                            className="checkmark"
                            style={{
                              marginRight:
                                activeTab !== `Section${index}`
                                  ? "2.5rem"
                                  : "0",
                            }}
                          >
                            {activeTab === `Section${index}` && (
                              <img src={CheckMark} alt="Checkmark" />
                            )}
                          </span>
                          <span
                            style={{ fontFamily: "myFont", fontSize: "22px" }}
                          >
                            {section.title}
                          </span>
                        </li>
                      ))}
                    <li
                      id="courseSection"
                      onClick={() => handleTabClick("Course")}
                      className={`d-flex align-items-center gap-2 coursemenus my-2 py-2 ${
                        activeTab === "Course" ? "active" : ""
                      }`}
                    >
                      <span
                        className="checkmark"
                        style={{
                          marginRight: activeTab !== "Course" ? "2.5rem" : "0",
                        }}
                      >
                        {activeTab === "Course" && (
                          <img src={CheckMark} alt="Checkmark" />
                        )}
                      </span>
                      <span
                        className=""
                        style={{ fontFamily: "myFont", fontSize: "22px" }}
                      >
                        Course in {singleCourse?.sections}{" "}
                        {singleCourse?.daysType}
                      </span>
                    </li>
                    {courseFaqList?.length > 0 && (
                      <li
                        onClick={() => handleTabClick("faq")}
                        className={`d-flex align-items-center gap-2 coursemenus my-2 py-2 ${
                          activeTab === "faq" ? "active" : ""
                        }`}
                      >
                        <span
                          className="checkmark"
                          style={{
                            marginRight: activeTab !== "faq" ? "2.5rem" : "0",
                          }}
                        >
                          {activeTab === "faq" && (
                            <img src={CheckMark} alt="Checkmark" />
                          )}
                        </span>
                        <span
                          className=""
                          style={{ fontFamily: "myFont", fontSize: "22px" }}
                        >
                          FAQ's{" "}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>

                {/* <div className="pt-5">
                  <a href="">
                    <button
                      className="btn btn-warning btn-lg text-light rounded-0 cmn-t-shake w-100 "
                      style={{ backgroundColor: "#FF9933!important" }}
                    >
                      Download{" "}
                    </button>
                  </a>
                </div>
                <div className="dowload d-flex pt-3">
                  <i className="bx bxs-download fs-2" />
                  <div className="living mx-3">
                    <p>Module-4</p>
                    <p>
                      Living the Happy Way <br />
                      .PDF
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-9 py-4 ">
                {Array.isArray(singleCourse?.courseSection) &&
                  [...singleCourse.courseSection]
                    .sort((a, b) => a.sequence - b.sequence)
                    .map(
                      (section, index) =>
                        activeTab === `Section${index}` && (
                          <div
                            ref={sectionRef}
                            key={section._id}
                            className="container-fluid"
                          >
                            <div className="d-flex justify-content-center flex-column align-items-center gap-2">
                              <h1
                                className="fs-3 fw-bold m-auto py-2"
                                style={{ fontFamily: "myFont" }}
                              >
                                {section.title?.toUpperCase()}
                              </h1>
                              <div
                                style={{
                                  overflow: "hidden",
                                }}
                              >
                                {section.fileType === "video" && (
                                  <iframe
                                    style={{
                                      borderRadius: "10px",
                                      height: " 48vh",
                                      width: "55vw",
                                    }}
                                    src={section.file}
                                    className="iframeCourse"
                                    title="Video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                  ></iframe>
                                )}
                                {section.fileType === "audio" && (
                                  <audio
                                    controls
                                    className="my-4"
                                    controlsList={
                                      section.downloadable ? "" : "nodownload"
                                    }
                                  >
                                    <source
                                      src={section?.file}
                                      type="audio/mp3"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                )}
                                {section.fileType === "book" && (
                                  <iframe
                                    style={{
                                      borderRadius: "10px",
                                      height: " 48vh",
                                      width: "55vw",
                                    }}
                                    className="iframeCourse"
                                    src={section.file}
                                    title="Book viewer"
                                    frameBorder="0"
                                    allow="fullscreen"
                                  ></iframe>
                                )}
                                {section.fileType === "text content" && (
                                  <div
                                    style={{
                                      width: "55vw",
                                      height: "58vh",
                                      overflowY: "auto",
                                      padding: "1rem",
                                      border: "1px solid #ccc",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: section.file,
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <p
                                className="pt-3 mx-3 mx-lg-5 px-1 px-lg-3 fs-6"
                                style={{
                                  textAlign: "justify",
                                  fontFamily: "Lato",
                                  fontWeight: 500,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: section.description,
                                }}
                              ></p>
                            </div>
                          </div>
                        )
                    )}

                {/* Modal for audio player */}
                {/* {isModalOpen && (
                  <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    style={{
                      display: "block",
                      position: "fixed",
                      top: "50px",
                      left: "68%",
                      maxWidth: "390px",
                      zIndex: "1050",
                    }}
                  >
                    <div className="modal-dialog " role="document">
                      <div className="modal-content">
                        <div
                          className="modal-header"
                          style={{
                            boxShadow: " -2px 1px 26px -1px rgba(0,0,0,0.51)",
                            borderRadius: "50px!important"
                          }}
                        >
                          <audio controls>
                            <source src={audioUrl} type="audio/mpeg" />
                          </audio>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => setIsModalOpen(false)}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {activeTab == "Course" && (
                  <div className="container-fluid">
                    <div className="d-flex justify-content-center align-items-center">
                      <iframe
                        style={{
                          height: "48vh",
                          width: "55vw",
                        }}
                        className="iframeCourse"
                        src={videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="row py-5 g-3">
                      {Array.isArray(courseLessonList) &&
                        courseLessonList
                          .slice(0, visibleCourses)
                          .map((item, index) => (
                            <div key={index}>
                              {item?.unlocked ? (
                                <div
                                  className="col-12 p-4 rounded-5"
                                  style={{
                                    backgroundColor: "#fdf1fa",
                                    cursor: "pointer",
                                  }}
                                >
                                  <h5
                                    className="fw-semibold"
                                    style={{ fontFamily: "myFont" }}
                                  >
                                    {singleCourse?.daysType === "Days"
                                      ? "Day"
                                      : singleCourse?.daysType === "Weeks"
                                      ? "Week"
                                      : singleCourse?.daysType === "Months"
                                      ? "Month"
                                      : ""}
                                    {""} {index + 1}
                                  </h5>
                                  <div className="row">
                                    {item?.content?.length === 1 ? (
                                      <>
                                        <div className="col-lg-8 col-sm-4 d-flex flex-column rounded align-items-start mt-3">
                                          <h5
                                            className="fw-semibold fs-5"
                                            style={{ fontFamily: "myFont" }}
                                          >
                                            {item?.content[0].title}
                                          </h5>
                                          <p className="lato fs-6">
                                            {item?.content[0].description}
                                          </p>
                                        </div>

                                        <div className="col-lg-4 col-sm-12">
                                          <div
                                            className="position-relative rounded-3"
                                            style={{
                                              paddingBottom: "56.25%",
                                              height: 0,
                                              overflow: "hidden",
                                              maxWidth: "100%",
                                              position: "relative",
                                            }}
                                          >
                                            <div
                                              className="overlay"
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                zIndex: 1,
                                              }}
                                              onClick={() =>
                                                handleUrlChange(
                                                  item?.content[0]?.videoUrl
                                                )
                                              }
                                            ></div>
                                            <iframe
                                              style={{
                                                width: "18vw",
                                                height: "24vh",
                                                borderRadius: "10px",
                                                position: "relative",
                                                zIndex: 0,
                                              }}
                                              className="iframeCourse"
                                              src={item?.content[0]?.videoUrl}
                                              title="YouTube video player"
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowFullScreen={true}
                                            ></iframe>
                                          </div>
                                          <div className="pt-2 d-flex">
                                            <span
                                              className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleAudio(
                                                  item?.content[0]?.audiofile,
                                                  index
                                                )
                                              }
                                            >
                                              <ImHeadphones fontSize={28} />
                                              Audio
                                            </span>
                                            <span
                                              className="p-2 d-inline-flex gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleActivity(
                                                  item?.activity?._id
                                                )
                                              }
                                            >
                                              <MdLibraryBooks fontSize={32} />
                                              Activity
                                            </span>
                                            {item?.activity?.answers[0]
                                              ?.answerText && (
                                              <span
                                                className="p-1 d-inline-flex gap-2 lato"
                                                style={{
                                                  cursor: "pointer",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={CheckMark}
                                                  style={{ height: "30px" }}
                                                  alt="Checkmark"
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-3 col-sm-3 d-flex flex-column rounded-3 pt-2">
                                          {item?.content?.map(
                                            (content, contentIndex) => (
                                              <ul
                                                className={`p-1 list-group`}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <li
                                                  key={contentIndex}
                                                  className="fw-semibold list-group-item"
                                                  style={{
                                                    fontFamily: "myFont",
                                                    backgroundColor:
                                                      activeContents[index]
                                                        ?.activeContent ===
                                                      contentIndex
                                                        ? "#b767a2"
                                                        : "#eeeff4",
                                                    color:
                                                      activeContents[index]
                                                        ?.activeContent ===
                                                      contentIndex
                                                        ? "white"
                                                        : "black",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleContentClick(
                                                      index,
                                                      contentIndex,
                                                      content
                                                    )
                                                  }
                                                >
                                                  {content.title}
                                                </li>
                                              </ul>
                                            )
                                          )}
                                        </div>
                                        <div className="col-lg-5 col-sm-4 d-flex flex-column align-items-start rounded mt-3">
                                          <h6 className="fw-semibold lato">
                                            {activeContents[index]?.activeTitle}
                                          </h6>
                                          <p className="lato fs-6">
                                            {
                                              activeContents[index]
                                                ?.activeDescription
                                            }
                                          </p>
                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                          <div
                                            className="position-relative rounded-3"
                                            style={{
                                              paddingBottom: "56.25%",
                                              height: 0,
                                              overflow: "hidden",
                                              maxWidth: "100%",
                                              position: "relative",
                                            }}
                                            onClick={() => {
                                              if (
                                                activeContents[index]
                                                  ?.activeVideoUrl
                                              ) {
                                                handleUrlChange(
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                );
                                              }
                                            }}
                                          >
                                            <div
                                              className="overlay"
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                zIndex: 1,
                                              }}
                                              onClick={() =>
                                                handleUrlChange(
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                )
                                              }
                                            ></div>
                                            <iframe
                                              style={{
                                                width: "18vw",
                                                height: "24vh",
                                                borderRadius: "10px",
                                                position: "relative",
                                                zIndex: 0,
                                              }}
                                              className="iframeCourse"
                                              src={
                                                activeContents[index]
                                                  ?.activeVideoUrl
                                              }
                                              title="YouTube video player"
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowFullScreen={true}
                                            ></iframe>
                                          </div>
                                          <div className="pt-2 d-flex">
                                            <span
                                              className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleAudio(
                                                  activeContents[index]
                                                    ?.activeAudioUrl,
                                                  index
                                                )
                                              }
                                            >
                                              <ImHeadphones fontSize={27} />
                                              Audio
                                            </span>
                                            <span
                                              className="p-2 d-inline-flex gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleActivity(
                                                  item?.activity?._id
                                                )
                                              }
                                            >
                                              <MdLibraryBooks fontSize={33} />
                                              Activity
                                            </span>
                                            {item?.activity?.answers[0]
                                              ?.answerText && (
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img
                                                  src={CheckMark}
                                                  style={{
                                                    height: "25px",
                                                  }}
                                                  alt="Checkmark"
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-12 p-3 rounded-5"
                                  style={{
                                    backgroundColor: "#CCCCCC",
                                    pointerEvents: "none",
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    className="col-12 p-3 rounded-5 lock-image"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      opacity: "0.6",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "43%",
                                        left: "52%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: "1",
                                      }}
                                    >
                                      <img
                                        src={lock}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>

                                    <h5 className="fw-semibold lato">
                                      {singleCourse?.daysType === "Days"
                                        ? "Day"
                                        : singleCourse?.daysType === "Weeks"
                                        ? "Week"
                                        : singleCourse?.daysType === "Months"
                                        ? "Month"
                                        : ""}
                                      {""} {index + 1}
                                    </h5>
                                    <div className="row">
                                      {item?.content?.length === 1 ? (
                                        <>
                                          <div className="col-lg-8 col-sm-4 d-flex flex-column rounded align-items-start mt-3">
                                            <h5
                                              className="fw-semibold fs-5"
                                              style={{ fontFamily: "myFont" }}
                                            >
                                              {item?.content[0].title}
                                            </h5>
                                            <p className="lato fs-6">
                                              {item?.content[0].description}
                                            </p>
                                          </div>

                                          <div className="col-lg-4 col-sm-12">
                                            <div
                                              className="position-relative"
                                              style={{
                                                paddingBottom: "56.25%",
                                                height: 0,
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                className="overlay"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  width: "100%",
                                                  height: "100%",
                                                  cursor: "pointer",
                                                  zIndex: 1,
                                                }}
                                                onClick={() =>
                                                  handleUrlChange(
                                                    item?.content[0]?.videoUrl
                                                  )
                                                }
                                              ></div>
                                              <iframe
                                                style={{
                                                  width: "18vw",
                                                  height: "24vh",
                                                  borderRadius: "10px",
                                                  position: "relative",
                                                  zIndex: 0,
                                                }}
                                                className="iframeCourse"
                                                src={item?.content[0]?.videoUrl}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen={true}
                                              ></iframe>
                                            </div>
                                            <div className="pt-2 d-flex">
                                              <span
                                                className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleAudio(
                                                    activeContents[index]
                                                      ?.activeAudioUrl,
                                                    index
                                                  )
                                                }
                                              >
                                                <ImHeadphones fontSize={28} />
                                                Audio
                                              </span>
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleActivity(
                                                    item?.activity?._id
                                                  )
                                                }
                                              >
                                                <MdLibraryBooks fontSize={32} />
                                                Activity
                                              </span>
                                              {item?.activity?.answers[0]
                                                ?.answerText && (
                                                <span
                                                  className="p-2 d-inline-flex gap-2 lato"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={CheckMark}
                                                    height={30}
                                                    alt="Checkmark"
                                                  />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="col-lg-3 col-sm-3 d-flex flex-column rounded-3 pt-2">
                                            {item?.content?.map(
                                              (content, contentIndex) => (
                                                <ul
                                                  className={`p-1 list-group`}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <li
                                                    key={contentIndex}
                                                    className="fw-semibold list-group-item"
                                                    style={{
                                                      fontFamily: "myFont",
                                                      backgroundColor:
                                                        activeContents[index]
                                                          ?.activeContent ===
                                                        contentIndex
                                                          ? "#b767a2"
                                                          : "#eeeff4",
                                                      color:
                                                        activeContents[index]
                                                          ?.activeContent ===
                                                        contentIndex
                                                          ? "white"
                                                          : "black",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleContentClick(
                                                        index,
                                                        contentIndex,
                                                        content
                                                      )
                                                    }
                                                  >
                                                    {content.title}
                                                  </li>
                                                </ul>
                                              )
                                            )}
                                          </div>
                                          <div className="col-lg-5 col-sm-4 d-flex flex-column align-items-start rounded mt-3">
                                            <h6 className="fw-semibold lato">
                                              {
                                                activeContents[index]
                                                  ?.activeTitle
                                              }
                                            </h6>
                                            <p className="lato fs-6">
                                              {
                                                activeContents[index]
                                                  ?.activeDescription
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-4 col-sm-12">
                                            <div
                                              className="position-relative rounded-3"
                                              style={{
                                                paddingBottom: "56.25%",
                                                height: 0,
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                className="overlay"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  width: "100%",
                                                  height: "100%",
                                                  cursor: "pointer",
                                                  zIndex: 1,
                                                }}
                                                onClick={() =>
                                                  handleUrlChange(
                                                    activeContents[index]
                                                      ?.activeVideoUrl
                                                  )
                                                }
                                              ></div>
                                              <iframe
                                                style={{
                                                  width: "18vw",
                                                  height: "24vh",
                                                  borderRadius: "10px",
                                                  position: "relative",
                                                  zIndex: 0,
                                                }}
                                                className="iframeCourse"
                                                src={
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                }
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen={true}
                                              ></iframe>
                                            </div>
                                            <div className="pt-2 d-flex">
                                              <span
                                                className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleAudio(
                                                    activeContents[index]
                                                      ?.activeAudioUrl,
                                                    index
                                                  )
                                                }
                                              >
                                                <ImHeadphones fontSize={27} />
                                                Audio
                                              </span>
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleActivity(
                                                    item?.activity?._id
                                                  )
                                                }
                                              >
                                                <MdLibraryBooks fontSize={33} />
                                                Activity
                                              </span>
                                              {item?.activity?.answers[0]
                                                ?.answerText && (
                                                <span
                                                  className="p-2 d-inline-flex gap-2 lato"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={CheckMark}
                                                    height={30}
                                                    alt="Checkmark"
                                                  />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Modal for the Content Audio File */}

                              {audioContentIndex === index &&
                                showAudioModal && (
                                  <>
                                    <div
                                      ref={audioModalRef}
                                      className="col-12 p-3 rounded-5"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h6 className="fw-semibold lato">
                                          Audio
                                        </h6>
                                        <div
                                          className="p-2 rounded-circle d-flex"
                                          style={{
                                            backgroundColor: "#b767a2",
                                            color: "white",
                                          }}
                                          onClick={toggleAudio}
                                        >
                                          <IoCloseSharp
                                            fontSize={25}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 d-flex flex-column rounded pt-1 my-3">
                                          <div
                                            className="modal-dialog w-100"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div
                                                className="modal-header"
                                                style={{
                                                  boxShadow:
                                                    " -2px 1px 26px -1px rgba(0,0,0,0.51)",
                                                  borderRadius: "50px",
                                                }}
                                              >
                                                <audio
                                                  controls
                                                  className="w-100"
                                                  controlsList="nodownload"
                                                >
                                                  <source
                                                    src={audioUrl}
                                                    type="audio/mpeg"
                                                  />
                                                </audio>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {/* Modal for the Activity Answer for Days */}
                              {answerActivityId === item.activity._id &&
                                showActivity && (
                                  <div
                                    ref={activityRef}
                                    className="col-12 p-3 rounded-5"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <h6 className="fw-semibold lato">
                                        Activity
                                      </h6>
                                      <div
                                        className="p-2 rounded-circle d-flex"
                                        style={{
                                          backgroundColor: "#b767a2",
                                          color: "white",
                                        }}
                                        onClick={toggleActivity}
                                      >
                                        <IoCloseSharp
                                          fontSize={25}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 d-flex flex-column rounded pt-1">
                                        <h5
                                          className="fw-semibold fs-6"
                                          style={{ fontFamily: "myFont" }}
                                        >
                                          {item?.activity?.title}
                                        </h5>
                                        <p
                                          className="text-left lato fs-6"
                                          dangerouslySetInnerHTML={{
                                            __html: item?.activity?.description,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="col-12">
                                        <div
                                          data-mdb-input-init
                                          className="form-outline position-relative"
                                          style={{
                                            boxShadow:
                                              " -2px 1px 26px -1px rgba(0,0,0,0.51)",
                                            borderRadius: "15px",
                                          }}
                                        >
                                          <textarea
                                            className="form-control"
                                            id="textAreaExample1"
                                            placeholder="Post Your Answer Here..."
                                            rows="4"
                                            name="answerText"
                                            value={
                                              item?.activity?.answers[0]
                                                ?.answerText
                                            }
                                            onChange={handleInputChange}
                                            readOnly={
                                              !!item?.activity?.answers[0]
                                                ?.answerText
                                            }
                                          ></textarea>
                                          {/* <label
                                            className="form-label"
                                            htmlFor="textAreaExample1"
                                          >
                                            {item?.activity?.answers[0]
                                              ?.answerText
                                              ? ""
                                              : "Post Your Answer"}
                                          </label> */}
                                          {!item?.activity?.answers[0]
                                            ?.answerText && (
                                            <button
                                              type="button"
                                              className="btn rounded px-4 fs-6 py-1 position-absolute text-white fw-2"
                                              style={{
                                                backgroundColor: "#b767a2",
                                                bottom: "1rem",
                                                right: "1rem",
                                              }}
                                              onClick={handleSubmit}
                                            >
                                              Post
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                    </div>

                    {courseLessonList?.length > visibleCourses && (
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn shadowbuttons btn-lg text-light rounded-pill cmn-t-shake "
                          onClick={handleLoadMore}
                        >
                          {loadmore ? (
                            <div class="spinner-border text-dark" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          ) : (
                            <span className="lato">LOAD MORE</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {activeTab == "faq" && (
                  <div className="container-fluid">
                    <div className="d-flex justify-content-center flex-column">
                      <h1
                        className="fs-2 fw-bold m-auto py-1"
                        style={{ fontFamily: "myFont" }}
                      >
                        FAQ's
                      </h1>
                    </div>

                    <div className="row py-3 g-3">
                      <div className="accordion" id="accordionExample">
                        {Array.isArray(courseFaqList) &&
                          courseFaqList.map((item, index) => (
                            <div
                              key={`accordionItem-${index}`}
                              className="accordion-item"
                            >
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className={`accordion-button ${
                                    activeAccordianIndex === index
                                      ? ""
                                      : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() => handleAccordionClick(index)}
                                  aria-expanded={activeAccordianIndex === index}
                                  aria-controls={`collapse-${index}`}
                                  style={{ background: "#fdf1fa" }}
                                >
                                  <strong style={{ fontFamily: "myFont" }}>
                                    {item?.question}
                                  </strong>
                                </button>
                              </h2>
                              <div
                                id={`collapse-${index}`}
                                className={`accordion-collapse collapse ${
                                  activeAccordianIndex === index ? "show" : ""
                                }`}
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div
                                  className="accordion-body"
                                  style={{ fontFamily: "myFont" }}
                                >
                                  {item?.answer}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* {
	activeTab =="hypnosis" &&<div className="lifel" id="second">
	<h1>
		<span>Healthy Relationships</span>
	</h1>
	<p style={{ lineHeight: '2', fontFamily: 'Lato' }}>
		Healthy relationships are the bedrock of a fulfilling and balanced
		life. They provide us with support, love, and a sense of connection.
		In these relationships, communication flows openly and honestly,
		trust is unwavering, and mutual respect forms the foundation.
		Boundaries are understood and honored, creating a safe space for
		personal growth. In healthy relationships, both parties are equal
		partners, sharing power and decision-making.{" "}
	</p>
	<div className="row">
		<div className="col-lg-5">
			<div className="imgese">
				<img
					src={tipsForHealthyRelation}
					alt=""
				/>
			</div>
		</div>
		<div className="col-lg-7">
			<div className="disctr">
				<p>
					Appreciation and empathy flourish, creating a nurturing and
					harmonious environment. In these relationships, we find not
					only companionship but also personal growth,Appreciation and
					empathy flourish, creating a nurturing and harmonious
					environment. In these relationships, we find not only
					companionship but also personal growth, making life's journey
					all the more meaningful. making life's journey all the more
					meaningful.
				</p>
			</div>
		</div>
	</div>
</div>
} */}
                {/* 
							<div className="lifel" id="third">
								<h1>
									<span>Practise Self-Love</span>
								</h1>
								<p style={{ lineHeight: "2", fontFamily: 'Lato' }}>
									Practicing self-love is a vital component of overall well-being and
									personal growth. It means treating yourself with the same kindness,
									compassion, and respect that you offer to others. Self-love involves
									acknowledging your worth,prioritizing self-care, and taking time for
									activities that bring joy and fulfillment. When you practice
									self-love, you become your own best advocate and friend, and this
									can greatly enhance your mental and emotional health.
								</p>
								<div className="row">
									<div className="col-lg-5">
										<div className="imgese">
											<img
												src={Attractive}
												alt=""
											/>
										</div>
									</div>
									<div className="col-lg-7">
										<div className="disctr">
											<p>
												It empowers you to make choices that align with your values,
												build resilience in the face of challenges, and lead a more
												content and purposeful life. Self-love is not selfish; it's
												the foundation upon which you can build strong, meaningful
												relationships and contribute positively to the world around
												you.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="lifel" id="four">
								<h1>
									<span>Gratitude</span>
								</h1>
								<p style={{ lineHeight: "2", fontFamily: "Lato" }}>
									Sollicitudin augue pretium eros a duis etiam congue vehicula
									aliquam. Gravida dapibus sem purus metus pretium donec erat cursus
									maecenas. Class ut scelerisque dolor morbi blandit magnis feugiat
									aptent suscipit tincidunt. Nullam sapien sagittis hac rhoncus montes
									finibus vel eleifend.
								</p>
								<div className="row">
									<div className="col-lg-5">
										<div className="imgese">
											<img src={Rectangle158} alt="" />
										</div>
									</div>
									<div className="col-lg-7">
										<div className="disctr">
											<p>
												Sollicitudin augue pretium eros a duis etiam congue vehicula
												aliquam. Gravida dapibus sem purus metus pretium donec erat
												cursus maecenas. Class ut scelerisque dolor morbi blandit
												magnis feugiat aptent suscipit tincidunt. Nullam sapien
												sagittis hac rhoncus montes finibus vel eleifend.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="lifel" id="five">
								<h1>
									<span>The Art of Forgiveness</span>
								</h1>
								<p style={{ lineHeight: "2", fontFamily: "Lato" }}>
									The art of forgiveness is a profound and transformative practice
									that liberates both the forgiver and the forgiven. It involves
									letting go of resentment, anger, and the desire for revenge, and
									instead choosing to release the emotional burdens of past hurts.
									Forgiveness doesn't mean condoning or forgetting the wrongs that
									have been done; rather, it's a conscious decision to free yourself
									from the weight of negative emotions and the grip of the{" "}
								</p>
								<div className="row">
									<div className="col-lg-5">
										<div className="imgese">
											<img src={zGWGlE9TL} alt="" />
										</div>
									</div>
									<div className="col-lg-7">
										<div className="disctr">
											<p>
												It's a powerful act of self-care that fosters the mending of
												relationships, the restoration of inner harmony, and the
												opening of new possibilities for the future. While it may not
												always be easy, the art of forgiveness is a testament to our
												capacity for compassion, resilience, and the ability to
												transcend the pain of the past.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="lifel" id="six">
								<h1>
									<span>Overthinking and Over-expectations</span>
								</h1>
								<p style={{ lineHeight: "2", fontFamily: 'Lato' }}>
									Sollicitudin augue pretium eros a duis etiam congue vehicula
									aliquam. Gravida dapibus sem purus metus pretium donec erat cursus
									maecenas. Class ut scelerisque dolor morbi blandit magnis feugiat
									aptent suscipit tincidunt. Nullam sapien sagittis hac rhoncus montes
									finibus vel eleifend.
								</p>
								<div className="row">
									<div className="col-lg-5">
										<div className="imgese">
											<img src={Rectangle158} alt="" />
										</div>
									</div>
									<div className="col-lg-7">
										<div className="disctr">
											<p>
												Sollicitudin augue pretium eros a duis etiam congue vehicula
												aliquam. Gravida dapibus sem purus metus pretium donec erat
												cursus maecenas. Class ut scelerisque dolor morbi blandit
												magnis feugiat aptent suscipit tincidunt. Nullam sapien
												sagittis hac rhoncus montes finibus vel eleifend.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="lifel" id="seven">
								<h1>
									<span>Comparison vs. Self-Acceptance</span>
								</h1>
								<p style={{ lineHeight: 2, fontFamily: '"Lato"' }}>
									Comparison involves evaluating oneself, one's achievements, and
									one's worth by measuring them against others. It often leads to
									feelings of inadequacy, envy, and competition. When you constantly
									compare yourself to others, you may find it challenging to feel
									content or satisfied, as there will always be someone seemingly
									"better" or "ahead" in some way. This can contribute to stress,
									anxiety, and a lack of self-esteem.
								</p>
								<div className="row">
									<div className="col-lg-5">
										<div className="imgese">
											<img
												src={self_esteem}
												alt=""
											/>
										</div>
									</div>
									<div className="col-lg-7">
										<div className="disctr">
											<p>
												On the other hand, self-acceptance is about embracing and
												loving yourself as you are, with all your strengths and
												weaknesses. It involves recognizing your unique qualities and
												understanding that you are a work in progress. Self-acceptance
												promotes self-compassion and a focus on personal growth rather
												than external validation. It leads to greater mental and
												emotional well-being and can foster a sense of inner peace and
												contentment.
											</p>
										</div>
									</div>
								</div>
							</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
