import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
  isLoading: false,
  error: null,
  order: null,
  orderAll: [],
};

export const getmyOrder = createAsyncThunk(
  "order/orders/me",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getallorderStart());
      const response = await axios.get(`order/orders/me`);
      dispatch(getallorderSuccess(response?.data));

      return response?.data;
    } catch (error) {
      dispatch(getallorderfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const pendingOrder = createAsyncThunk(
  "order/pendingorder",
  async (order, { dispatch, rejectWithValue }) => {

    try {
      dispatch(pendingOrderStart());
      const response = await axios.post(`order/pendingorder`, order);
      dispatch(pendingOrderSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(pendingOrderFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createorder = createAsyncThunk(
  "order/neworder",
  async (order, { dispatch, rejectWithValue }) => {
    try {
      dispatch(newOrderStart());
      const response = await axios.post(`order/new`, order);
      dispatch(newOrderSuccess(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(newOrderfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "order/updateOrderStatus",
  async ({orderid, orderStatus}, { dispatch, rejectWithValue }) => {

    try {
      dispatch(updateOrderStatusStart());
      const response = await axios.put(`order/updateorderstatus`, {orderid, orderStatus});
      dispatch(updateOrderStatusSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(updateOrderStatusFailer(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    newOrderStart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    newOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
    newOrderfailure(state, action) {
      state.isLoading = false;
    },
    pendingOrderStart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    pendingOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
    pendingOrderFailure(state, action) {
      state.isLoading = false;
    },
    updateOrderStatusStart(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    updateOrderStatusSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
    updateOrderStatusFailer(state, action) {
      state.isLoading = false;
    },
    getallorderStart(state) {
      state.isLoading = true;
      state.error = null;
      state.orderAll = [];
    },
    getallorderSuccess(state, action) {
      state.isLoading = false;
      state.orderAll = action.payload?.orders;
    },
    getallorderfailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  newOrderStart,
  newOrderSuccess,
  newOrderfailure,
  getallorderStart,
  getallorderSuccess,
  getallorderfailure,
  pendingOrderStart,
  pendingOrderSuccess,
  pendingOrderFailure,
  updateOrderStatusStart,
  updateOrderStatusSuccess,
  updateOrderStatusFailer,
} = orderSlice.actions;

export default orderSlice.reducer;
