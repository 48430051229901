import { useEffect, useState } from "react";
import geetabg from "../images/Geeta-bg.png";
import bg from "../images/rectangle.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import {
  getCategoryBhagwat,
  getlistBhagwat,
  BhagwatChapterImage,
} from "../Allslices/BhagwatGitaSlice";

const Chapter = () => {
  const { slug } = useParams();
  const Navigate1 = useNavigate();
  const location = useLocation();
  const handleNavigatetoSubChapter = (slug) => {
    Navigate1(`/bhagwatgeeta/chapter/${slug}`);
  };

  const query = location.search.split("?")[1];

  let initialfilters={
    categoryId:null,
    chapter_number:null,
    text: query ?? "",
  }
  const [filters, setFilters] = useState({initialfilters});
  // category
  const dispatch = useDispatch();
  const { BhagwatCategory } = useSelector((state) => state.BhagwatGita);
  const { Bhagwatlist } = useSelector((state) => state.BhagwatGita);
   const { BhagwatImage } = useSelector((state) => state.BhagwatGita);


  useEffect(() => {
    dispatch(getCategoryBhagwat());
    dispatch(
      getlistBhagwat({ 
        categoryId: filters.categoryId,
         text: filters.text,
        chapter_number:filters.chapter_number })
    );
    dispatch(BhagwatChapterImage());
  }, [dispatch]);

 

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters
     ({
       ...filters, 
      [name]: value 
    });
  };


  const handleFiltersSubmit = (e) => {
    e.preventDefault();
    dispatch(getlistBhagwat({
      categoryId: filters.categoryId,
      text: filters.text,
      chapter_number:filters.chapter_number
    }))
  };

  return (
    <>
      <MetaData title={BhagwatImage?.heading}/>

      <div
        className=""
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${BhagwatImage?.background_image})`,
        }}
      >
        <div
          className="container d-flex align-items-center justify-content-center"
          style={{ minHeight: "80vh" }}
        >
          <div className="row">
            <div className="col-12 h-100 text-center">
              <h2 className="fw-bold text-light">
                <span style={{ fontSize: "larger", fontSize: "44px" }}>
                  {BhagwatImage?.heading}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container filterpad pt-5 shadow mx-auto"
        style={{width: "75vw"}}
      >
        <div className="row pb-4 p-3">
          <nav class="navbar bg-light">
            <div class="container-fluid w-100">
              <form class="d-flex w-100" role="search" onSubmit={handleFiltersSubmit}>
                <input
                  class="form-control me-2 w-100"
                  // type="search"
                  name="text"
                  placeholder="Search by keyword..."
                  aria-label="Search"
                  onChange={handleFilterChange}
                  value={filters.text}
                />
                {/* <button class="btn btn-outline-success" type="submit">Search</button> */}
              </form>
            </div>
          </nav>
        </div>
        <div
          className="text-center"
          style={{
            fontStyle: "italic",
            fontFamily: "initial",
          }}
        >
          ---------OR---------
        </div>
        <div className="row pb-4 p-3 justify-content-center">
          <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
            <select
             value={filters.categoryId}
              name="categoryId"
              onChange={handleFilterChange}
              id="selectFilter"
              className="select border-dark rounded-pill p-2 w-100"
            >
              <option value="">Category</option>
              {Array.isArray(BhagwatCategory) &&
                BhagwatCategory?.map((item, index) => {
                 return <option key={index} value={item?._id}>
                    {item?.title}
                  </option>
                })}
            </select>
          </div>
          {/* <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
            <select
             value={filters.chapter_number}
              className="select border-dark rounded-pill p-2 w-100"
              name="contenttype"
              onChange={handleFilterChange}
              id="selectFilter"
            >
              <option value="">Chapter</option>
              {Array.isArray(Bhagwatlist) &&
                Bhagwatlist?.map((item, index) => {
                 return <option key={index} value={item?.chapter_number}>
                    {item?.chapter_number}
                  </option>
                })}
            </select>
          </div> */}

          <div className="pt-3 d-flex justify-content-center">
            <a href="">
              <button
                className="btn mb-5  btn-orange btn-lg text-light cmn-t-shake"
                style={{
                  backgroundColor: "#FF9933!important",
                  padding: "0.6rem 2.5rem",
                  borderRadius: "26px",
                }}
                onClick={handleFiltersSubmit}
              >
                SEARCH
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div
          class="row g-3 mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {Bhagwatlist ? (
            <>
              {Array.isArray(Bhagwatlist) &&
                Bhagwatlist?.map((item, index) => (
                  <div class="col-sm-6" key={index}>
                    <div
                      class="card shadow mx-1"
                      onClick={() => handleNavigatetoSubChapter(item.slug)}
                    >
                      <div class="card-body">
                        <h5 style={{ color: "#ff9f54", fontWeight: "600" }}>
                          {item.chapter_number}
                        </h5>
                        <h3 className="fw-bold">{item.title}</h3>
                        <p className="card-text text-secondary">
                          {item.short_description}
                        </p>

                        <div className="d-flex align-items-center gap-4">
                          <i
                            class="fa-solid fa-list-ul lh"
                            style={{ color: "#7b7b7b" }}
                          ></i>
                          <p className="lh m-0 text-black">
                            {item?.verses.length} Verses
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <SmallLoader />
          )}
        </div>
      </div>

      {/* <div
        className="container-fluid d-flex  align-items-center justify-content-center flex-column p-5 lh-lg text-center"
        style={{
          // fontFamily: "myfont",
          backgroundImage: `url(${geetabg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "80vh",
          color: "white",
          fontSize: "23px",
        }}
      >
        <h1>Subscribe by E-Mail</h1>
        <p className="pt-3 text-white">
          Start your day with a nugget of timeless inspiring wisdom from the
          Holy Bhagavad Gita delivered straight to your email!
        </p>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg my-3 d-sm-5 "
            style={{
              backgroundColor: "#ff9933",
              color: "white",
              width: "200px",
            }}
          >
            Subscribe
          </button>
        </div>
      </div> */}
    </>
  );
};

export default Chapter;
